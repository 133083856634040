<template>
    <div class="background">
        <div class="container">
            <div class="card">
                <div class="menu-bar">
                    <h2> <i class="fas fa-toolbox"></i> &nbsp;Application Modules</h2>
                </div>
                <div class="tab">
                    <div class="tab-modules" @click="tabModules()">
                        <i class="fa fa-home"></i> Modules
                    </div>
                    <div class="tab-assignmodules" @click="tabAssignModules()">
                        <i class="fas fa-door-open"></i> Assign Modules
                    </div>
                </div>
    
                <div class="reservation-container" v-if="modules_status">
                    <p> <span class="rm-menu add-rm" @click="add_modules()"><i class="fa fa-plus"></i> Add Modules</span> | <span class="rm-menu list-rm" @click="list_modules()"><i class="fa fa-eye"></i> List Modules</span></p>
                    <div class="reservation-content">
                        <p><i class="fas fa-hotel"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
                        <div v-if="show_add_modules">
                            <div class="add-room">
                                <div class="add-rm-center">    
                                    <label for="">Module route: &nbsp;</label>
                                    <input type="text" class="form-control" placeholder="Enter module route" v-model="module_route" required>&nbsp;
                                    <label for="">Module name: &nbsp;</label>
                                    <input type="text" class="form-control" placeholder="Enter module name" v-model="module_name" required>&nbsp;
                                    <button class="btn btn-primary btn-block" v-if="!loading3" @click="addmodules()">
                                        Save </button>
                                    <button class="btn btn-primary btn-block" type="button" disabled v-if="loading3">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="show_list_modules">
                            <label for="">Search by module name: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter module name" v-model="searchby_modulename">&nbsp;
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Module route </th>
                                            <th>Module name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in filteredMenu" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                 {{ data.module_route }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.module_name }}
                                            </td>

                                           
                                            <td style="font-weight: 400;">
                                                <span class="category-edit" data-toggle="tooltip" title="Edit Item" @click="editModules(data.id, data.module_route, data.module_name)"><i class="fa fa-edit"></i> Edit</span>
                                                | 
                                                <span class="category-edit" data-toggle="tooltip" title="Edit Item" @click="deleteModules(data.id)"><i class="fa fa-trash"></i> Delete</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="reservation-container2" v-if="assign_modules_status">
                    <div class="reservation-content2">
                        <div class="guest-information">
                            <p> <i class="fas fa-hotel"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
    
                        </div>
    
                        <div v-if="show_list_assign_modules">
                            <label for="">Role: &nbsp;</label>
                            <select class="form-control input" v-model="user_role_id" @change="fetch_user_modules">
                                <option value="">-Select -</option>
                                <option value="1"> Member </option>
                                <option value="3"> Staff </option>
                                <option value="2"> Administrator </option>
                            </select>
                           
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Module route </th>
                                            <th>Module name </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in assignmodules" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                               {{ data.module_route }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.module_name }}
                                            </td>
                                            <td style="font-weight: 400;" v-if="user_role_id == data.user_role">
                                                <input type="checkbox" :checked="true" >  
                                            </td>
                                            <td style="font-weight: 400;" v-else>
                                                <input type="checkbox" :checked="false" @change="toggleCheck(data.moduleid)">  
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="display: flex; flex-direction: row; justify-content:center; font-size:10px">
                                    <button class="btn btn-warning input-button" @click="assignModules" v-if="!loading2"> Assign </button>
                                    <button class="btn btn-warning input-button" type="button" disabled v-if="loading2">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>   
    
        <!-- Edit modules Modal -->
        <div v-if="edit_module_data">
                <transition name="modal">
                    <div class="modal-mask2">
                        <div class="modal-wrapper col-md-5" style="margin:auto">
                            <div class="row">
                                <div class="col-md-1">
                                    <a title="Close" class="modal-close-mobile2" @click="closeMenu2"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    <br>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <h3>
                                            Edit Modules
                                        </h3>
                                        <div class="order-content">
                                            <label for="" style="font-size:12px">Module route: &nbsp;</label>
                                            <input type="text" class="form-control edit-input" v-model="edit_module_route">&nbsp;

                                            <label for="" style="font-size:12px">Module name: &nbsp;</label>
                                            <input type="text" class="form-control edit-input" v-model="edit_module_name">&nbsp;
                                               
                                            <button class="btn btn-success btn-block input-button" @click="updateModule" v-if="!loading4"><i class="fa fa-upload"></i> Update </button>
                                            <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading4">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Updating...
                                            </button>
    
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div>
                                        <a href="#" title="Close" class="modal-close2" @click="closeMenu2"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </transition>
        </div>
    
        <!-- Update guest status Modal -->
        <div v-if="update_user_role_id">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile" @click="closeMenu3"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        Update Guest Status
                                    </h3>
                                    <div class="order-content">
                                        <p class="space-top">Status</p>
                                        <select name="" id="" class="form-control edit-input" v-model="user_role_id_input" v-if="bookedStatus == 3">
                                            <option value="">-Select Status-</option>
                                            <option value="1">Checked Out</option>
                                        </select>
                                        <select name="" id="" class="form-control edit-input" v-model="user_role_id_input" v-else>
                                            <option value="">-Select Status-</option>
                                                <option value="3">Checked In</option>
                                                <option value="5">Cancel</option> 
                                        </select>
    
                                        <button class="btn btn-success btn-block input-button" @click="update_guestStatus" v-if="!loading7"><i class="fa fa-upload"></i> Update </button>
                                        <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading7">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Updating...
                                        </button>
    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close" @click="closeMenu3"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    
        <!-- Edit guest Modal -->
        <div v-if="edit_guest_data">
            <transition name="modal">
                <div class="modal-mask2">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile2" @click="closeMenu4"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        Edit Guest
                                    </h3>
                                    <div class="order-content">
                                        <label for="" style="font-size:12px">Firstname: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="edit_firstname">&nbsp;
                                        <label for="" style="font-size:12px">Middlename: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="edit_middlename">&nbsp;
                                        <label for="" style="font-size:12px">Lastname: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="edit_lastname">&nbsp;
                                        <label for="" style="font-size:12px">Gender: &nbsp;</label>
                                        <select name="" id="" class="form-control edit-input" v-model="edit_gender">
                                            <option value="">-Select Gender-</option>
                                            <option value="Male">Male </option>
                                            <option value="Female">Female </option>
                                        </select>&nbsp;
                                        <label for="" style="font-size:12px">Phone: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="edit_phone">&nbsp;
                                        <label for="" style="font-size:12px">Email: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="edit_email">&nbsp;
                                        <label for="" style="font-size:12px">Address: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="edit_address">&nbsp;
    
                                        <button class="btn btn-success btn-block input-button" @click="updateGuest" v-if="!loading8"><i class="fa fa-upload"></i> Update </button>
                                        <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading8">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Updating...
                                        </button>
    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close2" @click="closeMenu4"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    
        <!-- room extension-->
        <div v-if="room_extension_status">
            <transition name="modal">
                <div class="modal-mask3">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile2" @click="closeMenu5"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        Room Extension
                                    </h3>
                                    <div class="order-content">
                                        <label for="" style="font-size:12px">Extension date: &nbsp;</label>
                                        <input type="date" class="form-control edit-input" v-model="extension_date" required >&nbsp;
                                        <label for="" style="font-size:12px">Extension amount: &nbsp;</label>
                                        <input type="text" class="form-control edit-input" v-model="extension_amount">&nbsp;
    
                                        <button class="btn btn-success btn-block input-button" @click="extendReservation" v-if="!loading12"> Extend my stay </button>
                                        <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading12">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                           
                                        </button>
    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close2" @click="closeMenu5"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    
        <!-- new payment modal-->
        <div v-if="new_payment_status">
            <transition name="modal">
                <div class="modal-mask4">
                    <div class="modal-wrapper col-md-6" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile2" @click="closeMenu6"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        <br>
                                        Guest Payment
                                    </h3>
                                    <div style="margin-top: 10px;">
                                        <v-autocomplete
                                        v-model="guest_name"
                                        :items="guestLists"
                                        @change="searchGuest"
                                        dense
                                        label="Search Guest"
                                       ></v-autocomplete>
                                        <div class="list-category" v-if="guest_found">
                                            <table id="myTable" class="table table-bordered table-striped" style="font-size:8px !important">
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Fullname </th>
                                                        <th>Room </th>
                                                        <th>Rate </th>
                                                        <th>Amount </th>
                                                        <th>Extension</th>
                                                        <th>Grand Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style="font-size:9px" v-for="(data, index) in guest_payments" :key="index">
                                                        <td style="font-weight: 400; width:auto">
                                                            {{ index +1 }}
                                                        </td>
                                                        <td style="font-weight: 400; width:auto">
                                                            {{ data.firstname }} {{ data.middlename }} {{ data.lastname }}
                                                        </td>
                                                        <td style="font-weight: 400; width:auto">
                                                            {{ data.category_name }} ({{ data.module_route }})
                                                        </td>
                                                        <td style="font-weight: 400; width:auto">
                                                            NGN{{ data.room_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} x  {{ data.no_days }} Nights
                                                        </td>
                                                        <td style="font-weight: 400; width:auto">
                                                            NGN{{ reservationAmount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                                        </td>
                                                        <td style="font-weight: 400; width:auto" v-if="data.extended_days == NULL">
                                                           N/A
                                                        </td>
                                                        <td style="font-weight: 400; width:auto" v-else>
                                                            {{ data.total_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} - {{ data.extended_days }}(Nights)
                                                         </td>
                                                         <td>
                                                           NGN {{ grandTotal.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                                         </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-if="guest_found" style="margin-top: -10px">
                                            <label for="" style="font-size:12px">Payment description</label>
                                            <input type="text" class="form-control edit-input" placeholder="Enter payment description" v-model="payment_description" required>
                                            <button class="btn btn-success btn-block input-button" @click="submitGuestPayment" v-if="!loading14"> Submit </button>
                                            <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading14">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close2" @click="closeMenu6"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    
    </div>
    </template>
    
    <script>
    //import Vue from 'vue'
    import swal from 'sweetalert2';
    //import $ from "jquery";
    
    export default {
    
        data() {
            return {
      
                report: [],
                app_role: '',
                cfirstname: '',
                clastname: '',
                cusername: '',
                userid: '',
                check_items: [],
               
                modules_status: true,
                assign_modules_status: false,
                edit_item: false,
                category_name: '',
                categories: [],
                edit_category_name: '',
                item_id: '',
                loading: false,
                loading2: false,
                loading3: false,
                loading4: false,
                loading5: false,
                loading6: false,
                loading7: false,
                loading8: false,
                loading9: false,
                loading10: false,
                loading11: false,
                loading12: false,
                loading13: false,
                loading14: false,
                show_rm_category: false,
                show_add_modules: false,
                show_list_modules: true,
                show_list_assign_modules: true,
                show_guest_information: false,
                room_extension_status: false,
                panel_name: "List Modules",
                panel_header: "Application Modules",
                room_type: '',
                module_route: '',
                bed_type: '',
                room_capacity: '',
                module_name: '',
                room_amount: '',
                modules: [],
                searchby_modulename: '',
                vmodules_status: '',
                edit_id: '',
                edit_room_type: '',
                edit_module_route: '',
                edit_room_capacity: '',
                edit_bed_type: '',
                edit_module_name: '',
                edit_room_amount: '',
                edit_module_data: false,
                gender: '',
                url: '',
                assignmodules: [],
                assign_module_search: '',
                user_role_id: 2,
                update_user_role_id: false,
                reservation_id: '',
                user_role_id_input: '',
                guest_roomid: '',
                guest_phone_number: '',
                guest_info: [],
                edit_quest_id: '',
                edit_firstname: '',
                edit_middlename: '',
                edit_lastname: '',
                edit_gender: '',
                edit_phone: '',
                edit_email: '',
                edit_address: '',
                edit_guest_data: false,
                checkout_date: '',
                reservation_summary: true,
                occupancy_report: false,
                revenue_report: false,
                roomtype_report: false,
                vuser_role_id: '',
                date_to: '',
                date_from: '',
                date_to2: '',
                date_from2: '',
                date_to3: '',
                date_from3: '',
                date_to4: '',
                date_from4: '',
                summaryReservation: [],
                response: '',
                occupancyReport: [],
                revenueReports:[],
                roomtypeReports:[],
                bookedStatus: '',
                extension_amount: '',
                extension_date: '',
                extension_reservation_id:'',
                ext_checkout_date: '',
                list_payments: false,
                guestPayments: [],
                payment_date_from: '',
                payment_date_to: '',
                guest_fullname: '',
                new_payment_status: false,
                guest_found: false,
                guest_name: '',
                guest_payments: [],
                payment_description: '',
                guestLists: [],
    
            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
            this.app_role = localStorage.getItem("app_status");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");

            this.fetchAllModules();
            this.fetch_user_module(this.user_role_id)
            this.url = "take-reservation/";
    
        },
    
        created() {
          
        },
        beforeDestroy() {
            clearInterval(this.timerInterval);
        },
        computed: {
            filteredMenu() {
                const searchString = this.searchby_modulename.toLowerCase();
                return this.modules.filter(item => item.module_route.toLowerCase().includes(searchString));
            },
               
            filteredGuest() {
                const searchString = this.guest_phone_number.toLowerCase();
                return this.guest_info.filter(item => item.phone.toLowerCase().includes(searchString));
            },
    
            totalAmountPaid() {
              return this.revenueReports.reduce((total, data) => total + data.amountPaid, 0);
            },
    
            reservationAmount(){
                return this.guest_payments.reduce(
                    (accumulator, item) => item.no_days * item.room_amount,
                    0
                );
            },
    
            grandTotal() {
                return this.guest_payments.reduce(
                    (accumulator, item) => item.total_amount + this.reservationAmount,
                    0
                );
            },
    
            reservationID(){
                return this.guest_payments.reduce(
                    (accumulator, item) => item.reservation_id,
                    0
                );
            },

            extensionID(){
                return this.guest_payments.reduce(
                    (accumulator, item) => item.extension_id,
                    0
                );
            }
    
        },
    
        methods: {

            toggleCheck(id) {
                const index = this.check_items.indexOf(id);
                if (index === -1) {
                    // If id is not in the array, add it
                    this.check_items.push(id);
                } else {
                    // If id is in the array, remove it
                    this.check_items.splice(index, 1);
                }
            }, 
            async fetchYears() {
    
                try {
                    const response = await this.$http.get('/fetch-years', )
                    this.years = response.data.years;
    
                } catch (err) {
                    console.log(err);
                }
    
            },
    
            tabModules() {
                this.modules_status = true
                this.assign_modules_status = false
                this.panel_header = "Application Modules"
                this.panel_name = "List Modules"
    
                const tab1 = document.getElementsByClassName("tab-modules");
                tab1[0].style.color = "#fff";
    
                const tab2 = document.getElementsByClassName("tab-assignmodules");
                tab2[0].style.color = "#000";
    
            },
    
            tabAssignModules() {
                this.modules_status = false
                this.assign_modules_status = true
                this.show_list_assign_modules = true
                this.panel_header = "Assign Modules"
                this.panel_name = "List Assign Modules"
    
                const tab1 = document.getElementsByClassName("tab-modules");
                tab1[0].style.color = "#000";
    
                const tab2 = document.getElementsByClassName("tab-assignmodules");
                tab2[0].style.color = "#fff";
    
            },
    
            editItem(itemid, title) {
                try {
                    this.item_id = itemid
                    this.edit_category_name = title
    
                    this.edit_item = true
    
                } catch (err) {
                    console.log(err);
                }
            },
    
    
            closeMenu() {
                this.edit_item = false
            },
        
            add_modules() {
                this.show_add_modules = true
                this.show_list_modules = false
                this.panel_name = "Add Modules"
            },
    
            list_modules() {
                this.fetchAllModules()
                this.show_list_modules = true
                this.show_add_modules = false
                this.panel_name = "List Modules"
            },
    
            async addmodules() {
                try {
    
                    if (!this.module_route) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter module route',
                        })
                        return;
                    }
                    if (!this.module_name) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter module name',
                        })
                        return;
                    }

                    this.loading3 = true;
    
                    this.response = await this.$http.post('/create-modules', {
                        module_route: this.module_route,
                        module_name: this.module_name,
                        userid: this.userid
                    });
    
                    this.loading3 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                    }
                    this.module_route = ""
                    this.module_name = ""
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchAllModules() {
                try {
                    const response = await this.$http.get('/list-modules', )
                    this.modules = response.data.modules;
    
                } catch (err) {
                    console.log(err);
                }
            },
    
           
    
            editModules(id, module_route, module_name) {
                try {
                        this.edit_id = id,
                        this.edit_module_route = module_route,
                        this.edit_module_name = module_name,

                        this.edit_module_data = true
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            closeMenu2() {
                this.edit_module_data = false
            },
    
            async updateModule() {
                try {
    
                    this.loading4 = true;
    
                    this.response = await this.$http.post('/update-modules', {
                        moduleid: this.edit_id,
                        module_route: this.edit_module_route,
                        module_name: this.edit_module_name,
                        userid: this.userid
                    });
    
                    this.loading4 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                        this.fetchAllModules()
                        this.edit_module_data = false
    
                    }
                    this.edit_module_route = ""
                    this.edit_module_name = ""
    
                } catch (err) {
                    console.log(err);
                }
            },

            async deleteModules(moduleid){
                try {
                    var msg = confirm("                You are about to delete this module. Are you sure?");
                    if(msg==true){
                        this.response = await this.$http.post('/delete-module', {
                            moduleid: moduleid,
                        });

                        if (!this.response.data.success) {
                            swal.fire({
                                icon: 'error',
                                width: 450,
                                height: 5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;

                        } else {

                            swal.fire({
                                icon: 'success',
                                width: 450,
                                height: 5,
                                title: "Success",
                                text: this.response.data.message,
                            });

                            this.fetchAllModules()
                            return;
                        }
                    }
                   
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchAllAssignModules() {
                try {
                    const response = await this.$http.get('/list-assigned-modules', )
                    this.assignmodules = response.data.assigned_modules;
                      
                } catch (err) {
                    console.log(err);
                }
            },

            async assignModules(){
                try {

                    if (!this.user_role_id) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: "Please select user role",
                        });
                        return;
                    } 
                    this.loading2 = true
                    const response = await this.$http.post('/assign-modules',{
                        user_role: this.user_role_id,
                        module_id: this.check_items,
                        userid: this.userid
                    })
                    this.loading2 = false
                    if (!response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: response.data.message,
                        });
                        return;
                    } 
                    else{
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: response.data.message,
                        });
                        this.check_items = []
                        this.fetch_user_module(this.user_role_id)
                        return;
                    }
                   
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            updateGuestStatus(gueststatus, reservationid, guest_roomid) {
                try {
                    this.bookedStatus = gueststatus
                    this.reservation_id = reservationid
                    this.guest_roomid = guest_roomid
    
                    this.update_user_role_id = true
                } catch (err) {
                    console.log(err);
                }
            },
    
            async update_guestStatus() {
                try {
    
                    this.loading7 = true;
    
                    this.response = await this.$http.post('/update-guest-status', {
                        guest_roomid: this.guest_roomid,
                        reservation_id: this.reservation_id,
                        user_role_id: this.user_role_id_input,
                    });
    
                    this.loading7 = false;
    
                    if (!this.response.data.success) {
    
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
    
                        this.update_user_role_id = false
                        this.user_role_id_input = ''
    
                        this.fetchAllReservations()
    
                        return;
    
                    }
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            closeMenu3() {
                this.update_user_role_id = false
            },
    
            async fetch_user_module(id) {
                try {
                    const response = await this.$http.post('/list-usermodules', {
                        user_role: id
                    })
                    this.assignmodules = response.data.assigned_modules;    
                } catch (err) {
                    console.log(err);
                }
            },

            async fetch_user_modules() {
                try {
                    const response = await this.$http.post('/list-usermodules', {
                        user_role: this.user_role_id
                    })
                    this.assignmodules = response.data.assigned_modules;    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchGuestInformation() {
                try {
                    const response = await this.$http.get('/fetch-guest-information', )
                    this.guest_info = response.data.guest_info;
    
                    response.data.guest_info.forEach(element => {
                        this.guestLists.push({
                            value: element.guess_id,
                            text: element.firstname + ' ' + element.lastname
                        })
                    });
                   
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            editGuest(guest_id, firstname, middlename, lastname, gender, phone, email, address) {
                try {
                    this.edit_quest_id = guest_id,
                        this.edit_firstname = firstname,
                        this.edit_middlename = middlename,
                        this.edit_lastname = lastname,
                        this.edit_gender = gender,
                        this.edit_phone = phone,
                        this.edit_email = email,
                        this.edit_address = address
    
                    this.edit_guest_data = true
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            closeMenu4() {
                this.edit_guest_data = false
            },
    
            async updateGuest() {
                try {
    
                    this.loading8 = true;
    
                    this.response = await this.$http.post('/update-guest', {
                        guestid: this.edit_quest_id,
                        firstname: this.edit_firstname,
                        middlename: this.edit_middlename,
                        lastname: this.edit_lastname,
                        gender: this.edit_gender,
                        phone: this.edit_phone,
                        email: this.edit_email,
                        address: this.edit_address,
                    });
    
                    this.loading8 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                        this.fetchGuestInformation()
                        this.edit_guest_data = false
                    }
                    this.edit_firstname = "",
                        this.edit_middlename = "",
                        this.edit_lastname = "",
                        this.edit_gender = "",
                        this.edit_phone = "",
                        this.edit_email = "",
                        this.edit_address = ""
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            reservation_summary_report(){
              this.reservation_summary = true
              this.occupancy_report = false
              this.revenue_report = false
              this.roomtype_report = false
              this.panel_header = "Reports"
              this.panel_name = "Reservation Summary"
            },
    
            occupancy_rate_report(){
              this.reservation_summary = false
              this.occupancy_report = true
              this.revenue_report = false
              this.roomtype_report = false
              this.panel_header = "Reports"
              this.panel_name = "Occupancy Rate"
            },
    
            revenue_reports(){
              this.reservation_summary = false
              this.occupancy_report = false
              this.revenue_report = true
              this.roomtype_report = false
              this.panel_header = "Reports"
              this.panel_name = "Revenue Analysis"
            },
    
            room_type_report(){
              this.reservation_summary = false
              this.occupancy_report = false
              this.revenue_report = false
              this.roomtype_report = true
              this.panel_header = "Reports"
              this.panel_name = "Room Type Performance"
            },
    
            async reservationSummary() {
                try {
    
                    this.loading9 = true;
    
                    this.response = await this.$http.post('/reservation-summary', {
                        user_role_id: this.vuser_role_id,
                        date_to: this.date_to,
                        date_from: this.date_from,
                    });
    
                    this.loading9 = false;
                    this.summaryReservation = this.response.data.assignmodules;
    
                    if (!this.summaryReservation) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Not found",
                            text: "Record not found",
                        });
                        return;
    
                    } 
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async occupancyRate() {
                try {
    
                    this.loading9 = true;
    
                    this.response = await this.$http.post('/occupancy-rate', {
                        date_to: this.date_to2,
                        date_from: this.date_from2,
                    });
    
                    this.loading9 = false;
                    this.occupancyReport = this.response.data.occupancy;
    
                    if (!this.occupancyReport) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Not found",
                            text: "Record not found",
                        });
                        return;
    
                    } 
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async revenueReport() {
                try {
    
                    this.loading10 = true;
    
                    this.response = await this.$http.post('/revenue-report', {
                        date_to: this.date_to3,
                        date_from: this.date_from3,
                    });
    
                    this.loading10 = false;
                    this.revenueReports = this.response.data.revenue;
    
                    if (!this.revenueReports) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Not found",
                            text: "Record not found",
                        });
                        return;
    
                    } 
    
                } catch (err) {
                    console.log(err);
                }
            }
            ,
            async roomtypeReport() {
                try {
    
                    this.loading11 = true;
    
                    this.response = await this.$http.post('/roomtype-performance', {
                        date_to: this.date_to4,
                        date_from: this.date_from4,
                    });
    
                    this.loading11 = false;
                    this.roomtypeReports = this.response.data.room_performance;
    
                    if (!this.roomtypeReports) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Not found",
                            text: "Record not found",
                        });
                        return;
    
                    } 
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            room_extension(reservationid, check_out_date){
                this.extension_reservation_id = reservationid,
                this.ext_checkout_date = check_out_date
                this.room_extension_status = true
            },
    
           async extendReservation(){
                try {
                                           
                    if (!this.extension_date) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please extension date',
                        })
                        return;
                    }                
                    if (!this.extension_amount) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter extension rate',
                        })
                        return;
                    }
                        
                    const daysDifference = this.calculateDaysDifference(this.ext_checkout_date, this.extension_date); // calculate date diff
    
                    this.loading12 = true;
    
                    this.response = await this.$http.post('/room-extension', {
                        reservationid: this.extension_reservation_id,
                        extension_date: this.extension_date,
                        days_difference: daysDifference,
                        amount: this.extension_amount,
                        userid:  this.userid,
                    });
    
                    this.loading12 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                    } else {
    
                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: this.response.data.message,
                        });
                    }
                    this.fetchAllReservations()
                    this.extension_date = ""
                    this.extension_amount = ""
                    this.room_extension_status = false
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            closeMenu5(){
                this.room_extension_status = false
            },
    
             // calculate date diff
             calculateDaysDifference(date1, date2) {
                const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
    
                // Parse the input dates
                const startDate = new Date(date1);
                const endDate = new Date(date2);
    
                // Calculate the difference in days
                const diffInDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    
                return diffInDays;
            },
    
            async guestPaymentList() {
                try {
    
                    this.response = await this.$http.get('/payment-lists', {
                    });
    
                    this.guestPayments = this.response.data.paymentList;
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async guestPaymentListByCrateria() {
                try {
    
                    this.loading13 = true;
    
                    this.response = await this.$http.post('/payment-lists-bycateria', {
                        guest_name: this.guest_fullname,
                        date_to: this.payment_date_from,
                        date_from: this.payment_date_to,
                    });
    
                    this.loading13 = false;
                    this.guestPayments = this.response.data.paymentList;
    
                    if (!this.guestPayments) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Not found",
                            text: "Record not found",
                        });
                        return;
    
                    } 
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            new_payment(){
                this.new_payment_status = true
            },
    
            closeMenu6(){
                this.new_payment_status = false
                this.guest_found = false
                this.guest_payments = ''
                this.guest_name = ''
            },
    
            async searchGuest(){
                try {
    
                    this.response = await this.$http.post('/search-guest-payment', {
                        guest_name: this.guest_name,
                    });
    
                    this.guest_payments = this.response.data.paymentList;
    
                if (this.guest_payments =='') {
                    swal.fire({
                        icon: 'error',
                        width: 450,
                        height: 5,
                        title: "Not found",
                        text: "Record not found",
                    });
                    this.guest_found = false
                    return;
                } 
                else{
                    this.guest_found = true
                }
    
                } catch (err) {
                console.log(err);
                }
            },
    
            async submitGuestPayment(){
                try {
    
                    this.response = await this.$http.post('/submit-guest-payment', {
                        guest_id: this.guest_name,
                        reservation_id: this.reservationID,
                        extension_id: this.extensionID,
                        grand_total: this.grandTotal,
                        payment_description: this.payment_description,
                        userid: this.userid
                    });
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        this.guest_found = true
                        return;
                    } 
                    else{
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                        this.guestPaymentList()
                        this.new_payment_status = false
                        return;
                    }
    
                } catch (err) {
                console.log(err);
                }
            }
    
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 35%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask4 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .background {
        height: auto;
        background: #ccc;
        width: 100%;
        border-radius: 10px;
    
        .container {
            background: transparent;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            height: auto;
    
            .card {
                margin: 6px;
                width: 100%;
                padding: 4px;
                background: transparent;
                font-size: 12px;
                border: none;
                color: #000;
                height: auto;
    
                .menu-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: left;
                    align-items: center;
                    width: 95%;
                    font-family: Mulish;
                    box-sizing: border-box;
                    height: 50px;
                    background: #000;
                    padding: 10px;
                    border-radius: 4px;
                }
    
                .tab {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 95%;
                    font-family: Mulish;
                    box-sizing: border-box;
                    padding: 10px;
                    border-radius: 4px;
                    margin-top: 15px;
                }
    
                .tab-modules {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    font-size: 10px;
                    box-sizing: border-box;
                    height: 20px;
                    background: #FFA500;
                    padding: 10px;
                    border-right: solid 2px #fff;
                    border-bottom: solid 1px #fff;
                    color: #fff;
                    cursor: pointer;
                }
    
                .tab-modules:hover {
                    font-weight: bold;
                    color: #000;
                    border-bottom: solid 1px #fff;
                }
    
                .tab-assignmodules {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    font-size: 10px;
                    box-sizing: border-box;
                    height: 20px;
                    background: #FFA500;
                    padding: 10px;
                    color: #000;
                    cursor: pointer;
                }
                .tab-assignmodules:hover {
                    font-weight: bold;
                    color: #000;
                    border-bottom: solid 1px #fff;
                }
                .tab-process-payment {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    font-size: 10px;
                    box-sizing: border-box;
                    height: 20px;
                    background: #FFA500;
                    padding: 10px;
                    border-right: solid 2px #fff;
                    color: #000;
                    cursor: pointer;
                }
                .tab-process-payment:hover {
                    font-weight: bold;
                    color: #000;
                    border-bottom: solid 1px #fff;
                }
                .tab-housekeepings {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    font-size: 10px;
                    box-sizing: border-box;
                    height: 20px;
                    background: #FFA500;
                    padding: 10px;
                    border-right: solid 2px #fff;
                    color: #000;
                    cursor: pointer;
                }
    
                .tab-housekeepings:hover {
                    font-weight: bold;
                    color: #000;
                    border-bottom: solid 1px #fff;
                }
    
                .tab-reports {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    font-size: 10px;
                    box-sizing: border-box;
                    height: 20px;
                    background: #FFA500;
                    padding: 10px;
                    //border-right: solid 2px #fff;
                    color: #000;
                    cursor: pointer;
                }
    
                .tab-reports:hover {
                    font-weight: bold;
                    color: #000;
                    border-bottom: solid 1px #fff;
                }
    
                .reservation-container {
                    margin: 15px;
    
                    p {
                        margin-top: -10px;
                        font-size: 10px;
                        font-weight: 650;
                        font-family: Mulish;
                    }
                }
    
                .reservation-container2 {
                    margin: 15px;
    
                    p {
                        margin-top: -10px;
                        font-size: 10px;
                        font-weight: 650;
                        font-family: Mulish;
                    }
                }
    
                .reservation-container3 {
                    margin: 15px;
    
                    p {
                        margin-top: -10px;
                        font-size: 10px;
                        font-weight: 650;
                        font-family: Mulish;
                    }
                }
    
                .search-input {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 90%;
                    font-size: 10px !important;
                }
    
                .search-name {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    text-align: left;
                    color: #000;
                    margin-bottom: -2px;
                }
    
                .orderid {
                    width: 10%;
                    height: 25px;
                    margin-bottom: 2px;
                    border: 1px solid #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                }
    
                .searchdate {
                    width: 13%;
                    height: 25px;
                    margin-bottom: 2px;
                    border: 1px solid #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                }
    
                .btn-search1 {
                    width: 8%;
                    height: 25px;
                    margin-bottom: 2px;
                    border: 1px solid #000;
                    background: #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                    color: #fff;
                }
    
                .rm-menu {
                    margin: 4px;
                }
    
                .rm-category:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .rm-category3:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .add-rm:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                .add-rm3:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .list-rm:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                .list-rm3:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .reservation-content {
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
                    width: 95%;
                    padding: 10px;
                    background: #ccc;
                    height: auto;
    
                    p {
                        margin-top: 5px;
                        font-size: 14px;
                        font-weight: 700;
                        font-family: Mulish;
                    }
                }
    
                .rm-menu2 {
                    margin: 4px;
                }
    
                .add-rm2:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .list-rm2:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .reservation-content2 {
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
                    width: 95%;
                    padding: 10px;
                    background: #ccc;
    
                    p {
                        margin-top: 5px;
                        font-size: 14px;
                        font-weight: 700;
                        font-family: Mulish;
                    }
                }
    
                .reservation-content3 {
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
                    width: 95%;
                    padding: 10px;
                    background: #ccc;
    
                    p {
                        margin-top: 5px;
                        font-size: 14px;
                        font-weight: 700;
                        font-family: Mulish;
                    }
                }
    
                .rm-menu3 {
                    margin: 4px;
                }
    
                .add-rm3:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .list-rm3:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
    
                .guest-information {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
    
                    .guests {
                        font-size: 10px;
                        font-weight: 400 !important;
                        cursor: pointer;
                    }
    
                    .guests:hover {
                        text-decoration: underline;
                    }
                }
    
                .add-category {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
    
                .add-category2 {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
    
                .add-room {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
    
                .add-room2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
    
                .add-rm-center {
                    width: 50%;
    
                    .form-control {
                        border: solid 1px #000;
                        border-radius: 4px;
                        height: 30px;
                        font-size: 12px;
                    }
    
                    .btn-primary {
                        background: #000;
                        font-size: 12px;
                        height: 30px;
                        border-radius: 4px;
                        width: 100%;
    
                    }
                }
    
                .add-rm-center2 {
                    width: 50%;
    
                    .form-control {
                        border: solid 1px #000;
                        border-radius: 4px;
                        height: 30px;
                        font-size: 12px;
                    }
    
                    .btn-primary {
                        background: #000;
                        font-size: 12px;
                        height: 30px;
                        border-radius: 4px;
                        width: 100%;
    
                    }
                }
    
                .list-category {
                    margin-top: 10px;
                    overflow-x: auto;
                    overflow-y: auto;
                    height: 400px;
                    .category-edit {
                        cursor: pointer;
                        color: green;
                        font-size: 10px;
                    }
    
                    .category-delete {
                        cursor: pointer;
                        color: #f50404;
                        font-size: 10px;
                    }
                }
    
                .list-category2 {
                    margin-top: 10px;
                    overflow-x: auto;
                    overflow-y: auto;
    
                    //height: 350px;
                    .category-edit {
                        cursor: pointer;
                        color: green;
                        font-size: 10px;
                    }
    
                    .category-delete {
                        cursor: pointer;
                        color: #f50404;
                        font-size: 10px;
                    }
                }
    
                .input {
                    border: solid 1px #000;
                    border-radius: 4px;
                    width: 30%;
                    height: 30px;
                    font-size: 12px;
                }
    
                .btn-primary {
                    background: #000;
                    font-size: 12px;
                    height: 30px;
                    width: 10%;
    
                }
            }
    
            img {
                width: 50px;
                border-radius: 40px;
            }
    
            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 20px;
                text-align: left;
                color: #fff;
            }
        }
    }
    
    @media screen and (max-width: 768px) {
    
        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: 25%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #ccc;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 1px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 1px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    }
    
    @media screen and (max-width: 768px) {
        .background {
            background: #ccc;
            width: 100%;
            height: 100vh;
    
            .container {
                background: #ccc;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 30px;
    
                .card {
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
    
                    .menu-bar {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        height: 50px;
                        background: #000;
                        padding: 10px;
                        border-radius: 4px;
                    }
    
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        padding: 4px;
                        border-radius: 4px;
                        margin-top: 15px;
                    }
    
                    .tab-modules {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #f50404;
                        padding: 0px;
                        border-right: solid 2px #fff;
                        border-bottom: solid 1px #fff;
                        color: #fff;
                        cursor: pointer;
                    }
    
                    .tab-assignmodules {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #f50404;
                        padding: 1px;
                        border-right: solid 2px #fff;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .tab-housekeepings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #f50404;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .reservation-container {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .reservation-container2 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .reservation-container3 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .search-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 10px !important;
                    }
                    .searchdate {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                    }
        
                    .btn-search1 {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        background: #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                        color: #fff;
                    }
    
                    .rm-menu {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .rm-category:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .add-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .reservation-content {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .rm-menu2 {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .add-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .reservation-content2 {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .add-room {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-room2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-rm-center {
                        width: 100%;
    
                        .form-control {
                            border: solid 1px #000;
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primary {
                            background: #000;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-rm-center2 {
                        width: 100%;
    
                        .form-control {
                            border: solid 1px #000;
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primary {
                            background: #000;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-category {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .add-category2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .input {
                        width: 100%;
                        height: 30px;
                    }
    
                    .btn-primary {
                        margin-top: -10px;
                        width: 100%;
                        height: 30px;
                    }
    
                    img {
                        width: 50px;
                    }
                }
    
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: left;
                    color: #fff;
                }
    
            }
        }
    }
    </style>
    