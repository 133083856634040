  <template>
    <div>
        <div class="product-container">
            <div class="column9">
                <div class="product-cart-number">
                    Cart({{ cart_length }})
                </div>
                <div v-if="cart_items == ''">
                  <div class="cart-0">
                      <div class="empty-cart">
                          <i class="fa fa-shopping-bag"></i>
                          <p class="cart-e">Your cart is empty </p>
                          <p class="cart-e2">Shop from our collections </p>
                         <router-link to="shopping">
                            <button class="btn btn-shopping">start shopping</button>
                         </router-link>
                      </div>
                  </div>
                </div>
                <div v-else>
                  <div class="cart-0" v-for="(data, index) in cart_items" :key="index">
                    <div class="product">
                        <div class="product-img">
                            <img class="img" :src="img_url +'products/'+ data.product_image" alt="">
                        </div>
                        <div class="product-detail">
                            <div class="product-name">
                                <p class="cart-title">{{ data.product_name}}</p>
                                <div class="variation-style" v-if="data.selected_variation !=null">
                                  <p class="brand">Variation: {{ data.selected_variation }}  </p>
                                </div>
                               
                                <p class="in-stock" v-if="data.in_stock == 1">In stock</p>
                                <p class="in-stock text-danger" v-if="data.in_stock == 0">Out of stock</p>
                            </div>
                        </div>
                        <div class="product-price">
                            <p class="price">₦{{ data.unit_price.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}}</p>
                        </div>
                    </div>  
                    <div class="product-counter-remove">
                       <button class="btn-remove" @click="removeItem(data.cartid)"><i class="fa fa-trash"></i> Remove</button>
                        <div class="counter">
                            <button class="btn-counter" @click="decrement(data)" > <span class="counter-action">-</span> </button> 
                            <input type="text" v-model="data.qty" class="login-modal-box2" readonly> 
                            <button class="btn-counter" @click="increment(data)"> <span class="counter-action">+</span> </button>
                        </div>
                    </div> 
                </div>
                </div>                         
            </div>

            <div class="column3">
              <div class="delivery-status">
                <div class="delivery-return">
                 cart summary
                </div>
                <div  class="d-info">
                 <span>Subtotal:</span>  <span>₦{{ total_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</span> 
                </div>
                <div class="check-out">
                  <button id="submit4" class="btn btn-primary" :disabled="isCartEmpty" @click="checkout()" v-if="!loading">check out (₦{{ total_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}})</button>
                  <button class="btn btn-primary" type="button" disabled v-if="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
              </div>
            </div>
        </div>  
        <P>&nbsp;</P> 
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../../components/PageLoader.vue'
    
  export default {
    
    name: "CartPage",
    components: {
        //quillEditor,
        PageLoader
    },
  
    data(){
      return {

          isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: process.env.VUE_APP_IMAGE_URL,
          cart_items: '',
          cart_id: '',
          cart_length: '',
          total_amount: '',
          counter: 1,
          
      };
    },
  
    beforeMount(){
      //this.calculateOrder()
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");

        this.fetchCartInformation(this.cart_id) 
       
    },
  
    created(){
          
    },
  
    computed: {
      isCartEmpty() {
        return (
          this.cart_items == ''
        );
      },
    },
  
    methods: {
      calculateOrder(){
            this.total_amount = this.cart_items.reduce(
                (accumulator, item) => accumulator + item.unit_price * item.qty,
                0
            );
        },

        increment(data) {
            data.qty++; // Increment the quantity for the specific data item
            this.calculateOrder();
        },

        decrement(data) {
            if (data.qty > 1) {
                data.qty--; // Decrement the quantity for the specific data item (if it's greater than 0)
                this.calculateOrder();
            }
        },
      async fetchCartInformation(cart_id) {
          try {

              this.isLoading = true;
              const response = await this.$http.post('/fetch-cart-byunique_code', {
                cart_unique_code: cart_id
              })

              this.cart_items = response.data.cart;
              this.cart_length =  this.cart_items.length
              this.$store.dispatch('updateCartCount', this.cart_items.length);
              this.calculateOrder()
              this.isLoading = false;

          } catch (err) {
              console.log(err);
          }
      },

      async removeItem(cartid){
        let msg = confirm("     You are about to remove this item from the cart. Are you sure?");
          if(msg==true){
            
          try {
              const response = await this.$http.post('/delete-cart', {
                cartid: cartid
              })

              if (response.data.success) {
                swal.fire({
                    icon: "success",
                    title: "Success",
                    width: 450,
                    height: 5,
                    text: response.data.message,
                });
                this.fetchCartInformation(this.cart_id)  

                return;
            }

          } catch (err) {
              console.log(err);
          }
        }
      },

      async checkout(){
        try {
                this.loading = true
                const response = await this.$http.post('/update-cart', {
                    user_id: this.userid,
                    cart_items: this.cart_items,
                })
                this.loading = false
                if (response.data.success) {
                  this.$router.push('/checkout/summary')
                }
                           
          } catch (err) {
              console.log(err);
          }
      },

    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;

  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .empty-cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .cart-e{
    padding: 0.9rem;
    font-family: Mulish;
    font-size: 14px;
  }
  .cart-e2{
    font-family: Mulish;
    font-size: 14px;
    margin-top: -20px;
  }
  .btn-shopping{
    background: #00802f;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 14px;
    padding: 0.5rem;
    font-family: Mulish;
  }
  .fa-shopping-bag{
    font-size: 60px;
    color: #00802f;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.9rem;
    font-family: Mulish;
    background: #fff;
    border-bottom: solid 1px #bdbaba;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #00802f;
    color: #fff;
    border-radius: 4px;
}
.counter-action{
    font-size: 13px;
}
.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem;
    
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #00802f;
        font-size: 15px;
      }
    .btn-remove:hover{
      color: #a5a3a3;
    }
  }
  
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  .product:hover{
    //border: solid 1px #071278;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .check-out{
    margin-top:60px; 
    a{
      text-decoration: none
    }
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
  }
  .product-name{
    //border-bottom: solid 2px #a5a3a3;
    font-family: Mulish;
    .cart-title{
        font-size: 17px;
        font-weight: 300;
        color: #2b2929;
    }
    .brand{
        margin-top: -10px;
        font-size: 13px;
    }
    .in-stock{
        font-size: 12px;
        line-height: 1;
        margin-top: -10px;
        color: #a5a3a3;
      }
  }
  .product-price{
    .price{
        padding: 16px 0;
        font-size: 20px;
        //font-weight: 600;
        color: #2b2929;
    }
   
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #00802f;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;

}
.delivery-return{
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
    margin-top: 30px;
    border-bottom: solid 1px #a5a3a37b;
}
.location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
}

  @media screen and (max-width: 768px) {

    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
      
      .column9{
        width: 100%;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>