<template>
    <div class="background">
        <div class="bg-content">
                <div class="menu-bar">
                    <h2> <i class="fas fa-users"></i> &nbsp;My Members</h2>
                </div>
                       
                <div class="users-container2">
                    <p><span class="rm-menu rm-category" @click="add_clients()"><i class="fa fa-plus"></i> Add Members</span> | <span class="rm-menu list-rm" @click="list_clients()"><i class="fa fa-eye"></i> List Members</span></p>
                    <div class="users-content">
                        <p><i class="fas fa-users"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
                        <div class="members-form" v-if="show_clients">
                            <div style="margin-top:10px;margin-bottom:10px; font-weight:800;">
                                MEMBERS' REGISTRATION FORM
                            </div>
                            <form @submit.prevent="submit_form" enctype="multipart/form-data">
                               
                            <center>
                                <div class="img-profile" v-if="previewImage == ''" style="margin-bottom:10px;">
                                    <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-0 overflow-hidden absolute" style="cursor:pointer" @change="pickFile"/>
                                </div>
                                <span v-else style="margin-bottom:10px;">
                                    <div class="img-profile" :style="{ 'background-image': `url(${previewImage})` }">
                                        <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-0 overflow-hidden absolute" style="cursor:pointer" @change="pickFile"/>
                                    </div>
                                </span>
                            </center>
                            
                           
                            <div class="row col-md-12">
                                <div class="col-md-2">
                                    <label for="">Title</label>
                                    <select class="form-control form-select" v-model="client_title" required>
                                        <option value="">-Select-</option>
                                        <option value="1"> Mr. </option>
                                        <option value="2"> Mrs. </option>
                                        <option value="3"> Dr. </option>
                                        <option value="4"> Miss </option>
                                        <option value="5"> Chief </option>
                                        <option value="6"> Barr. </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Firstname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter firstname"   v-model="client_firstname" required>&nbsp;
                                </div>
                                <div class="col-md-3">
                                    <label for="">Middlename</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter middlename (Optional)"   v-model="client_middlename">&nbsp;
                                </div>
                                <div class="col-md-4">
                                    <label for="">Surname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter surname"   v-model="client_surname" required>&nbsp;
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Gender</label>
                                    <select class="form-control form-select" v-model="client_gender">
                                        <option value="">-Select-</option>
                                        <option value="1"> Male </option>
                                        <option value="2"> Female </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Date of birth</label>
                                    <input type="date" class="form-control form-input"   v-model="client_dob" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Phone</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter phone number"   v-model="client_phone_number" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Email</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter email"   v-model="client_email_address" required>&nbsp;
                                </div>
                                
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Marital Status</label>
                                    <select class="form-control form-select" v-model="client_ms">
                                        <option value="">-Select-</option>
                                        <option value="1"> Married </option>
                                        <option value="2"> Single </option>
                                    </select>
                                </div>
                                <div class="col-md-3" v-if="client_ms == 1">
                                    <label for="">Spouse</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter spouse name"   v-model="client_spouse" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Mother's maiden name</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter mother's maiden name"   v-model="client_mother_name" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Religion</label>
                                    <select class="form-control form-select" v-model="client_religion">
                                        <option value="">-Select-</option>
                                        <option value="1"> Christianity </option>
                                        <option value="2"> Islam </option>
                                        <option value="3"> Others </option>
                                    </select>
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">State of origin</label>
                                    <select class="form-control form-select" v-model="client_state" @change="getStateLga()">
                                        <option value="">-Select-</option>
                                        <option v-for="(data, index) in states" :key="index" :value="data.id"> {{ data.state }} </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Lga of origin</label>
                                    <select class="form-control form-select" v-model="client_lga">
                                        <option value="">-Select-</option>
                                        <option v-for="(data, index) in lgas" :key="index" :value="data.id"> {{ data.lga }} </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Country of birth</label>
                                    <select class="form-control form-select" v-model="birth_country">
                                        <option value="">-Select-</option>
                                        <option value="1"> Nigeria </option>
                                        <option value="2"> Other </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Nationality</label>
                                    <select class="form-control form-select" v-model="client_nationality">
                                        <option value="">-Select-</option>
                                        <option value="1"> Nigeria </option>
                                        <option value="2"> Others </option>
                                    </select>
                                </div>
                               
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Residential/Contact address</label>
                                    <textarea  class="form-control form-input" v-model="client_contact_address" id="" cols="30" rows="10" placeholder="Enter contact address" required></textarea>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Whatsapp number(if any)</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter whatsapp number "   v-model="client_whatsapp_number" >
                                </div>
                                <div class="col-md-3">
                                    <label for="">Means of ID (NIN)</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter nin "   v-model="client_nin" >
                                </div> 
                                <div class="col-md-3">
                                    <label for="">Referral Id</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter referral "   v-model="referral_id" >
                                </div>                               
                            </div> 
                            <div style="margin-top:10px; margin-bottom:10px; font-weight:800;">
                                <center>  NEXT OF KIN </center>
                            </div>
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Title</label>
                                    <select class="form-control form-select" v-model="nok_title" required>
                                        <option value="">-Select-</option>
                                        <option value="1"> Mr. </option>
                                        <option value="2"> Mrs. </option>
                                        <option value="3"> Dr. </option>
                                        <option value="4"> Miss </option>
                                        <option value="5"> Chief </option>
                                        <option value="6"> Barr. </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Firstname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter firstname"   v-model="nok_firstname" required>&nbsp;
                                </div>
                                <div class="col-md-3">
                                    <label for="">Middlename</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter middlename (Optional)"   v-model="nok_middlename">&nbsp;
                                </div>
                                <div class="col-md-3">
                                    <label for="">Surname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter surname"   v-model="nok_surname" required>&nbsp;
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Gender</label>
                                    <select class="form-control form-select" v-model="nok_gender">
                                        <option value="">-Select-</option>
                                        <option value="1"> Male </option>
                                        <option value="2"> Female </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Date of birth</label>
                                    <input type="date" class="form-control form-input"   v-model="nok_dob" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Phone</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter phone number"   v-model="nok_phone_number" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Nature of business</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter nature of business"   v-model="nok_business" >&nbsp;
                                </div>
                                
                            </div> 
                            <div style="margin-top:10px">
                            <center>
                                <button style="width: 50%" class="form-control btn-success" v-if="!loading2" >
                                    Submit </button>
                                <button style="width: 50%" class="form-control btn-success" type="button" disabled v-if="loading2">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </center>
                            </div>
                            </form>
                        </div>
                       
                        <div v-if="show_list_clients">
                            <label for="">Search by member id: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter member id" v-model="search_account_number">&nbsp;
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Passport</th>
                                            <th>Fullname </th>
                                            <th>Member Id </th>
                                            <th>Gender </th>
                                            <th>Phone </th>
                                            <th>Email </th>
                                            <th>Referred By </th>
                                            <th>Payment made </th>
                                            <th>Payment status </th>
                                            <th>Wallet </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in filteredMember" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                               <img :src="image_url + data.picture" alt="photo" style="border-radius: 4px;" height="50" width="100"> 
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.firstname }} {{ data.middlename }} {{ data.lastname }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.account_number }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                               <span v-if="data.gender == 1">Male</span> 
                                               <span v-else-if="data.gender == 2">Female</span>
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.phone }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.email }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.referral_id }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                <span v-if="data.payment_made == 0" class="badge badge-danger">Not Paid</span>
                                                <span v-if="data.payment_made == 1" class="badge badge-success">Paid</span>
                                                <!-- <button class="badge badge-primary" @click="confirm_payment(data.members_id)">confirm payment</button> -->
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                <span v-if="data.payment_status == 0" class="badge badge-danger">Not confirm</span>
                                                <span v-if="data.payment_status == 1" class="badge badge-success">Confirm</span>
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                <span v-if="data.wallet_payment == 1">
                                                    <router-link :to="'/myusers-wallet/'+data.user_id"> <span style="color:green; cursor:pointer" ><button data-toggle="tooltip" title="View wallet" class="btn btn-success btn-sm btn-input"><i class="fa fa-eye"></i></button></span></router-link>
                                                </span>
                                                <span v-else-if="data.wallet_payment == 0">
                                                    <router-link :to="'/myusers-wallet/'+data.user_id"> <span style="color:red; cursor:pointer" ><button data-toggle="tooltip" title="View wallet" class="btn btn-danger btn-sm btn-input"><i class="fa fa-eye"></i></button></span></router-link>
                                                </span>
                                              
                                            </td>
                                                                                       
                                            <td style="font-weight: 400; display: flex; flex-direction: row">
                                                <router-link :to="url+data.members_id"> <span style="color:green; cursor:pointer" data-toggle="tooltip" title="Edit"><button data-toggle="tooltip" title="Edit" class="btn btn-primary btn-sm btn-input"><i class="fa fa-edit"></i></button></span></router-link>
                                                <button class="btn btn-success btn-sm btn-input" v-if="data.active_status == 1" @click="disable_member(data.user_id)"><i class="fa fa-user-secret"></i> Disable</button> &nbsp;
                                                <button class="btn btn-danger btn-sm btn-input" v-if="data.active_status == 0" @click="enable_member(data.user_id)"><i class="fa fa-user-secret"></i> Enable</button> &nbsp;
                                                <!-- <button data-toggle="tooltip" title="Delete" class="btn btn-danger btn-sm btn-input" @click="delete_member(data.user_id)"><i class="fa fa-trash"></i></button>  -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
    <script>
    //import Vue from 'vue'
    import swal from 'sweetalert2';
    import PageLoader from '../../components/PageLoader.vue'
    //import $ from "jquery";
    
    export default {
        components: {
            PageLoader,
        },
    
        data() {
            return {
                isLoading: false,
                report: [],
                app_role: '',
                cfirstname: '',
                clastname: '',
                cusername: '',
                userid: '',
                check_items: [],
                customer_order: false,
               
                staff_status: true,
                clients_status: false,
                housekeeping_status: false,
                process_payment: false,
                report_status: false,
                edit_item: false,
                category_name: '',
                categories: [],
                edit_category_name: '',
                item_id: '',
                loading: false,
                loading2: false,
                loading3: false,
                loading4: false,
                loading5: false,
                loading6: false,
                loading7: false,
                loading8: false,
                loading9: false,
                loading10: false,
                loading11: false,
                loading12: false,
                loading13: false,
                loading14: false,
                loading15: false,
                show_staffs: false,
                show_add_rm: false,
                show_list_staffs: true,
                show_list_clients: true,
                show_clients: false,
                show_guest_information: false,
                room_extension_status: false,
                panel_name: "List Members",
                panel_header: "Manage Members",
                room_type: '',
                room_number: '',
                bed_type: '',
                room_capacity: '',
                room_description: '',
                room_amount: '',
                rooms: [],
                staff_id: '',
                vstaff_status: '',
                edit_room_id: '',
                edit_room_type: '',
                edit_room_number: '',
                edit_room_capacity: '',
                edit_bed_type: '',
                edit_room_description: '',
                edit_room_amount: '',
                edit_room_data: false,
                gender: '',
                url: '',
                reservations: [],
                reservation_search: '',
                guest_status: '',
                update_guest_status: false,
                reservation_id: '',
                guest_status_input: '',
                guest_roomid: '',
                guest_phone_number: '',
                guest_info: [],
                edit_quest_id: '',
                user_id: '',
                edit_staffid:'',
                edit_firstname: '',
                edit_middlename: '',
                edit_lastname: '',
                edit_gender: '',
                edit_phone: '',
                edit_email: '',
                edit_address: '',
                edit_guest_data: false,
                checkout_date: '',
                reservation_summary: true,
                occupancy_report: false,
                revenue_report: false,
                roomtype_report: false,
                vguest_status: '',
                date_to: '',
                date_from: '',
                date_to2: '',
                date_from2: '',
                date_to3: '',
                date_from3: '',
                date_to4: '',
                date_from4: '',
                summaryReservation: [],
                response: '',
                occupancyReport: [],
                revenueReports:[],
                roomtypeReports:[],
                bookedStatus: '',
                extension_amount: '',
                extension_date: '',
                extension_reservation_id:'',
                ext_checkout_date: '',
                list_payments: false,
                guestPayments: [],
                payment_date_from: '',
                payment_date_to: '',
                guest_fullname: '',
                new_payment_status: false,
                guest_found: false,
                guest_name: '',
                guest_payments: [],
                payment_description: '',
                guestLists: [],

                staff_firstname: '',
                staff_middlename: '',
                staff_surname: '',
                staff_gender: '',
                staff_branch: '',
                staff_phone_number: '',
                staff_email_address: '',
                staff_address: '',
                password: '',
                cpassword:'',
                referral_id: '',

                account_officer: '',
                client_account_officer: '',
                client_title: '',
                client_firstname: '',
                client_middlename: '',
                client_surname: '',
                client_gender: '',
                client_phone_number: '',
                client_email_address: '',
                client_password: '',
                client_cpassword:'',
                client_account_type: '',
                client_account_number: '',
                client_state: '',
                client_lga: '',
                client_spouse: '',
                client_mother_name: '',
                client_ms: '',
                client_dob: '',
                birth_country: '',
                client_nationality: '',
                client_religion: '',
                client_contact_address: '',
                client_whatsapp_number: '',
                client_nin: '',
                nok_title: '',
                nok_firstname: '',
                nok_middlename: '',
                nok_surname: '',
                nok_gender: '',
                nok_dob:'',
                nok_phone_number: '',
                nok_business:'',
                states: [],
                lgas: [],
                previewImage: '',
                branchid: '',
                branches: [],
                staffList: [],
                staffListing: [],
                edit_user: false,
                membersList: [],
                search_account_number: '',
                selectedFile: '',
                image_url: process.env.VUE_APP_IMAGE_URL,
    
            };
        },
    
        beforeMount() {
            this.fetchAllStates();
            this.fetchAllBranches();
            //this.fetchAllUsers();
        },
    
        mounted() {
            this.app_role = localStorage.getItem("user_role");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");
            this.branchid = localStorage.getItem("branchid");

            this.fetchMyMembers();
    
            this.url = 'edit-member/'
        },
    
        created() {
    
            //this.fetchAllRooms();
            //this.timerInterval = setInterval(this.fetchAllRooms, 5000);
    
        },
        beforeDestroy() {
            // Clear the interval when the component is about to be destroyed
            clearInterval(this.timerInterval);
        },
        computed: {
            filteredMenu() {
                const searchString = this.staff_id.toLowerCase();
                return this.staffList.filter(item => item.staff_id.toLowerCase().includes(searchString));
            },
            filteredMember() {
                const searchString = this.search_account_number.toLowerCase();
                return this.membersList.filter(item => item.account_number.toLowerCase().includes(searchString));
            },   
    
        },
    
        methods: {
           async confirm_payment(membersid){
                try{
                    let message = confirm("You're about to confirm payment. Do you want to continue?")
                    if (message == true) {
                    this.response = await this.$http.post('/update-paymentstatus', {
                        membersid: membersid,
                    });    
                    
                    this.fetchMyMembers()
                }         
              }catch (err) {
                  console.log(err);
              }
            },
            pwdCheck() {
                    var length = this.password.length; // get password length
        
                    if(length>=5) {
                        var char = document.getElementsByClassName("five-char")
                        char[0].style.color = "green";
                        
                        var bi = document.getElementsByClassName("btn-primarys")
                        bi[0].disabled = false;
                    }
                    else {
                        var char7 = document.getElementsByClassName("five-char")
                        char7[0].style.color = "red";
        
                        var bxx = document.getElementsByClassName("btn-primarys")
                        bxx[0].disabled = true;
                    }
                    // check for uppercase
                    if(this.containsUppercase(this.password) == true) {
                        var char2 = document.getElementsByClassName("uppercase")
                        char2[0].style.color = "green";
        
                        var bx = document.getElementsByClassName("btn-primarys")
                        bx[0].disabled = false;
                    }
                    else {
                        var char6 = document.getElementsByClassName("uppercase")
                        char6[0].style.color = "red";
        
                        var r = document.getElementsByClassName("btn-primarys")
                        r[0].disabled = true;
                    }
            
                    // check for numbers
                    if(this.containsNumber(this.password) == true) {
                        var char3 = document.getElementsByClassName("number")
                        char3[0].style.color = "green";
        
                        var v = document.getElementsByClassName("btn-signup")
                        v[0].disabled = false;
                    }
                    else {
                        var char4 = document.getElementsByClassName("number")
                        char4[0].style.color = "red";
        
                        var s = document.getElementsByClassName("btn-primarys")
                        s[0].disabled = true;
                    }
                    
            },
            cpwdCheck() {
            if(this.password == this.cpassword) {
                var char3 = document.getElementsByClassName("cpwd")
                char3[0].style.color = "green";
    
                var cha = document.getElementsByClassName("pwd")
                cha[0].style.color = "green";
    
                var chars = document.getElementsByClassName("password-match")
                chars[0].style.color = "green";
    
            }
            else{
                var c = document.getElementsByClassName("cpwd")
                c[0].style.color = "red";
    
                var ch = document.getElementsByClassName("pwd")
                ch[0].style.color = "red";
    
                var charx = document.getElementsByClassName("password-match")
                charx[0].style.color = "red";
            }
            },
            pwdCheck2() {
                    var length = this.client_password.length; // get password length
        
                    if(length>=5) {
                        var char = document.getElementsByClassName("five-char")
                        char[0].style.color = "green";
                        
                        var bi = document.getElementsByClassName("btn-primarys")
                        bi[0].disabled = false;
                    }
                    else {
                        var char7 = document.getElementsByClassName("five-char")
                        char7[0].style.color = "red";
        
                        var bxx = document.getElementsByClassName("btn-primarys")
                        bxx[0].disabled = true;
                    }
                    // check for uppercase
                    if(this.containsUppercase(this.client_password) == true) {
                        var char2 = document.getElementsByClassName("uppercase")
                        char2[0].style.color = "green";
        
                        var bx = document.getElementsByClassName("btn-primarys")
                        bx[0].disabled = false;
                    }
                    else {
                        var char6 = document.getElementsByClassName("uppercase")
                        char6[0].style.color = "red";
        
                        var r = document.getElementsByClassName("btn-primarys")
                        r[0].disabled = true;
                    }
                    
                    // check for numbers
                    if(this.containsNumber(this.client_password) == true) {
                        var char3 = document.getElementsByClassName("number")
                        char3[0].style.color = "green";
        
                        var v = document.getElementsByClassName("btn-signup")
                        v[0].disabled = false;
                    }
                    else {
                        var char4 = document.getElementsByClassName("number")
                        char4[0].style.color = "red";
        
                        var s = document.getElementsByClassName("btn-primarys")
                        s[0].disabled = true;
                    }
                    
            },
            cpwdCheck2() {
            if(this.client_password == this.client_cpassword) {
                var char3 = document.getElementsByClassName("cpwd")
                char3[0].style.color = "green";
    
                var cha = document.getElementsByClassName("pwd")
                cha[0].style.color = "green";
    
                var chars = document.getElementsByClassName("password-match")
                chars[0].style.color = "green";
    
            }
            else{
                var c = document.getElementsByClassName("cpwd")
                c[0].style.color = "red";
    
                var ch = document.getElementsByClassName("pwd")
                ch[0].style.color = "red";
    
                var charx = document.getElementsByClassName("password-match")
                charx[0].style.color = "red";
            }
            },
            containsUppercase(str) {
                    return /[A-Z]/.test(str);
            },
            containsSpecialChars(str) {
                //eslint-disable-next-line
                const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                return specialChars.test(str);
            },
            containsNumber(str) {
                    return /\d/.test(str);
            },
            async fetchYears() {
    
                try {
                    const response = await this.$http.get('/fetch-years', )
                    this.years = response.data.years;
    
                } catch (err) {
                    console.log(err);
                }
    
            },
        
            tabClients() {
                this.staff_status = false
                this.clients_status = true
                this.show_list_clients = true
                this.show_guest_information = false
                this.housekeeping_status = false
                this.report_status = false
                this.process_payment = false
                this.panel_header = "Members"
                this.panel_name = "List Members"
    
                const tab1 = document.getElementsByClassName("tab-staff");
                tab1[0].style.color = "#000";
    
                const tab2 = document.getElementsByClassName("tab-clients");
                tab2[0].style.color = "#fff";
    
            },
                               
            editItem(itemid, title) {
                try {
                    this.item_id = itemid
                    this.edit_category_name = title
    
                    this.edit_item = true
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            closeMenu() {
                this.edit_user = false
            },
    
            add_staffs() {
                this.show_staffs = true
                this.show_list_staffs = false
                this.panel_name = "Add Staff"
            },

            add_clients() {
                this.show_clients = true
                this.show_list_clients = false
                this.panel_name = "Add Members"
            },
        
            list_staffs() {
                this.show_list_staffs = true
                this.show_staffs = false
                this.panel_name = "List Staff"
    
            },
            
            list_clients() {
                this.show_list_clients = true
                this.show_clients = false
                this.panel_name = "List Members"
    
            },
              
            closeMenu2() {
                this.edit_room_data = false
            },
    
            closeMenu3() {
                this.update_guest_status = false
            },
    
            closeMenu4() {
                this.edit_guest_data = false
            },  
    
            closeMenu5(){
                this.room_extension_status = false
            },
    
             // calculate date diff
             calculateDaysDifference(date1, date2) {
                const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
    
                // Parse the input dates
                const startDate = new Date(date1);
                const endDate = new Date(date2);
    
                // Calculate the difference in days
                const diffInDays = Math.round(Math.abs((startDate - endDate) / oneDay));
    
                return diffInDays;
            },
        
            closeMenu6(){
                this.new_payment_status = false
                this.guest_found = false
                this.guest_payments = ''
                this.guest_name = ''
            },
    
            async searchGuest(){
                try {
    
                    this.response = await this.$http.post('/search-guest-payment', {
                        guest_name: this.guest_name,
                    });
    
                    this.guest_payments = this.response.data.paymentList;
    
                if (this.guest_payments =='') {
                    swal.fire({
                        icon: 'error',
                        width: 450,
                        height: 5,
                        title: "Not found",
                        text: "Record not found",
                    });
                    this.guest_found = false
                    return;
                } 
                else{
                    this.guest_found = true
                }
    
                } catch (err) {
                console.log(err);
                }
            },
    
            async createStaff(){
                try {
                                           
                    if (!this.staff_firstname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter firstname',
                        })
                        return;
                    }                
                    if (!this.staff_surname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter surname',
                        })
                        return;
                    }
                    if (!this.staff_gender) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select gender',
                        })
                        return;
                    }
                    if (!this.staff_branch) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select branch',
                        })
                        return;
                    }
                    if (!this.staff_phone_number) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter phone',
                        })
                        return;
                    }
                    if (!this.password) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter password',
                        })
                        return;
                    }
                    if (!this.cpassword) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter confirm password',
                        })
                        return;
                    }
    
                    this.loading15 = true;
    
                    this.response = await this.$http.post('/create-staff', {
                        firstname: this.staff_firstname,
                        middlename: this.staff_middlename,
                        surname: this.staff_surname,
                        gender: this.staff_gender,
                        staff_branch: this.staff_branch,
                        phone: this.staff_phone_number,
                        email: this.staff_email_address,
                        address: this.staff_address,
                        password: this.password,
                        cpassword: this.cpassword,
                        userid:  this.userid,
                    });
    
                    this.loading15 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                    } else {
    
                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: this.response.data.message,
                        });
                    }
                    this.staff_firstname = "",
                    this.staff_middlename = ""
                    this.staff_surname = ""
                    this.staff_gender = ""
                    this.staff_phone_number = ""
                    this.staff_email_address = ""
                    this.staff_address = ""
                    this.password = ""
                    this.cpassword = ""
                    this.staff_branch = ""
                   // this.fetchAllUsers();
    
                } catch (err) {
                    console.log(err);
                }
            },

            async submit_form(){
                try {
                    if (this.app_role == 1) {
                        if (!this.client_account_officer) {
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width: 450,
                                height: 5,
                                text: 'Please select account officer',
                            })
                            return;
                        }  
                    }
                    
                    if (!this.client_firstname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter firstname',
                        })
                        return;
                    }                
                    if (!this.client_surname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter surname',
                        })
                        return;
                    }
                    if (!this.client_gender) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select gender',
                        })
                        return;
                    }
                    if (!this.client_phone_number) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter phone',
                        })
                        return;
                    }
                    if (!this.client_email_address) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter email address',
                        })
                        return;
                    }
                    if (!this.client_ms) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select marital status',
                        })
                        return;
                    }

                    else {
                        if (this.client_ms == 1) {
                            if (!this.client_spouse) {
                                swal.fire({
                                    icon: 'warning',
                                    title: 'Error...',
                                    width: 450,
                                    height: 5,
                                    text: 'Please enter spouse name',
                                })
                                return;
                            }
                        }
                    }
                    if (!this.client_mother_name) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: "Please enter mother maiden's name",
                        })
                        return;
                    }
                    if (!this.client_religion) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select religion',
                        })
                        return;
                    }
                    if (!this.client_state) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select state',
                        })
                        return;
                    }
                    if (!this.client_lga) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select lga',
                        })
                        return;
                    }
                    if (!this.birth_country) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select birth country',
                        })
                        return;
                    }
                    if (!this.client_nationality) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select nationality',
                        })
                        return;
                    }
                    if (!this.client_contact_address) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter contact address',
                        })
                        return;
                    }
                    if (!this.nok_title) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select next of kin title',
                        })
                        return;
                    }
                    if (!this.nok_firstname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin firstname',
                        })
                        return;
                    }
                    if (!this.nok_surname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin surname',
                        })
                        return;
                    }
                    if (!this.nok_gender) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select next of kin gender',
                        })
                        return;
                    }
                    if (!this.nok_dob) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select next of kin date of birth',
                        })
                        return;
                    }
                    if (!this.nok_phone_number) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin phone',
                        })
                        return;
                    }
                    if (!this.nok_business) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin nature of business',
                        })
                        return;
                    }
    
                    this.loading2 = true;
                    
                    if (this.app_role == 1) {
                        this.account_officer = this.client_account_officer
                    }else if(this.app_role == 2){
                        this.account_officer = this.userid
                    }

                    const formData = new FormData();
                    formData.append('image', this.selectedFile),
                    formData.append('account_officer', this.account_officer),
                    formData.append('title', this.client_title),
                    formData.append('firstname', this.client_firstname),
                    formData.append('middlename', this.client_middlename),
                    formData.append('surname', this.client_surname),
                    formData.append('gender', this.client_gender),
                    formData.append('dob', this.client_dob),
                    formData.append('phone', this.client_phone_number),
                    formData.append('email', this.client_email_address),
                    formData.append('marital_status', this.client_ms),
                    formData.append('spouse_name', this.client_spouse),
                    formData.append('maiden_name', this.client_mother_name),
                    formData.append('religion', this.client_religion),
                    formData.append('state', this.client_state),
                    formData.append('lga', this.client_lga),
                    formData.append('birth_country', this.birth_country),
                    formData.append('nationality', this.client_nationality),
                    formData.append('contact_address', this.client_contact_address),
                    formData.append('whatsapp_number', this.client_whatsapp_number),
                    formData.append('id_means', this.client_nin),
                    formData.append('referral_id', this.referral_id),
                    formData.append('nok_title', this.nok_title),
                    formData.append('nok_firstname', this.nok_firstname),
                    formData.append('nok_middlename', this.nok_middlename),
                    formData.append('nok_surname', this.nok_surname),
                    formData.append('nok_gender', this.nok_gender),
                    formData.append('nok_dob', this.nok_dob),
                    formData.append('nok_phone', this.nok_phone_number),
                    formData.append('nok_business_nature', this.nok_business),
                    formData.append('userid',  this.userid),
                    formData.append('branchid', this.branchid)

                    this.response = await this.$http.post('/register-member', formData
    
                    );
    
                    this.loading2 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                    } else {
    
                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: this.response.data.message,
                        });
                    }
                        
                        this.client_title = "",
                        this.client_firstname  = "",
                        this.client_middlename  = "",
                        this.client_surname  = "",
                        this.client_gender  = "",
                        this.client_dob  = "",
                        this.client_phone_number  = "",
                        this.client_email_address  = "",
                        this.client_ms  = "",
                        this.client_spouse  = "",
                        this.client_mother_name  = "",
                        this.client_religion  = "",
                        this.client_state  = "",
                        this.client_lga  = "",
                        this.birth_country  = "",
                        this.client_nationality  = "",
                        this.client_contact_address  = "",
                        this.client_whatsapp_number  = "",
                        this.client_nin  = "",
                        this.nok_title  = "",
                        this.nok_firstname  = "",
                        this.nok_middlename  = "",
                        this.nok_surname  = "",
                        this.nok_gender  = "",
                        this.nok_dob  = "",
                        this.nok_phone_number  = "",
                        this.nok_business  = "",
                        this.previewImage  = ""
                        this.fetchMyMembers()  
    
                } catch (err) {
                    console.log(err);
                }
            },

            getRandomAlphanumeric(length) {
                const alphanumeric = '0123456789';
                let result = '';
                for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(Math.random() * alphanumeric.length);
                    result += alphanumeric[randomIndex];
                }
                return result;
            },

            async fetchAllStates() {
                try {
                    this.isLoading = true;
                    const response = await this.$http.get('/all-states', )
                    this.isLoading = false
                    this.states = response.data.states;
    
                } catch (err) {
                    console.log(err);
                }
            },

            async getStateLga(){
                try {
                                                   
                    this.response = await this.$http.post('/get-state-lga', {
                        state_id: this.client_state,
                    });
        
                    if (this.response.data.lgas != "") {
                        this.lgas = this.response.data.lgas
    
                    } else {
    
                    swal.fire({
                        icon: 'warning',
                        width:450,
                        height:5,
                        title: "Not Found",
                        text: "Record not found",
                        });

                        this.client_lga = ""
                    }
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllBranches() {
                try {
                    this.isLoading = true;
                    const response = await this.$http.get('/fetch-branches', )
                    this.isLoading = false
                    this.branches = response.data.branches;
    
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllUsers() {
                try {
                    const response = await this.$http.get('/all-staff', )
                    this.staffList = response.data.staff;

                    this.staffList.forEach(element => {
                    this.staffListing.push({
                        value: element.id,
                        text: element.firstname + ' ' + element.lastname +'('+ element.staff_id + ')'
                    })
                });
    
                } catch (err) {
                    console.log(err);
                }
            },
            pickFile() {
                this.selectedFile = this.$refs.fileInput.files[0];
                let input = this.$refs.fileInput;
                let file = input.files;
                
                if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImage = e.target.result;
                };
                reader.readAsDataURL(file[0]);
                this.$emit("input", file[0]);
                }
            },

            handleFileChange() {
                this.selectedFile = this.$refs.fileInput.files[0];
            },

            async deleteUser(uid) {

            let msg = confirm("               You are about to delete this user. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/delete-user', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
            this.fetchAllUsers()


            } catch (err) {
                console.log(err);
            }
            }
            },

            async disableUser(uid) {

            let msg = confirm("You are about to disable this user. This user cannot have access to the system if disabled. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/disable-user', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
            this.fetchAllUsers()


            } catch (err) {
                console.log(err);
            }
            }
            },

            async enableUser(uid) {

            let msg = confirm("You are about to activate this user. This user can now have access to the system if activated. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/activate-user', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
            this.fetchAllUsers()


            } catch (err) {
                console.log(err);
            }
            }
            },
            editUser(uid, staffid, firstname, middlename, lastname, gender, address, phone, email){
                try{
                    this.user_id = uid
                    this.edit_staffid = staffid,
                    this.edit_firstname = firstname,
                    this.edit_middlename = middlename,
                    this.edit_lastname = lastname,
                    this.edit_gender = gender,
                    this.edit_address = address,
                    this.edit_phone = phone,
                    this.edit_email = email,

                    this.edit_user = true

                } catch (err) {
                console.log(err);
                }
            },

            async updateUser(){
                try{
        
                        this.loading = true;

                        this.response = await this.$http.post('/update-user', {
                            userid: this.user_id,
                            staffid: this.edit_staffid,
                            firstname: this.edit_firstname,
                            middlename: this.edit_middlename,
                            lastname: this.edit_lastname,
                            gender: this.edit_gender,
                            address: this.edit_address,
                            phone: this.edit_phone,
                            email: this.edit_email,
                        });

                            this.loading = false;

                            if (!this.response.data.success) {

                                swal.fire({
                                        icon: 'error',
                                        width:450,
                                        height:5,
                                        title: "Error",
                                        text: this.response.data.message,
                                    });
                                    return;

                            } else {

                            swal.fire({
                                icon: 'success',
                                width:450,
                                height:5,
                                title: "Success",
                                text: this.response.data.message,
                            });

                            this.edit_user = false
                            this.edit_staffid = '',
                            this.edit_firstname = '',
                            this.edit_lastname = '',
                            this.edit_gender = '',
                            this.edit_address = '',
                            this.edit_phone = '',
                            this.edit_email = '',

                            this.fetchAllUsers()
                                                        
                            return;

                            }

                    } catch (err) {
                    console.log(err);
                    }
            },

            async fetchMyMembers() {
                try {
                    this.isLoading = true;
                    const response = await this.$http.post('/my-members', {
                        userid: this.userid
                    })
                    this.isLoading = false
                    this.membersList = response.data.members;
    
                } catch (err) {
                    console.log(err);
                }
            },
            async delete_member(uid) {

                let msg = confirm("               You are about to delete this member. Are you sure?");

                if(msg == true) {
                try {
                    this.loading = true;

                    this.response = await this.$http.post('/delete-member', {
                        userid: uid,
                    });

                    this.loading = false;

                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;

                    } else {

                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: this.response.data.message,
                    });
                }
                this.fetchMyMembers()


                } catch (err) {
                    console.log(err);
                }
                }
            },

            async disable_member(uid) {

            let msg = confirm("You are about to disable this member. This user cannot have access to the system if disabled. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/disable-member', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
        }
            this.fetchMyMembers()
                } catch (err) {
                    console.log(err);
                }
                }
            },

            async enable_member(uid) {

            let msg = confirm("You are about to activate this member. This user can now have access to the system if activated. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/activate-member', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
            this.fetchMyMembers()


            } catch (err) {
                console.log(err);
            }
            }
            },
        
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
.password-check{
        margin-left:50px;
        font-size: 10px;
        font-family: Mulish;
        .five-char {
            color: red
        }
        .uppercase{
            color: red
        }
        .number{
            color: red
        }
        .special-char{
            color: red
        }
        .password-match{
            color: red
        }
}
.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #2b0c17;
        background: #FFF;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    .welcome {
        margin-top: 8px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 23px;
        justify-content: center;
        color: #FFFFFF;

    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

    .counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .order-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        height: 250px;
        overflow-y: auto;
       
    }
    .edit-left{
        width:100%;
        .space-top{
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }  
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }

    }

    .edit-right{
        width:100%;
        margin-left: 4px;
        .space-top{
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }  
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }    

    }

    .edit-button {
        margin-bottom: 15px;
    }
   
}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask4 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .background {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .bg-content{
        padding: 10px;
        background:#ccc;
        padding-right: 20px;
        width: 100%;
        border-radius: 10px;
        height: auto;
    }
    
    
    .menu-bar {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        box-sizing: border-box;
        height: 50px;
        background: #000;
        padding: 10px;
        border-radius: 4px;
    }

    .tab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 4px;
        margin-top: 15px;
    }

    .tab-staff {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        font-size: 10px;
        box-sizing: border-box;
        height: 20px;
        background: #FFA500;
        padding: 10px;
        border-right: solid 2px #fff;
        border-bottom: solid 1px #fff;
        color: #fff;
        cursor: pointer;
    }

    .tab-staff:hover {
        font-weight: bold;
        color: #000;
        border-bottom: solid 1px #fff;
    }

    .tab-clients {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        font-size: 10px;
        box-sizing: border-box;
        height: 20px;
        background: #FFA500;
        padding: 10px;
        color: #000;
        cursor: pointer;
    }
    .tab-clients:hover {
        font-weight: bold;
        color: #000;
        border-bottom: solid 1px #fff;
    }
    
        .tab-reports {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            //border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-reports:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .users-container {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .users-container2 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .users-container3 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .search-input {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            font-size: 10px !important;
        }
    
        .search-name {
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-align: left;
            color: #000;
            margin-bottom: -2px;
        }
    
        .orderid {
            width: 10%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .searchdate {
            width: 13%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .btn-search1 {
            width: 8%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            background: #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
            color: #fff;
        }
    
        .rm-menu {
            margin: 4px;
        }
    
        .rm-category:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .rm-category3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .add-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .users-content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
            font-size: 12px;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu2 {
            margin: 4px;
        }
    
        .add-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .users-content2 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .users-content3 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu3 {
            margin: 4px;
        }
    
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .guest-information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .guests {
                font-size: 10px;
                font-weight: 400 !important;
                cursor: pointer;
            }
    
            .guests:hover {
                text-decoration: underline;
            }
        }
    
        .add-user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 10px;
            font-weight: 700;
            font-family: Mulish;

            .staff-form {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
       
        .members-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
        }

        .btn-input{
            height: 30px;
            width: auto;
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            margin: 2px;
        }

        .btn-group{
            display:flex; 
            flex-direction: row; 
            justify-content: space-between;
        }

        .img-profile {
            width: 120px;
            height: 120px;
            background: url("../../assets/photo_add.png");
            background-size: cover;
            padding-bottom: 0rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            border-radius: 10px;
        }
        .form-input{
            font-weight: 500;
            font-size: 12px;
            height: 30px;  
        }
        
        .form-select{
            font-weight: 500;
            font-size: 12px;
            height: 30px;  
        }
        .form-button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
        }
    
        .add-user2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    
        .add-room {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-room2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-rm-center {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .add-rm-center2 {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .list-category {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            //height: 800px;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .list-category2 {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .input {
            border: solid 0px #000;
            border-radius: 4px;
            width: 100%;
            height: 30px;
            font-size: 12px;
        }
    
        .btn-primarys {
            background: #FFA500;
            font-size: 12px;
            height: 30px;
            width: 100%;
    
        }
    
    img {
        width: 50px;
        border-radius: 40px;
    }
    
    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        text-align: left;
        color: #fff;
    }
    
    
    
    @media screen and (max-width: 768px) {
    
        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: 25%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    }
    
    @media screen and (max-width: 768px) {

        .bg-content{
            padding: 10px;
            background:#ccc;
            padding-right: 20px;
            width: 100%;
            border-radius: 10px;
            height: auto;
        }
    
        .background {
            background: #fbd43a;
            width: 100%;
            height: 100vh;
    
            .container {
                background: #fbd43a;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 30px;
    
                .card {
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
    
                    .menu-bar {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        height: 50px;
                        background: #000;
                        padding: 10px;
                        border-radius: 4px;
                    }
    
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        padding: 4px;
                        border-radius: 4px;
                        margin-top: 15px;
                    }
    
                    .tab-staff {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 0px;
                        border-right: solid 2px #fff;
                        border-bottom: solid 1px #fff;
                        color: #fff;
                        cursor: pointer;
                    }
    
                    .tab-clients {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .tab-housekeepings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .users-container {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .users-container2 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .users-container3 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .search-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 10px !important;
                    }
                    .searchdate {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                    }
        
                    .btn-search1 {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        background: #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                        color: #fff;
                    }
    
                    .rm-menu {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .rm-category:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .add-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .users-content {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .rm-menu2 {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .add-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .users-content2 {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .add-room {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-room2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-rm-center {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-rm-center2 {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-user {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: 10px;
                        font-weight: 700;
                        font-family: Mulish;
            
                        .staff-form {
                            display: flex;
                            flex-direction: column !important;
                            width: 100%;
                        }
                    }
    
                    .add-user2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .input {
                        width: 100%;
                        height: 30px;
                    }
    
                    .btn-primarys {
                        margin-top: -10px;
                        width: 100%;
                        height: 30px;
                    }
    
                    img {
                        width: 50px;
                    }
                }
    
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: left;
                    color: #fff;
                }
    
            }
        }
    }

    </style>
    