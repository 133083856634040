import { render, staticRenderFns } from "./ReceiptsPage.vue?vue&type=template&id=0b16f4fd&scoped=true&"
import script from "./ReceiptsPage.vue?vue&type=script&lang=js&"
export * from "./ReceiptsPage.vue?vue&type=script&lang=js&"
import style0 from "./ReceiptsPage.vue?vue&type=style&index=0&id=0b16f4fd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b16f4fd",
  null
  
)

export default component.exports