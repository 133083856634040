<template>
    <div v-if="isLoading" class="page-loader">
      <div class="loader"></div>
      <img src="../assets/logo.png" alt="BMCS" class="loader-logo" />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isLoading: Boolean, // Pass a Boolean prop to control when to show/hide the loader
    },
  };
  </script>
  
  <style scoped>
  /* Style the page loader container */
  .page-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Style the loader animation (you can use any loader animation library) */
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #1d7a2b;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  /* Style the loader logo */
.loader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  color: #1d7a2b;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  