<template>
    <div class="background">
            <div class="header navbar navbar-default">
                <span class="logo">
                   <router-link to="/home"> <img src="../../assets/arrow-back.png" alt="" width="30px"> </router-link>
                </span>
                <div class="contact">
                    <router-link to="/list-staff"> <img src="../../assets/fa-user.png" alt="" width="20px">  List Users </router-link>
                </div>
            </div>
            <div class="container">
                <div class="card">
                    <p>&nbsp;</p>
                   <center><img src="../../assets/add_userx-removebg-preview.png" alt=""></center> 
                </div>
                <div class="card">
                    <h2>
                        Add Users
                    </h2>
                  
                    <span>
                        <div class="row">
                            <label for="">Role</label>
                            <select class="form-control input-text" id="role" v-model="user_role" required>
                                <option value="">-Select-</option>
                                <option value="1">Waiter</option>
                                <option value="2">Admin</option>
                            </select>
                        </div>
                        <div class="row">
                            <label> Firstname</label>
                            <input type="text" class="form-control input-text" v-model="firstname" placeholder="Your first name" required>
                        </div>
                        <div class="row">
                            <label for=""> Lastname</label>
                            <input type="text" class="form-control input-text" v-model="lastname" placeholder="Your last name" required>
                        </div>
                       
                        <div class="row">
                            <label for=""> Email</label>
                            <input type="email" class="form-control input-text" v-model="email" placeholder="Your email" required>
                        </div>
                        <div class="row">
                            <label for=""> Phone</label>
                            <input type="text" class="form-control input-text" v-model="phone" placeholder="Your phone number" required>
                        </div>
                        <div class="row">
                            <label for="">Gender</label>
                            <select class="form-control input-text" id="gender" v-model="gender" required>
                                <option value="">-Select-</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div class="row">
                            <label for=""> Address</label>
                            <textarea class="form-control input-text" v-model="address" placeholder="Your address" required cols="3" rows="2"> </textarea>
                        </div>
    
                        <div class="row">
                            <label for=""> Password</label>
                            <input type="password" class="form-control input-text pwd" v-model="password" minlength="5" maxlength="12" placeholder="Your password" required  @input="pwdCheck()">
                        </div>
                        <div class="row">
                            <label for=""> Confirm Password</label>
                            <input type="password" class="form-control input-text cpwd" v-model="cpassword" placeholder="Confirm password" required  @input="cpwdCheck()">
                        </div>
                        <div class="row">
                            <span class="password-check">
                                <span class="five-char">Password should be at least 5 characters</span> <br>
                                <span class="uppercase">Password should contain upper case(e.g ABC)</span><br>
                                <span class="number">Password should contain number (e.g 1234567890)</span><br>
                                <span class="password-match"> Password and Confirm Password must match</span><br>
                             </span>
                        </div>
                      
                        <div class="row">
                            <button class="btn-next" @click="submit()" v-if="!loading"><i class="fa fa-plus"></i> Submit </button>
                            <button class="btn-next" type="button" disabled v-if="loading">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Submitting...
                            </button>
                        </div>
                    </span>
                </div>
                <div class="card">
    
                </div>
            </div>
        
    </div>
    </template>
    <script>
    //import $ from 'jquery';
    //import Vue from 'vue'
    import swal from 'sweetalert2';
   // import VueCountryDropdown from "vue-country-dropdown";
    
   // Vue.use(VueCountryDropdown);
    
    export default {
       // components: { VueCountryDropdown },
    data() {
      return {
        personal_information: true,
        firstname: '',
        middlename: '',
        lastname: '',
        username:'',
        email: '',
        phone: '',
        address: '',
        user_role:'',
        day: '',
        month: '',
        year: '',
        gender: '',
        location: '',
        aboutyou: '',
        occupation: '',
        religion: '',
        sexuality: '',
        relationship_goal: '',
        loading: false,
        loading1: false,
        response: '',
        record_id: '',
        options: [],
       
        success_message: '',
        is_referred: false,
        referral_code:'',
        referral_option: '',
        password: '',
        cpassword:'',
        cfirstname: '',
        clastname: '',
        cusername: '',
        userid:'',
    
      };
    },
    
    beforeMount(){

    },
    
    mounted(){

        this.app_role = localStorage.getItem("app_status");
        this.cfirstname = localStorage.getItem("firstname");
        this.clastname = localStorage.getItem("lastname");
        this.cusername = localStorage.getItem("username");
        this.userid = localStorage.getItem("userid");
    },
    
    methods: {           
    
        pwdCheck() {
                var length = this.password.length; // get password length
    
                if(length>=5) {
                    var char = document.getElementsByClassName("five-char")
                    char[0].style.color = "green";
                    
                    var bi = document.getElementsByClassName("btn-next")
                    bi[0].disabled = false;
                }
                else {
                    var char7 = document.getElementsByClassName("five-char")
                    char7[0].style.color = "red";
    
                    var bxx = document.getElementsByClassName("btn-next")
                    bxx[0].disabled = true;
                }
                // check for uppercase
                if(this.containsUppercase(this.password) == true) {
                    var char2 = document.getElementsByClassName("uppercase")
                    char2[0].style.color = "green";
    
                    var bx = document.getElementsByClassName("btn-next")
                    bx[0].disabled = false;
                }
                else {
                    var char6 = document.getElementsByClassName("uppercase")
                    char6[0].style.color = "red";
    
                    var r = document.getElementsByClassName("btn-next")
                    r[0].disabled = true;
                }
                // check for special char
                // if(this.containsSpecialChars(this.password) == true) {
                //     var char1 = document.getElementsByClassName("special-char")
                //     char1[0].style.color = "green";
    
                //     var x = document.getElementsByClassName("btn-next")
                //     x[0].disabled = false;
                // }
                // else {
                //     var char5 = document.getElementsByClassName("special-char")
                //     char5[0].style.color = "red";
    
                //     var y = document.getElementsByClassName("btn-next")
                //     y[0].disabled = true;
                // }
                // check for numbers
                if(this.containsNumber(this.password) == true) {
                    var char3 = document.getElementsByClassName("number")
                    char3[0].style.color = "green";
    
                    var v = document.getElementsByClassName("btn-signup")
                    v[0].disabled = false;
                }
                else {
                    var char4 = document.getElementsByClassName("number")
                    char4[0].style.color = "red";
    
                    var s = document.getElementsByClassName("btn-next")
                    s[0].disabled = true;
                }
                
        },
        cpwdCheck() {
            if(this.password == this.cpassword) {
                var char3 = document.getElementsByClassName("cpwd")
                char3[0].style.color = "green";
    
                var cha = document.getElementsByClassName("pwd")
                cha[0].style.color = "green";
    
                var chars = document.getElementsByClassName("password-match")
                chars[0].style.color = "green";
    
            }
            else{
                var c = document.getElementsByClassName("cpwd")
                c[0].style.color = "red";
    
                var ch = document.getElementsByClassName("pwd")
                ch[0].style.color = "red";
    
                var charx = document.getElementsByClassName("password-match")
                charx[0].style.color = "red";
            }
        },
        containsUppercase(str) {
                return /[A-Z]/.test(str);
        },
        containsSpecialChars(str) {
            //eslint-disable-next-line
            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            return specialChars.test(str);
        },
        containsNumber(str) {
                return /\d/.test(str);
        },
    
        async submit() {
                try{

                    if(!this.user_role){
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width:450,
                            height:5,
                            text: 'Please select staff role ',
                        })
                        return;
                    }
    
                    if (!this.firstname ){
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width:450,
                            height:5,
                            text: 'Please enter firstname ',
                        })
                        return;
                    }
                    if (!this.lastname ){
                    
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width:450,
                            height:5,
                            text: 'Please enter lastname ',
                        })
                        return;
                    }
                   
                    if(!this.phone){
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width:450,
                            height:5,
                            text: 'Please enter phone number',
                        })
                        return;
                    }
                    if (!this.gender) {
                        
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width:450,
                            height:5,
                            text: 'Please indicate your gender ',
                        })
                        return;
                    }
                    if (!this.address) {
                        
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width:450,
                            height:5,
                            text: 'Address cannot be empty',
                        })
                        return;
                    }    
                    if (!this.password ){
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width:450,
                                height:5,
                                text: 'Please enter password',
                            })
                            return;
                        }
                        if (!this.cpassword ){
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width:450,
                                height:5,
                                text: 'Please enter confirm password',
                            })
                            return;
                        }
                        if (this.password != this.cpassword){
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width:450,
                                height:5,
                                text: 'Confirm password does not match',
                            })
                            return;
                        }
               
                        this.loading = true;
    
                        this.response = await this.$http.post('/add-user', {
                            user_role: this.user_role,
                            firstname: this.firstname,
                            lastname: this.lastname,
                            phone: this.phone,
                            email: this.email,
                            gender: this.gender,
                            address: this.address,
                            created_by: this.userid,
                            password: this.password,
                            confirm_password: this.cpassword
                        });
    
                            this.loading = false;
    
                            if (!this.response.data.success) {
    
                                swal.fire({
                                        icon: 'error',
                                        width:450,
                                        height:5,
                                        title: "Error",
                                        text: this.response.data.message,
                                    });
                                    return;
    
                            } else {
    
                            swal.fire({
                                icon: 'success',
                                width:450,
                                height:5,
                                title: "Success",
                                text: this.response.data.message,
                            });
    
                            this.firstname = "";
                            this.lastname = "";
                            this.email = "";
                            this.phone = ""; 
                            this.gender = ""; 
                            this.location = ""; 
                            this.password = ""; 
                            this.cpassword = "";
                            this.address = "";
                            this.user_role = "";
                            this.gender = "";
                         
                            return;
    
                            }
                            
                    
                } catch (err) {
                console.log(err);
            }
        },
       
        async getStates() {
         try{
            const response = await this.$http.get('/all-states', )
            this.options = response.data.states;
    
            // console.log(this.options)
    
                        
            }catch (err) {
                console.log(err);
            }
        },
       
      }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    a {
        text-decoration: none;
    }

    
    .password-check{
            margin-top:10px;
            width: 100%!important;
            font-size: 10px;
            font-family: Mulish;
            .five-char {
                color: red
            }
            .uppercase{
                color: red
            }
            .number{
                color: red
            }
            .special-char{
                color: red
            }
            .password-match{
                color: red
            }
    }
    .modal-window {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        &:target {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
        & > div {
          width: 680px;
          height: 400px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2em;
          position: absolute;
          width: 372px;
          height: 420px;
          background: #19181B;
          border-radius: 50px;
        }
        header {
          font-weight: bold;
          font-family: Mulish;
        }
        h1 {
          font-size: 150%;
          margin: 0 0 15px;
          font-family: Mulish;
        }
        h2 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }
        .welcome {
            margin-top: 15px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }
        label {
            text-align: left;
            color: #fff;
            font-size: 12px;
            margin-left:15px;
            margin-bottom: -6px;
            font-family: Mulish;
        }
        span {
            margin-bottom: 20px;
        }
    
      }
      
      .modal-close {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        font-family: Mulish;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
        &:hover {
          color: black;
        }
      }
      .modal-close-mobile {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        font-family: Mulish;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
        &:hover {
          color: black;
        }
        display: none;
      }
      .modal-window {
        & > div {
            border-radius: 63px;
        }
      }
      .modal-logo {
        text-align: center;
      }
      .modal-logo > img {
        width: 100px;
        height: 100;
    }
    h2 {
       
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        line-height: 190%;
        margin-top: 20px;
    
    }
    .modal-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 19px;
        width: 380px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 50px;
        margin: 3%;
        cursor: pointer;
    }
    .login-modal-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 311px;
        width: 496px;
        height: 45px;
        background: #0A070F;
        border: 0.5px solid #FFFFFF;
        border-radius: 50px;
        margin: 10px;
        color: #767475;
    }
    .btn-proceed {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 250px;
        height: 40px;
        background: #D33E74;
        border-radius: 82px;
        color: #FFFFFF;
        margin-left: -10px;
    }
    .background{
        background: #ccc;
        width: 100%;
       
        .container{
            background: #ccc;
            display:flex;
            flex-direction: row;
            justify-content:space-evenly;
            width: 100%;
                
            .card{
                margin: 6px;
                width: 100%;
                height: auto;
                padding: 4px;
                background: #ccc;
                font-size: 12px;
                font-family: Mulish;
                border: none;
                color: #000;

                img{
                    width: 60%;
                    margin-top: 5px;
                }
            }
            
    
            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 30px;
                line-height: 42px;
                text-align: left;
                color: #000;
            }
            p {
                font-family: Mulish;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                color: #000;
            }     

            span {
                .row{
                    label {
                        color: #000;
                        text-align: left;
                        font-family: Mulish;
                    }
                }

               
            }
    
            .form-group {
                margin: 4px;
            }
            .input-text {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                width: 100%;
                height: auto;
                background: #FFF;
                border: 0.5px solid #FFFFFF;
                border-radius: 4px;
                color: #000;
                font-size:11px;   
                font-family: Mulish; 
            }
    
            .birthday {
                margin-left: -20px;
            }
    
            input:focus {
                border: 0.5px solid #D33E74;
            }
            select:focus {
                border: 0.5px solid #D33E74;
            }
            textarea:focus {
                border: 0.5px solid #D33E74;
            }
            option:focus {
                border: 0.5px solid #D33E74;
            }
            .input-select {
                box-sizing: border-box;
                align-items: center;
                padding: 10px 40px;
                width: 110%;
                height: auto;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                //margin: 2px;
                color: #767475;
                font-size:11px;
            }
            label {
                text-align: left;
                color: #FFFFFF;
                margin-left:4px;
                margin-top: 10px;
            }
    
           
            .gender {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: auto;
                background: #fbd43a;
                //border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                color: #000;
                font-size:11px;
                font-family: Mulish;
            }
        
            .location {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                gap: 202px;
                width: 100%;
                height: auto;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                color: #767475;
                font-size:11px;
                font-family: Mulish;
            }
            .btn-next {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 100%;
                height: 50px;
                background: #000;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color:#FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
            .btn-done {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 435px;
                height: 50px;
                background: #D33E74;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color:#FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            background: #000;
            width: 100%;
            border-radius: 4px;
            font-family: Mulish;
    
            .logo > img{
                width: 20px;
            }
            .contact{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: auto;
                font-weight: normal;
                font-size: 10px;
                color: #fff !important;
                line-height: 0px;
                font-family: Mulish;
            }
         }
         .btn-chips {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 40px;
            gap: 19px;
            width: 117.61px;
            height: 35px;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 50px;
            margin:6px;
            color:#767475;
            font-family: Mulish;
         }
         .btn-chips:hover {
            border: 0.5px solid #D33E74;
         }
         .rel-goal{
            color:#D33E74;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
        }
    
    }
    @media screen and (max-width: 768px) {
        .modal-window {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s;
            &:target {
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            }
            & > div {
              width: 680px;
              height: 490px;
              position: absolute;
              top: 50%;
              left: 53%;
              transform: translate(-50%, -50%);
              padding: 2em;
              position: absolute;
              width: 300px;
              height: 400px;
              background: #19181B;
              border-radius: 50px;
            }
            header {
              font-weight: bold;
              font-family: Mulish;
              
            }
            h1 {
              font-size: 150%;
              margin: 0 0 15px;
              font-family: Mulish;
            }
            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                color: #FFFFFF;
            }
            .welcome {
                margin-top: 15px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                color: #FFFFFF;
            }
            label {
                text-align: left;
                color: #fff;
                font-size: 12px;
                margin-left:15px;
                margin-bottom: -6px;
                font-family: Mulish;
            }
            span {
                margin-bottom: 20px;
            }
        
          }
          
          .modal-close {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
          }
          .modal-close-mobile {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
            display: none;
          }
          .modal-window {
            & > div {
                border-radius: 63px;
            }
          }
          .modal-logo > img {
            margin-top: -30px;
            width: 120px;
            height: 120;
        }
        h2 {
           
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            line-height: 190%;
            margin-top: 20px;
        
        }
        .modal-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 19px;
            width: 380px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 50px;
            margin: 3%;
            cursor: pointer;
        }
        .login-modal-box {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 311px;
            width: 496px;
            height: 45px;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 50px;
            margin: 10px;
            color: #767475;
        }
        .btn-proceed {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 235px;
            height: 35px;
            background: #D33E74;
            border-radius: 82px;
            color: #FFFFFF;
            margin-left: -10px;
            font-size: 13px;
            font-family: Mulish;
        }
    }
    
    @media screen and (max-width: 768px) {
    
        .background{
            background: #fbd43a;
            width: 100%;
            height: auto;;
        
            .container{
                background: #fbd43a;
                width: 100%;
                height: 100%;
                display:flex;
                flex-direction: column;
                justify-content:space-evenly;
                padding: 30px;

                .card{
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;

                    img{
                        margin-top: -50px;
                        width: 50%;
                    }
                }
        
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 42px;
                    text-align: left;
                    color: #000;
                }
                p {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12.5px;
                    line-height: 23px;
                    text-align: center;
                    color: #000;
                }     
        
            
                .form-group {
                    margin: 4px;
                }
                .input-text {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 30px;
                    width: 100%;
                    height: 40px;
                    background: #FFF;
                    border: 0.5px solid #fff;
                    border-radius: 10px;
                    font-family: Mulish;
                }
                input:focus {
                    border: 0.5px solid #D33E74;
                }
                select:focus {
                    border: 0.5px solid #D33E74;
                }
                textarea:focus {
                    border: 0.5px solid #D33E74;
                }
                option:focus {
                    border: 0.5px solid #D33E74;
                }
                .birthday {
                    margin-left: -30px;
                }
                .input-select {
                    box-sizing: border-box;
                    align-items: center;
                    padding: 10px 40px;
                    width: 100%;
                    height: 40px;
                    background: #FFF;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 10px;
                    margin: 2px;
                    font-family: Mulish;
                }
                label {
                    text-align: left;
                    color: #000;
                    margin-left:4px;
                    margin-top: 10px;
                    font-size:12px;
                    font-family: Mulish;
                }
        
                .gender {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 40px;
                    width: 100%;
                    height: 40px;
                    font-family: Mulish;
                    border-radius: 10px;

                    img{
                        margin-top:10px;
                        width: 50px;
                    }
                }
                .location {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 52px;
                    gap: 202px;
                    width: 100%;
                    height: 40px;
                    background: #0A070F;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 10px;
                    font-family: Mulish;
                }
                .btn-next {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 42px;
                    background: #000;
                    border-radius: 82px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    color:#FFFFFF;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;
                }
                .btn-done {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 42px;
                    background: #D33E74;
                    border-radius: 82px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    margin-left: -10px;
                    color:#FFFFFF;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;
    
                }
        
            
            }
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 50px;
                background: #000;
                width:94%;
                padding: 10px;
                margin-left: 10px;
            
                .logo > img{
                    width: 50%;
                }
                .contact {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: right;
                    padding: 10px;
                    font-weight: bold;
                    font-family: Mulish;
                    font-size: 10px;
                    color: #000;
                    line-height: 0px;
    
                }    
             }
        
        }
    }
    
    </style>