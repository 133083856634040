<template>
    <div class="container-fluid">
        <!-- <div class="header navbar navbar-fixed-top navbar-default">
            <span class="logo">
                <router-link to="/"> <img src="logo.png" alt="lets play" width="90"> </router-link>
            </span>
            <div class="login">
                <a href="#login"> <img src="../../assets/fa-user.png" alt=""> Login </a>
            </div>
        </div> -->
        <div class="container">
            <div class="row ">
                <div class="col-md-6">
                    <div class="row">
                        <p>&nbsp;</p>
                        <p class="welcome">
                            Email verification: <span v-if="verify == 1">{{ msg }}. </span> <span v-else> {{ msg }} </span> 
                        </p>
                    </div>
                    <div class="row" v-if="verify == 1">
                        <p>&nbsp;</p>

                        <div>
                            <label style="color:#FFF"> Password: </label>
                            <input type="password" class="form-control" placeholder="Enter pasword" v-model="password" maxlength="12">
                        </div>
                        <div style="margin-top:10px">
                            <label style="color:#FFF"> Confirm Password: </label>
                            <input type="password" class="form-control" placeholder="Confirm password" v-model="cpassword" maxlength="12">
                        </div>
                        <div style="margin-top:10px">
                            <button class="btn btn-info" @click="createPassword">
                              <span v-if="loading">
                                <i class="fas fa-spinner fa-spin"></i> Creating...
                              </span>
                              <span v-else>
                                <i class="fa fa-lock"></i> Create
                              </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                   <div class="row right-box">
                        <div class="col-md-6">
                            <img src="../../assets/banner.svg" alt="dating" width="450" class="dating">
                        </div>
                        
                   </div>
                </div>
                
            </div>
        </div>
    
    </div>
    
    </template>
    
    <script>
    
    import swal from 'sweetalert2';
    import Vue from 'vue'
    import VueTelInput from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css';
    import VueCountryCode from "vue-country-code";
    //import axios from 'axios';
    
    Vue.use(VueTelInput)
    Vue.use(VueCountryCode)
    export default {
         data: () => ({
            email: '',
            password: '',
            cpassword: '',
            firstname: '',
            lastname: '',
            phone_number: '',
            email_address: '',
            user_password: '',
            title_designation: '',
            company_name: '',
            user_country: '',
            country_dial_code: 0,
            user_state: '',
            user_city: '',
            user_date: '',
            user_month: '',
            user_day: '',
            user_gender: '',
            notify_birthday: 1,
            previewImage: null,
            user_role: '',
            error_code:'',
            message:'',
            status:'',
            response: '',
            loading: false,
    
            user_email: '',
    
            signUp: true,
            signIn: false,
            cardIf: false,  
            verify: 0, 
            msg: '',        
          
        }),
        
        beforeMount(){
            this.getPlayerProfile(this.$route.params.id);
        },
    
        mounted(){
            localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
        },
    
        methods: {
    
            async getPlayerProfile(e) {

            this.pageLoader = true
            this.select_player = true
            const response = await this.$http.post('/verify-email', {
                user_email: e,
            });
            this.pageLoader = false
            this.user_email = e
            this.verify = response.data.verify
            this.msg = response.data.message

            if (!response.data.success) {
                swal.fire({
                    icon: "info",
                    title: 'Email already verified',
                    width:450,
                    height:5,
                    text: response.data.message,
                });
                return;

            } else {
                swal.fire({
                    icon: "success",
                    title: 'Verified',
                    width:450,
                    height:5,
                    text: response.data.message,
                });
                return;
            }
        },

        async createPassword() {

            this.loading = true
            
            if(this.password != this.cpassword){

                swal.fire({
                    icon: "error",
                    title: 'Password Mismatch',
                    width:450,
                    height:5,
                    text: "Password confirmation does not match!",
                });
                return;
            }

            const response = await this.$http.post('/create-password', {
                email: this.user_email,
                password: this.password,
                cpassword: this.cpassword
            });
            
            this.loading = false

            if (!response.data.success) {
                swal.fire({
                    icon: "info",
                    title: 'Success',
                    width:450,
                    height:5,
                    text: response.data.message,
                });
                return;

            } else {

                swal.fire({
                    icon: "success",
                    title: 'Success',
                    width:450,
                    height:5,
                    text: response.data.message,
                });
                

                this.response = await this.$http.post('/login', {
                    email: this.user_email,
                    password: this.password,
                });
              
                this.loading = false;
                
                    let token = this.response.data.token;
                    let play = this.response.data.play;
                    let hangout = this.response.data.hangout;
                    let marry = this.response.data.marry;
                    let typestatus = this.response.data.app_status;
                    let email = this.response.data.email;
                    let photos = this.response.data.photos;
                    let profile_photo = this.response.data.profile_photo;

                    await localStorage.setItem("wertyuiojhdfghhdrtfyguh", token)
                    await localStorage.setItem("app_status", typestatus)
                    await localStorage.setItem("photos", photos)
                    await localStorage.setItem("profile_photo", profile_photo)
                    await localStorage.setItem("email", email)
                    await localStorage.setItem("play", play)
                    await localStorage.setItem("hangout", hangout)
                    await localStorage.setItem("marry", marry)
                    
                    if(typestatus == 1){
                        if ((photos == 0) || (profile_photo == null)){
                                this.$router.push('/profile');
                                return;
                            }
                         this.$router.push('/hangout');
                         swal.fire({
                                icon: "success",
                                title: this.response.data.message,
                                width:450,
                                height:5,
                                text: "You are welcome",
                        });
                        return;
                        }
                        else if(typestatus == 2){
                            if ((photos == 0) || (profile_photo == null)){
                                this.$router.push('/profile');
                                return;
                            }
                         this.$router.push('/marry');
                         swal.fire({
                                icon: "success",
                                title: this.response.data.message,
                                width:450,
                                height:5,
                                text: "You are welcome",
                        });
                        return;
                    }
                
            }

            
        },
        
            showText(){
                const input =  document.getElementById('show_text');
                if (input.getAttribute("type") === "password") {
                    input.setAttribute("type", "text");
                        document.getElementById('eye_close').style.display="none";
                        document.getElementById('eye_open').style.display="block";
                    } else {
                    input.setAttribute("type", "password");
                        document.getElementById('eye_close').style.display="block";
                        document.getElementById('eye_open').style.display="none";
                    }
            },

            showSignIn() {
                document.getElementById("signInId").className = "Active";
                document.getElementById("signUpId").className = "signIn";
                // document.getElementById('cardToggle').style.display = "none";
                    //document.getElementById('cardToggle').style.width = "200px";
                // document.getElementById('cardToggleSignup').style.display = "block";
                // document.getElementById('cardToggleSignin').style.display = "block";

                //document.getElementById("cardId").className = "signIn";
                this.signIn = true
                this.signUp = false
                // this.cardIf = false
            },
            showSignUp() {
                document.getElementById("signInId").className = "signIn";
                //document.getElementById("cardId").className = "signIn";
                document.getElementById("signUpId").className = "Active1";
                this.signIn = false
                //this.cardIf = false
                this.signUp = true
                
            },
            showCardIn() {
                document.getElementById("signInId").className = "signIn";
                document.getElementById("signUpId").className = "signIn";
                //document.getElementById("cardId").className = "Activex";
                this.signIn = true
                this.signUp = false
                //this.cardIf = true
            },

          
        },
    
    }
    
    // const phoneInputField = document.querySelector("#phone");
    //    const phoneInput = window.intlTelInput(phoneInputField, {
    //      utilsScript:
    //        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    //    });
    </script>
    <style lang="scss" scoped>
    body {
        background: #0A070F !important;
    }
    .modal-window {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        &:target {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
        & > div {
          width: 580px;
          height: 505px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2em;
          border: 1px solid #2F2F2F;
          backdrop-filter: blur(13px);
          box-sizing: border-box;
        }
        header {
          font-weight: bold;
        }
        h1 {
          font-size: 150%;
          margin: 0 0 15px;
        }
        h2 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }
        .welcome {
            margin-top: 8px;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }
        label {
            text-align: left;
            color: #fff;
            font-size: 12px;
            margin-left:15px;
            margin-bottom: -6px;
        }
        span {
            margin-bottom: 20px;
        }
    
      }
      
      .modal-close {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
        &:hover {
          color: black;
        }
      }
      .modal-close-mobile {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
        &:hover {
          color: black;
        }
        display: none;
      }
      .modal-window {
        & > div {
            border-radius: 63px;
        }
      }
      .modal-logo {
        text-align: center;
        margin-top: -10px;
      }
      .modal-logo > img {
        width: 100px;
        
    }
    h2 {
       
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        line-height: 190%;
        margin-top: 0px;
    
    }
    .modal-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        width: 380px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 50px;
        margin: 2%;
        cursor: pointer;
    }
    .login-modal-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 311px;
        width: 496px;
        height: 45px;
        background: #0A070F;
        border: 0.5px solid #FFFFFF;
        border-radius: 50px;
        margin: 10px;
        color: #767475;
    }
    .btn-login {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        background: #D33E74;
        border-radius: 82px;
        margin-top: 8px;
        color: #fff;
        margin-left: 10px;
        
    }
    .terms {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    
        span {
            color: #D33E74;
        }
    }
    .forgot-password {
    
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 25px;
    
        span > a {
            color: #D33E74;
            cursor: pointer;
            text-decoration: none;
    
        }
    }
    
    .container-fluid{
        background: #0A070F;
        width: 100%;
        height: 100vh;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 0%;
    
        .container{
            background: #0A070F;
            width: 100%;
           //height: 1000px;
            //margin-bottom: 0%;
        
            .box {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 53px;
                width: 430px;
                height: 70px;
                border: 1px solid #D33E74;
                border-radius: 8px;
                margin: 7%;
                cursor:pointer;
            }
           
            .box > a {
                text-decoration: none;
            }
            .box:hover {
                background: #D33E74;
                border: 1px solid #D33E74;
                border-radius: 8px;
                color: #fff;
    
                p {
                    color: #fff;
                 }
                 img {
                   filter: opacity(1.0) drop-shadow(0 0 0 white);
                 }
    
            }
            .welcome {
                width: 450px;
                margin-left:35px;
                margin-bottom: 50px;
                font-family: 'Metropolis';
                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 107.7%;
                color: #FFFFFF;
                text-align: justify;
            }
            p {
                height: 26px;
                font-family: 'Metropolis';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 107.7%;
                color: #D33E74;
                text-align: left;
            }
          
            .right-box {
                margin-top: 15%;
    
                img {
                    background: #0A070F;
                }
            }
            .dating {
               margin-top: -40px
            }
            .marry{
                margin: 20px;
                margin-right: 30%;
            }
            .hangout{
                margin-right: 20%;
            }
            .top-smiling {
                position: absolute;
                z-index: 1;
                width: 100%;
                top: 0;
                margin-top: -20px;
                margin-left: -100px;
            }
            .top-love {
                position: absolute;
                z-index: 1;
                width: 100%;
                top: 100;
                margin-top: 165px;
                margin-left: -230px;
            }
          
        
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            height: 105px;
            background: #0A070F;
            width: 100%;
            margin-left: 0%;
            margin-right: 0%;
        
            .logo > img{
                width: 150px !important;
                height: 30px;
            }
            .login{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: 100px;
                height: 40px;
                background: #D33E74;
                border-radius: 82px;
                font-weight: bold;
                font-size: 14px;
                color: #fff;
    
                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
         }
    
    }
    
    @media screen and (max-width: 768px) {
    
        .modal-window {
            position: fixed;
            //background-color: rgba(255, 255, 255, 0.25);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s;
            &:target {
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            }
            & > div {
              width: 300px;
              height: 490px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding: 2em;
              border: 1px solid #2F2F2F;
              backdrop-filter: blur(13px);
              box-sizing: border-box;
            }
            header {
              font-weight: bold;
            }
            h1 {
              font-size: 150%;
              margin: 0 0 15px;
            }
          }
          
          .modal-close {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
            display: none;
          }
          .modal-close-mobile {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
            display: block;
          }
          .modal-window {
            & > div {
                border-radius: 63px;
            }
          }
          .modal-logo > img {
            width: 90px;
            text-align: center;
        }
        h2 {
           
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 800;
            font-size: 17px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            line-height: 190%;
            margin-top: 20px;
        
        }
        .modal-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 15px;
            gap: 19px;
            width: 360px;
            height: 46px;
            background: #FFFFFF;
            border-radius: 50px;
            margin: 2%;
            cursor: pointer;
            font-size: 12.5px;
        }
        .terms {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
        
            span {
                color: #D33E74;
            }
        }
    
    }
    
    @media screen and (max-width: 768px) {
      
    }
    
    @media screen and (max-width: 768px) { 
        .container-fluid {
            background: #0A070F;
            height: 100%;
            margin-bottom: 0%;
    
            .header {
    
                    .logo > img{
                        width: 80px !important;
                        height: 16px;
                    }
                    .login {
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        width: 100px;
                        height: 40px;
                        background: #D33E74;
                        border-radius: 82px;
                        font-weight: bold;
                        color: #fff;
                        margin: 10px;
    
                    }    
                    .login > img{
                        height: 14px;
                    }
                }        
                
    
                .container {
                    background: #0A070F;
                    margin-right: 0px !important;
                    margin-bottom: 0%;
                
                    .box {
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 4px 20px;
                        width: 270px;
                        height: 55px;
                        border: 1px solid #D33E74;
                        border-radius: 8px;
                        margin: 7%;
                    }
                    .welcome {
                        width: 280px;
                        margin-left:35px;
                        margin-bottom: 50px;
                        font-family: 'Metropolis';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 107.7%;
                        color: #FFFFFF;
                        text-align: justify;
                    }
                    p {
                        height: 26px;
                        font-family: 'Metropolis';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 80.7%;
                        color: #D33E74;
                        text-align: left;
                    }
                    .right-box {
                        margin-top: -10%;
                    }
                    .dating {
                        width: 180px !important;
                        margin-top: 30px;
                        
                    }
                    .marry{
                        margin-right: 8%;
                        width: 100px !important;
                    }
                    .hangout{
                        margin-top: 20px;
                        width: 120px !important;
                    }
                    .top-smiling {
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        top: 0;
                        margin-top: 0px;
    
                    }
                    .top-love {
                        position: absolute;
                        z-index: 1;
                        margin-top: 15px;
                        margin-right: 0px;
                    }
                }
    
        }
     }
    
    
    
    </style>