<template>
    <div class="background">
        <div class="container">
            <div class="card">
                <div class="menu-bar">
                    <h2>
                        <img src="../../assets/sales_report.jpeg" alt=""> Report
                    </h2>
                </div>
                <div class="tab">
                    <div class="tab-receipts" @click="tabReceipts()">
                      <i class="fa fa-file-invoice"></i> Pool
                    </div>
                    <div class="tab-orders" @click="tabOrders()">
                        <i class="fa fa-file-word"></i> Savings
                    </div>
                    <div class="custom-report" >
                        <i class="fa fa-database" aria-hidden="true"></i> Custom
                    </div>
                    <!-- <div class="stock-report" @click="stockReport">
                        <i class="fas fa-file"></i> Inventory
                    </div> -->
                </div>
               
                <div v-if="order_status">
                    <h6><strong>Savings Report</strong></h6>
                    <div class="search-input">
                        <p class="search-name">Savings type: </p>
                        <select class="form-control waiters" id="category_name" v-model="category_name">
                            <option value="">-Select-</option>
                            <option v-for="(data, index) in savingstype" :key="index" :value="data.category_id">{{ data.category_name}}</option>
                        </select>
                        <p class="search-name">Date From: </p>
                        <input type="date" class="form-control searchdate" v-model="date_from" />
                        <p class="search-name">Date To: </p>
                        <input type="date" class="form-control searchdate" v-model="date_to"/>
    
                        <button class="form-control btn-search" @click="searchSavings()" v-if="!loading">
                             Search </button>
                        <button class="form-control btn-search" style="width: 10% !important;" type="button" disabled v-if="loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
    
                    </div> 
                   
                    <div class="view-content">  
                        <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Staff</th>
                                    <th>ID</th>
                                    <th>Amount(NGN)</th>
                                    <th>Number of registration </th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in options" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.firstname }} {{ data.middlename }} {{ data.lastname }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.staff_id }} 
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.totalAmount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                    <td style="font-weight: 400; width:5%">
                                       {{ data.totalMembers }}
                                    </td>
                                   
                                    <td style="font-weight: 400;">
                                        {{ new Date(data.created_at).toDateString() }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-if="receipt_status">
                    <h6> <strong> Pools Report</strong></h6>
                    <div class="search-input">
                        <p class="search-name">Pool type: </p>
                        <select class="form-control waiters" id="category_name" v-model="category_name">
                            <option value="">-Select-</option>
                            <option v-for="(data, index) in poolstype" :key="index" :value="data.category_id">{{ data.category_name}}</option>
                        </select>
                        <p class="search-name">Date From: </p>
                        <input type="date" class="form-control searchdate" v-model="date_from2" />
                        <p class="search-name">Date To: </p>
                        <input type="date" class="form-control searchdate" v-model="date_to2"/>
    
                        <button class="form-control btn-search1" v-if="!loading2" @click="searchPools()">
                             Search </button>
                        <button class="form-control btn-search1" style="width: 10% !important;" type="button" disabled v-if="loading2">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
    
                    </div> 
                    
                    <div class="view-content">
                        
                        <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Staff</th>
                                    <th>ID</th>
                                    <th>Amount(NGN)</th>
                                    <th>Number of registration </th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in options2" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.firstname }} {{ data.middlename }} {{ data.lastname }}
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.staff_id }} 
                                    </td>
                                    <td style="font-weight: 400;">
                                        {{ data.totalAmount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                    </td>
                                    <td style="font-weight: 400; width:5%">
                                       {{ data.totalMembers }}
                                    </td>
                                   
                                    <td style="font-weight: 400;">
                                        {{ new Date(data.created_at).toDateString() }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-if="custom_report">
                    <h6> <strong> Custom Report</strong></h6>
                    <div class="custom-search-input">
                        <p class="search-name">Report Type: </p>
                        <select class="form-control waiters" id="report_type" v-model="report_type">
                            <option value="">-Select-</option>
                            <option value="1">Daily</option>
                            <option value="2">Monthly</option>
                            <option value="3">Yearly</option>
                        </select>
    
                        <button class="form-control btn-search1" v-if="!loading3" @click="currentReport()">
                            Search </button>
                        <button class="form-control btn-search1" style="width: 10% !important;" type="button" disabled v-if="loading3">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        
                        &nbsp;&nbsp;&nbsp;
                        <p class="search-name">Custom Search: </p>

                        <select class="form-control waiters" id="report_type" v-model="select_type" required>
                            <option value="">-Select Type-</option>
                            <option value="1">Daily</option>
                            <option value="2">Monthly</option>
                        </select>

                        <select class="form-control waiters" id="report_type" v-model="select_month" required>
                            <option value="">-Select Month-</option>
                            <option value="Jan">January</option>
                            <option value="Feb">February</option>
                            <option value="Mar">March</option>
                            <option value="Apr">April</option>
                            <option value="May">May</option>
                            <option value="Jun">June</option>
                            <option value="Jul">July</option>
                            <option value="Aug">August</option>
                            <option value="Sep">September</option>
                            <option value="Oct">October</option>
                            <option value="Nov">November</option>
                            <option value="Dec">December</option>
                        </select>

                        <select id="years" class="form-control waiters" v-model="select_year" required>
                            <option value="">-Select Year-</option>
                            <option v-for="(data, index) in years" :key="index" :value="data.year">{{ data.year}}</option>
                        </select>

                        <button class="form-control btn-search1" v-if="!loading4" @click="customSearch()">
                            Search </button>
                        <button class="form-control btn-search1" style="width: 10% !important;" type="button" disabled v-if="loading4">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                                                
                    </div> 
                  
                    <div class="view-content">
                        <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                            <thead>
                                <!-- <tr><td colspan="10"> Sales: <strong> NGN{{ totalReceiptsAmount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</strong></td></tr> -->
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th v-if="reportValue == 1">Day </th>
                                    <th v-if="reportValue == 2">Month </th>
                                    <th v-if="reportValue == 3">Year </th>
                                    <th>Sales </th>
                                    <th>Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in report" :key="index" style="font-size:9px">
                                    <td style="font-weight: 400; width:4%">
                                        {{ index + 1 }}
                                    </td>
                                    <td style="font-weight: 400; width:20%" v-if="reportValue == 1">
                                        {{ data.day }}, {{ data.days }} {{ data.month }} {{ data.year }}
                                    </td>
                                    <td style="font-weight: 400; width:20%" v-if="reportValue == 2">
                                         {{ data.month }} {{ data.year }}
                                    </td>
                                     <td style="font-weight: 400; width:20%" v-if="reportValue == 3">
                                        {{ data.year }}
                                    </td>
                                    <td style="font-weight: 400; width:20%">
                                        {{ data.totalSales.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} 
                                    </td>
                                     <td style="font-weight: 400; width:10%" v-if="data.profit == NULL">
                                        N/A
                                    </td>    
                                    <td style="font-weight: 400; width:10%" v-else>
                                        {{ data.profit.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} 
                                    </td>                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               
            </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
//import Vue from 'vue'
import swal from 'sweetalert2';
import PageLoader from '../../components/PageLoader.vue'
//import $ from "jquery";

export default {
    components: {
            PageLoader,
        },

    data() {
        return {
            isLoading: false,
            options: [],
            options2: [],
            options3: [],
            report:[],
            app_role: '',
            cfirstname: '',
            clastname: '',
            cusername: '',
            userid: '',
            check_items: [],
            customer_order: false,
            loading: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            customer_items: [],
            selected_items: '',
            total_amount: '',
            quantity: [],
            customer_name: '',
            customer_phone: '',
            timerInterval: '',
            timerInterval2: '',
            url: '',
            order_status: true,
            receipt_status: false,
            custom_report: false,
            stock_report: false,
            showMonth: true,
            category_name:'',
            savingstype: [],
            date_from: '',
            date_to: '',
            date_from2: '',
            date_to2: '',
            payment_status: '',
            order_id: '',
            search_url: '',
            response: '',
            totalOrdersAmount: 0,
            totalReceiptsAmount: 0,
            report_type: '',
            reportValue: '',
            select_type: '',
            select_month: '',
            select_year: '',
            years: [],
            menu_search: '',
            stock_period: '',
            stock_month: '',
            stock_year: '',
            poolstype: [],
        };
    },

    beforeMount() {
        this.fetchAllSavings();
        this.fetchAllPools()
    },

    mounted() {
        this.app_role = localStorage.getItem("app_status");
        this.cfirstname = localStorage.getItem("firstname");
        this.clastname = localStorage.getItem("lastname");
        this.cusername = localStorage.getItem("username");
        this.userid = localStorage.getItem("userid");
        
    },

    created() {

    // Set an interval to call fetchAllOrders() every 5 seconds
    //this.timerInterval = setInterval(this.fetchMyOrders, 5000);
    },
    beforeDestroy() {
        // Clear the interval when the component is about to be destroyed
        clearInterval(this.timerInterval);
    },
    computed: {
        filteredMenu() {
            const searchString = this.menu_search.toLowerCase();
            return this.options3.filter(item => item.title.toLowerCase().includes(searchString));
        },
    },

    methods: {

        tabReceipts(){
            this.receipt_status = true
            this.order_status = false
            this.custom_report = false
            this.stock_report = false

            const tab1 = document.getElementsByClassName("tab-receipts");
            tab1[0].style.color = "#fff";

            const tab2 = document.getElementsByClassName("tab-orders");
            tab2[0].style.color = "#000";

            const tab3 = document.getElementsByClassName("custom-report");
            tab3[0].style.color = "#000";

            const tab4 = document.getElementsByClassName("stock-report");
            tab4[0].style.color = "#000";
        },

        tabOrders(){
            this.order_status = true
            this.receipt_status = false
            this.custom_report = false
            this.stock_report = false

            const tab1 = document.getElementsByClassName("tab-receipts");
            tab1[0].style.color = "#000";

            const tab2 = document.getElementsByClassName("tab-orders");
            tab2[0].style.color = "#fff";

            const tab3 = document.getElementsByClassName("custom-report");
            tab3[0].style.color = "#000";

            const tab4 = document.getElementsByClassName("stock-report");
            tab4[0].style.color = "#000";

        },

        customReport() {
            this.custom_report = true,
            this.receipt_status = false
            this.order_status = false
            this.stock_report = false

            const tab1 = document.getElementsByClassName("tab-receipts");
            tab1[0].style.color = "#000";

            const tab2 = document.getElementsByClassName("tab-orders");
            tab2[0].style.color = "#000";

            const tab3 = document.getElementsByClassName("custom-report");
            tab3[0].style.color = "#fff";

            const tab4 = document.getElementsByClassName("stock-report");
            tab4[0].style.color = "#000";
        },

        async searchSavings() {
            try {

                if (this.date_to != '') {
                    
                    if (!this.date_from) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width: 450,
                        height: 5,
                        text: 'Please select starting date',
                    })
                    return;
                    }

                }

                this.loading = true;
                
                this.response = await this.$http.post("/search-report-savings", {
                    category_name: this.category_name,
                    date_from: this.date_from,
                    date_to: this.date_to
                });
          
                this.loading = false;

                this.options = this.response.data.savingstype;
                
            } catch (err) {
                console.log(err);
            }
        },

        async searchPools() {
            try {

                if (this.date_to2 != '') {
                    
                    if (!this.date_from2) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width: 450,
                        height: 5,
                        text: 'Please select starting date',
                    })
                    return;
                    }

                }

                this.loading2 = true;
                
                this.response = await this.$http.post("/search-report-pools", {
                    category_name: this.category_name,
                    date_from: this.date_from2,
                    date_to: this.date_to2
                });
                
                              
                this.loading2 = false;

                this.options2 = this.response.data.poolstype;                

            } catch (err) {
                console.log(err);
            }
        },

        async fetchAllSavings() {
            try{
                    this.isLoading = true
                    const response = await this.$http.get('/all-current-savings', )
                    this.savingstype = response.data.savingstype;   
                    this.isLoading = false
                }catch (err) {
                    console.log(err);
                }
        },

        async fetchAllPools() {
            try{
                    this.isLoading = true
                    const response = await this.$http.get('/all-current-pools', )
                    this.poolstype = response.data.poolstype;   
                    this.isLoading = false
                }catch (err) {
                    console.log(err);
                }
        },

        async currentReport(){

            if (!this.report_type) {
                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width: 450,
                    height: 5,
                    text: 'Please select report type',
                })
                return;
            }

            this.reportValue = this.report_type
            if (this.reportValue == 1) {
                try{
                    this.loading3 = true;
                    const response = await this.$http.get('/daily-report', )
                    this.report = response.data.dailyReport;
                    this.loading3 = false;
                            
                    }catch (err) {
                    console.log(err);
                }
            }
            if (this.reportValue == 2) {
                try{
                    this.loading3 = true;
                    const response = await this.$http.get('/monthly-report', )
                    this.report = response.data.monthlyReport;
                    this.loading3 = false;       
                    }catch (err) {
                    console.log(err);
                }
            }
            if (this.reportValue == 3) {
                try{
                    this.loading3 = true;
                    const response = await this.$http.get('/yearly-report', )
                    this.report = response.data.yearlyReport;
                    this.loading3 = false;        
                    }catch (err) {
                    console.log(err);
                }
            }
        },

        async customSearch(){

            if (!this.select_type) {
                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width: 450,
                    height: 5,
                    text: 'Please select report type',
                })
                return;
            }
            if (!this.select_month) {
                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width: 450,
                    height: 5,
                    text: 'Please select month',
                })
                return;
            }
            if (!this.select_year) {
                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width: 450,
                    height: 5,
                    text: 'Please select year',
                })
                return;
            }

            this.reportValue = this.select_type
            if (this.reportValue == 1) {
                try{
                    this.loading4 = true;
                    const response = await this.$http.post('/custom-daily-report', {
                        month: this.select_month,
                        year: this.select_year
                    })
                    this.report = response.data.dailyReport;
                    this.loading4 = false;
                            
                    }catch (err) {
                    console.log(err);
                }
            }
            if (this.reportValue == 2) {
                try{
                    this.loading4 = true;
                    const response = await this.$http.post('/custom-monthly-report', {
                        month: this.select_month,
                        year: this.select_year
                    } )
                    this.report = response.data.monthlyReport;
                    this.loading4 = false;       
                    }catch (err) {
                    console.log(err);
                }
            }
        },

        async fetchStock() {
         try{
            const response = await this.$http.get('/stock-report', )
            this.options3 = response.data.stockReport;    
                        
            }catch (err) {
                console.log(err);
            }
        },

        showSelect(){
            this.reportValue = this.stock_period
            if(this.reportValue == 1){
                this.showMonth = false
            }
            else if(this.reportValue == 2) {
                this.showMonth = true
            }
        },

        async stockSearch(){
        
        this.reportValue = this.stock_period

        if (!this.stock_period) {
            swal.fire({
                icon: 'warning',
                title: 'Error...',
                width: 450,
                height: 5,
                text: 'Please select stock period',
            })
            return;
        }
        if(this.reportValue == 2){
        if (!this.stock_month) {
            swal.fire({
                icon: 'warning',
                title: 'Error...',
                width: 450,
                height: 5,
                text: 'Please select month',
            })
            return;
        }
        if (!this.stock_year) {
            swal.fire({
                icon: 'warning',
                title: 'Error...',
                width: 450,
                height: 5,
                text: 'Please select year',
            })
            return;
        }
        }

      
        if (this.reportValue == 1) {
            try{

                this.loading5 = true;
                this.fetchStock();
                this.loading5 = false;
                        
                }catch (err) {
                console.log(err);
            }
        }
        if (this.reportValue == 2) {
            try{

                this.loading5 = true;
                const response = await this.$http.post('/daily-stock-report', {
                    month: this.stock_month,
                    year: this.stock_year
                })

                this.options3 = response.data.dailyStockReport;
                this.loading5 = false;
                        
                }catch (err) {
                console.log(err);
            }
        }
       
        },
     }
    }
</script>
    
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #2b0c17;
        background: #FFF;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
    }

    .welcome {
        margin-top: 8px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 23px;
        justify-content: center;
        color: #FFFFFF;

    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

    .counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .btn-counter {
        padding: 6px;
        font-weight: bold;
        font-size: 10px;
        border: 1px solid #fbd43a;
        margin: 5px;
        width: 20%;
        font-family: Mulish;
    }

    .order-content {
        height: 200px;
        overflow-y: scroll;
    }

}

.customer-input {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    font-family: Mulish;
}

.btn-login {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 52px;
    background: #000;
    border-radius: 8px;
    margin: 10px;
    color: #fff;
    width: 100%;
    font-size: 10px;
    margin-bottom: 20px;
    font-family: Mulish;

}

.btn-login:hover {
    background: #21182f;
    border: 1px solid #21182f;
}

a {
    text-decoration: none;
}


.modal-window {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &:target {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &>div {
        width: 680px;
        height: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2em;
        position: absolute;
        width: 372px;
        height: 420px;
        background: #19181B;
        border-radius: 50px;
    }

    header {
        font-weight: bold;
        font-family: Mulish;
    }

    h1 {
        font-size: 150%;
        margin: 0 0 15px;
        font-family: Mulish;
    }

    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
    }

    .welcome {
        margin-top: 15px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
    }

    label {
        text-align: left;
        color: #fff;
        font-size: 12px;
        margin-left: 15px;
        font-family: Mulish;
        margin-bottom: -6px;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

.modal-window {
    &>div {
        border-radius: 63px;
    }
}

.modal-logo {
    text-align: center;
}

.modal-logo>img {
    width: 100px;
    height: 100;
}

h2 {

    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    line-height: 190%;
    margin-top: 20px;

}

.modal-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 52px;
    gap: 19px;
    width: 380px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 50px;
    margin: 3%;
    cursor: pointer;
}

.login-modal-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    width: 100%;
    height: 30px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    margin: 10px;
    color: #000;
    font-size: 10px;
    font-family: Mulish;

}

.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 30%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    color: #000;
    font-weight: 800;
    font-family: Mulish;
}

.btn-proceed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 250px;
    height: 40px;
    background: #D33E74;
    border-radius: 82px;
    color: #FFFFFF;
    margin-left: -10px;
    font-family: Mulish;
}

.background {
    height: auto;
    background: #ccc;
    width: 100%;
    border-radius: 10px;


    .container {
        background: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: auto;
        

        .card {
            margin: 6px;
            width: 100%;
            height: auto;
            padding: 4px;
            background: transparent;
            font-size: 12px;
            border: none;
            color: #000;
            height: auto;

            .menu-bar{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 95%;
                font-family: Mulish;
                box-sizing: border-box;
                height: 50px;
                background: #000;
                padding: 10px;
                border-radius: 4px;
            }
            .img-order{
                font-family: Mulish;
                font-size: 14px;
                color: #fff;
                text-decoration: underline;
                img{
                    width: 20px;
                    margin-top: 5px;
                }
            }

            .tab{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 95%;
                font-family: Mulish;
                box-sizing: border-box;
                padding: 10px;
                border-radius: 4px;
                margin-top: 15px;
            }
            .tab-receipts{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-family: Mulish;
                font-size: 10px;
                box-sizing: border-box;
                height: 20px;
                background: #FFA500;
                padding: 10px;
                border-right: solid 2px #fff;
                color: #000;
                cursor: pointer;
            }
            .tab-receipts:hover{
                font-weight:bold;
                color: #000;
            }
            .tab-orders{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-family: Mulish;
                font-size: 10px;
                box-sizing: border-box;
                height: 20px;
                background: #FFA500;
                padding: 10px;
                border-right: solid 2px #fff;
                color: #fff;
                cursor: pointer;
            }
            .tab-orders:hover{
                font-weight:bold;
                color: #000;
            }

            .custom-report{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-family: Mulish;
                font-size: 10px;
                box-sizing: border-box;
                height: 20px;
                background: #FFA500;
                border-right: solid 2px #fff;
                padding: 10px;
                color: #000;
                cursor: pointer;
            }
            .custom-report:hover{
                font-weight:bold;
                color: #000;
            }
            .stock-report{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-family: Mulish;
                font-size: 10px;
                box-sizing: border-box;
                height: 20px;
                background: #FFA500;
                padding: 10px;
                color: #000;
                cursor: pointer;
            }
            .stock-report:hover{
                font-weight:bold;
                color: #000;
            }

            .search-input{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width:90%;
            }
            .custom-search-input{
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                width:90%;
            }
            .stock-search-input{
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                width:90%;
            }
            .current-report{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
            }
            .previous-report{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
            }
            .waiters{
                width: 12%;
                height:25px;
                margin-bottom: 2px;
                border: 1px solid #000;
                border-radius: 2px;
                font-size: 10px;
                font-family: Mulish;
                margin: 4px;
            }
            .orderid{
                width: 10%;
                height:25px;
                margin-bottom: 2px;
                border: 1px solid #000;
                border-radius: 2px;
                font-size: 10px;
                font-family: Mulish;
                margin: 4px;
            }
            .payment-status{
                width: 10%;
                height:25px;
                margin-bottom: 2px;
                border: 1px solid #000;
                border-radius: 2px;
                font-size: 10px;
                font-family: Mulish;
                margin: 4px;
            }
            .searchdate{
                width: 13%;
                height:25px;
                margin-bottom: 2px;
                border: 1px solid #000;
                border-radius: 2px;
                font-size: 10px;
                font-family: Mulish;
                margin: 4px;
            }
            .btn-search {
                width: 8%;
                height:25px;
                margin-bottom: 2px;
                border: 1px solid #000;
                background: #000;
                border-radius: 2px;
                font-size: 10px;
                font-family: Mulish;
                margin: 4px;
                color: #fff;
            }
            .btn-search1 {
                width: 8%;
                height:25px;
                margin-bottom: 2px;
                border: 1px solid #000;
                background: #000;
                border-radius: 2px;
                font-size: 10px;
                font-family: Mulish;
                margin: 4px;
                color: #fff;
            }
            p {
                font-family: Mulish;
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                text-align: left;
                color: #000;
                margin-top: 10px;
            }

            img {
                width: 50px;
                margin-top: 5px;
            }

            .view-content {
                padding: 10px;
                overflow-x: auto;
                overflow-y: auto;
                height: 350px;
                width: 96%;
                box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);

                .orders-btn {
                    font-family: Mulish;
                    font-size: 10px;
                }
            }
        }


        h2 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 42px;
            text-align: left;
            color: #fff;
        }

        span {
            .row {
                label {
                    color: #000;
                    text-align: left;
                    font-family: Mulish;
                }
            }


        }

        .form-group {
            margin: 4px;
        }

        .input-text {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            width: 100%;
            height: auto;
            background: #FFF;
            border: 0.5px solid #FFFFFF;
            border-radius: 4px;
            color: #000;
            font-size: 11px;
            font-family: Mulish;
        }

        .birthday {
            margin-left: -20px;
        }

        input:focus {
            border: 0.5px solid #D33E74;
        }

        select:focus {
            border: 0.5px solid #D33E74;
        }

        textarea:focus {
            border: 0.5px solid #D33E74;
        }

        option:focus {
            border: 0.5px solid #D33E74;
        }

        .input-select {
            box-sizing: border-box;
            align-items: center;
            padding: 10px 40px;
            width: 110%;
            height: auto;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 10px;
            font-family: Mulish;
            color: #767475;
            font-size: 11px;
        }

        label {
            text-align: left;
            color: #FFFFFF;
            margin-left: 4px;
            margin-top: 10px;
        }


        .gender {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 40px;
            width: 100%;
            height: auto;
            background: #fbd43a;
            font-family: Mulish;
            border-radius: 10px;
            color: #000;
            font-size: 11px;
        }

        .location {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 202px;
            width: 100%;
            height: auto;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 10px;
            color: #767475;
            font-size: 11px;
            font-family: Mulish;
        }

        .btn-next {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 100%;
            height: 50px;
            background: #000;
            border-radius: 82px;
            margin-top: 40px;
            margin-bottom: 40px;
            color: #FFFFFF;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .btn-done {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 435px;
            height: 50px;
            background: #D33E74;
            border-radius: 82px;
            margin-top: 40px;
            margin-bottom: 40px;
            color: #FFFFFF;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        background: #fbd43a;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        border-radius: 4px;
        font-family: Mulish;

        .logo>img {}

        .contact {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            width: auto;
            font-weight: normal;
            font-size: 13px;
            color: #000;
            line-height: 0px;
            font-family: Mulish;
        }
    }

    .btn-chips {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 40px;
        gap: 19px;
        width: 117.61px;
        height: 35px;
        background: #0A070F;
        border: 0.5px solid #FFFFFF;
        border-radius: 50px;
        margin: 6px;
        color: #767475;
        font-family: Mulish;
    }

    .btn-chips:hover {
        border: 0.5px solid #D33E74;
    }

    .rel-goal {
        color: #D33E74;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }

}

@media screen and (max-width: 768px) {
    .modal-window {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;

        &:target {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }

        &>div {
            width: 680px;
            height: 490px;
            position: absolute;
            top: 50%;
            left: 53%;
            transform: translate(-50%, -50%);
            padding: 2em;
            position: absolute;
            width: 300px;
            height: 400px;
            background: #19181B;
            border-radius: 50px;
        }

        header {
            font-weight: bold;
            font-family: Mulish;

        }

        h1 {
            font-size: 150%;
            margin: 0 0 15px;
            font-family: Mulish;
        }

        h2 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }

        .welcome {
            margin-top: 15px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }

        label {
            text-align: left;
            color: #fff;
            font-size: 12px;
            margin-left: 15px;
            margin-bottom: -6px;
            font-family: Mulish;
        }

        span {
            margin-bottom: 20px;
        }

    }

    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: black;
        }
    }

    .view-content {
        overflow-x: scroll;
        overflow-y: scroll;
        height: 350px;
        width: 96%;

        .orders-btn {
            height: 40px;
            font-size: 10px;
        }
    }

    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: black;
        }

        display: none;
    }

    .modal-window {
        &>div {
            border-radius: 63px;
        }
    }

    .modal-logo>img {
        margin-top: -30px;
        width: 120px;
        height: 120;
    }

    h2 {

        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        line-height: 190%;
        margin-top: 20px;

    }

    .modal-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 19px;
        width: 380px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 50px;
        margin: 3%;
        cursor: pointer;
        font-family: Mulish;
    }

    .login-modal-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        padding: 5px;
        width: 100%;
        height: 30px;
        background: #ccc;
        border-radius: 4px;
        margin: 10px;
        color: #000;
        font-size: 10px;
        font-family: Mulish;
    }

    .login-modal-box2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 80%;
        height: 30px;
        background: #fff;
        border: 1px solid #000;
        border-radius: 4px;
        color: #000;
        font-weight: 800;
        font-family: Mulish;
    }

    .btn-proceed {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 235px;
        height: 35px;
        background: #D33E74;
        border-radius: 82px;
        color: #FFFFFF;
        margin-left: -10px;
        font-size: 13px;
        font-family: Mulish;
    }
}

@media screen and (max-width: 768px) {

    .modal-close {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        

        &:hover {
            color: #fff;
        }

        display: none;
    }

    .modal-close-mobile {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        //text-decoration: none;

        &:hover {
            color: #fff;
        }

        display: block;
    }

    .btn-login {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 52px;
        background: #000;
        border-radius: 8px;
        margin: 10px;
        color: #fff;
        width: 100%;
        font-size: 10px;
        margin-bottom: 20px;
        font-family: Mulish;

    }

    .btn-login:hover {
        background: #D33E74;
        border: 1px solid #21182f;
    }

    .background {
        background: #fbd43a;
        width: 100%;
        height: 100vh;

        .container {
            background: #fbd43a;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 30px;

            .card {
                margin: 6px;
                width: 100%;
                height: auto;
                padding: 4px;
                background: transparent;
                font-size: 12px;
                border: none;
                color: #000;

                .menu-bar{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    box-sizing: border-box;
                    height: 50px;
                    background: #000;
                    padding: 10px;
                    border-radius: 4px;
                }

                .search-input{
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    width:100%;
                }
               
                .waiters{
                    width: 100%;
                    height:25px;
                    border: 1px solid #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                }
                .orderid{
                    width: 100%;
                    height:25px;
                    border: 1px solid #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                }
                .payment-status{
                    width: 100%;
                    border: 1px solid #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                }
                .searchdate{
                    width: 100%;
                    height:25px;
                    border: 1px solid #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                }

                .custom-search-input{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width:90%;
                }
                .stock-search-input{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width:90%;
                }
                .search-name {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    text-align: left;
                    color: #000;
                    margin-bottom: -2px;
                }
                .btn-search {
                    width: 100%;
                    height:25px;
                    margin-bottom: 2px;
                    border: 1px solid #000;
                    background: #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                    color: #fff;
                }
                .btn-search1 {
                    width: 100%;
                    height:25px;
                    margin-bottom: 2px;
                    border: 1px solid #000;
                    background: #000;
                    border-radius: 2px;
                    font-size: 10px;
                    font-family: Mulish;
                    margin: 4px;
                    color: #fff;
                }
                .img-order{
                    font-size: 13px;
                    color: #fff;
                    font-family: Mulish;
                    text-decoration: underline;

                    img{
                        width: 18px;
                        margin-top: -5;
                    }
                }

                img {
                    width: 50px;
                }
            }

            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 42px;
                text-align: left;
                color: #fff;
            }

            .form-group {
                margin: 4px;
            }

            .input-text {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 30px;
                width: 100%;
                height: 40px;
                background: #FFF;
                border: 0.5px solid #fff;
                border-radius: 10px;
                font-family: Mulish;
            }

            input:focus {
                border: 0.5px solid #D33E74;
            }

            select:focus {
                border: 0.5px solid #D33E74;
            }

            textarea:focus {
                border: 0.5px solid #D33E74;
            }

            option:focus {
                border: 0.5px solid #D33E74;
            }

            .birthday {
                margin-left: -30px;
            }

            .input-select {
                box-sizing: border-box;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: 40px;
                background: #FFF;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                margin: 2px;
                font-family: Mulish;
            }

            label {
                text-align: left;
                color: #000;
                margin-left: 4px;
                margin-top: 10px;
                font-size: 12px;
                font-family: Mulish;
            }

            .gender {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: 40px;
                font-family: Mulish;
                border-radius: 10px;
                //margin: 6px;

                img {
                    margin-top: 10px;
                    width: 50px;
                }
            }

            .location {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                gap: 202px;
                width: 100%;
                height: 40px;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                font-family: Mulish;
            }

            .btn-next {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 100%;
                height: 42px;
                background: #000;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color: #FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
            }

            .btn-done {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 100%;
                height: 42px;
                background: #D33E74;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                margin-left: -10px;
                color: #FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;

            }


        }

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 105px;
            background: #fbd43a;
            width: 100%;
            padding: 15px;

            .logo>img {
                width: 50%;
            }

            .contact {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: right;
                padding: 10px;
                font-weight: bold;
                font-size: 15px;
                color: #000;
                line-height: 0px;
                font-family: Mulish;

            }
        }

    }
}</style>