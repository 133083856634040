<template>
<div class="background">
    <div class="row">
        <div class="col-lg-12">
        <div class="header navbar navbar-fixed-top navbar-default">
            <div class="button" id="btn" @click="showSidebar">
                <div class="bar top"></div>
                <div class="bar middle"></div>
                <div class="bar bottom"></div>
            </div>
            <span class="logo" id="logo">
                <router-link to="#"> <img :src="logo_url" alt="Logo" width="50"> </router-link>
            </span>
            <p class="company-name"> {{ company_name }} </p>
            <div class="bell-profile dropdown">
                <span class="profile dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="fa fa-user"></i>&nbsp; {{ firstname}} </span> 
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <router-link class="dropdown-item" to="#"> <span @click="logout">Logout</span> </router-link>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="sidebar" id="sidebar" v-if="user_role == 1">
                <span class="window-close"  @click="showSidebar()"><i class="fa fa-window-close" aria-hidden="true" style="cursor:pointer"></i></span>
                    <router-link id="home" class="active" to="/home" data-toggle="tooltip" title="Home"><i class="fa fa-home"></i></router-link>
               <span>
                    <router-link id="game" class="active" to="/manage-users" data-toggle="tooltip" title="Manage Users"><img src="../../assets/add_user.png" alt="" width="15px" style="border-radius: 47px"></router-link>
                    <router-link id="game" class="active" to="/manage-transactions" data-toggle="tooltip" title="Manage Transactions"><i class="fas fa-book"></i></router-link>
                    <router-link id="chats" class="active" to="/setup-packages" data-toggle="tooltip" title="Setup Packages"><i class="fas fa-tools"></i> </router-link>
                    <router-link id="chats" class="active" to="/setup-package-duration" data-toggle="tooltip" title="Setup Package Duration"><i class="fas fa-screwdriver"></i> </router-link>
                    <router-link id="chats" class="active" to="/report" data-toggle="tooltip" title="Report"><img src="../../assets/sales_report.jpeg" alt="" width="15px" style="border-radius: 47px"> </router-link>
                    <!-- <router-link id="chats" class="active" to="/shop" data-toggle="tooltip" title="Shops"><i class="fa fa-building" aria-hidden="true"></i> </router-link>
                    <router-link id="chats" class="active" to="/brand" data-toggle="tooltip" title="Brands"><i class="fa fa-life-ring" aria-hidden="true"></i></router-link>
                    <router-link id="chats" class="active" to="/category" data-toggle="tooltip" title="Category"><i class="fa fa-crosshairs" aria-hidden="true"></i></router-link>
                    <router-link id="chats" class="active" to="/subcategory" data-toggle="tooltip" title="Sub Category"><i class="fa fa-object-group" aria-hidden="true"></i> </router-link>
                    <router-link id="chats" class="active" to="/product" data-toggle="tooltip" title="Products"><i class="fa fa-plus-circle" aria-hidden="true"></i> </router-link> -->
                    <router-link id="game" class="active" to="/shopping" data-toggle="tooltip" title="Shopping"><i class="fa fa-shopping-basket" aria-hidden="true"></i></router-link>
                    <!-- <router-link id="chats" class="active" to="/application-modules" data-toggle="tooltip" title="Application Modules"><i class="fas fa-toolbox"></i> </router-link> -->

               </span>
            </div>
            <div class="sidebar" id="sidebar" v-if="user_role == 2">
                <span class="window-close"  @click="showSidebar()"><i class="fa fa-window-close" aria-hidden="true" style="cursor:pointer"></i></span>
                    <router-link id="home" class="active" to="/home" data-toggle="tooltip" title="Home"><i class="fa fa-home"></i></router-link>
               <span>
                    <router-link id="game" class="active" to="/my-users" data-toggle="tooltip" title="Manage Users"><img src="../../assets/add_user.png" alt="" width="15px" style="border-radius: 47px"></router-link>
               </span>
            </div>
            <div class="sidebar" id="sidebar" v-if="user_role == 3">
                <span class="window-close"  @click="showSidebar()"><i class="fa fa-window-close" aria-hidden="true" style="cursor:pointer"></i></span>
                <router-link id="home" class="active" to="/home" data-toggle="tooltip" title="Home"><i class="fa fa-home"></i></router-link>
                <router-link id="game" class="active" to="/manage-profile" data-toggle="tooltip" title="Manage Profile"><img src="../../assets/add_user.png" alt="" width="15px" style="border-radius: 47px"></router-link>
                <router-link id="game" class="active" to="/manage-bank" data-toggle="tooltip" title="Manage Bank"><img src="../../assets/bank.png" alt="" width="15px" style="border-radius: 47px"></router-link>
                <router-link id="game" class="active" to="/manage-withdrawal" data-toggle="tooltip" title="Withdrawal"><img src="../../assets/card.png" alt="" width="15px" style="border-radius: 47px"></router-link>
                <router-link id="game" class="active" to="/shopping" data-toggle="tooltip" title="Shopping"><i class="fa fa-shopping-basket" aria-hidden="true"></i></router-link>
            </div>
        </div>
        <div class="col-md-9" style="height: 120vh">
            <router-view />
        </div>
        <div class="col-md-1">
        </div>
    </div>
</div>    
</template>
<script>
import swal from 'sweetalert2';
export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        myprofile: [],
        firstname: '',
        lastname: '',
        username: '',
        user_role: '',
        is_chat: 0,
        userid: '',
        company_name: '',
        company_address: '',
        app_logo: '',
    };
  },

  beforeMount(){
       
    },
    mounted() {

        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.username = localStorage.getItem("username");
        this.userid = localStorage.getItem("userid");
        this.company_name = localStorage.getItem("company_name");
        this.company_address = localStorage.getItem("company_address");
        this.app_logo = localStorage.getItem("app_logo");
    },
    created(){

    },


  methods: {
    showSidebar() {
        var x = document.getElementById("sidebar");
        if (x.style.display === "none") {
            x.style.display = "block";
            document.getElementById('btn').style.display='none'
            document.getElementById('logo').style.display='block'
        } else {
            x.style.display = "none";
            document.getElementById('btn').style.display='block'
            document.getElementById('logo').style.display='none'
        }
    },
   
    profile() {
        document.getElementById("profile").classList.add('profile-menu');
    },
    
    async listMyProfile(){
         try{
            const response = await this.$http.get('/profile-name', )
            this.myprofile = response.data.profile_name;
                      
            }catch (err) {
            console.log(err);
        }
    },
    async newMessages(){
         try{
            const response = await this.$http.get('/new-messages', )
            this.is_chat = response.data.chat_count;
                      
            }catch (err) {
            console.log(err);
        }
    },
    async logout() {

        var msg = confirm("                           You are about to log out. Are you sure?");
        if(msg==true){

            // clear jwt from session
            localStorage.removeItem('wertyuiojhdfghhdrtfyguh');
            this.response = await this.$http.post('/logout', {
                userid: this.userid,
            });

            swal.fire({
                icon: "success",
                title: "Logout",
                text: this.response.data.message,
                width:450,
                height:5,
            });

        this.$router.push('/');
        }
    }   
  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.background{
    margin-top: -20px;
    background: #00802f12;

    .bar {
        display: none;
    }
    .button {
        display: none;
      }
    .btn-close{
        display: none;
    }
    .window-close{
        display: none
    }
    .header {
        margin-top: 20px;
        background: #fff;
        display: flex;
        flex-direction: row;
        align-content: center;
        height: 70px;
        font-size: 12px;
        font-family: Mulish;
        font-weight: 400;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
        margin-bottom: 30px;   
        color: #00802f;
    
        .logo > img{
            width: 120px;
            height: 30px;
        }

        .e-waiter {
            font-weight: bold;
            color:#fff;
            text-shadow: 2px 1px #000;
        }

        .company-name {
            font-weight: bold;
            font-size: 14px;
            margin:10px;
            font-family: Mulish;
            text-transform:uppercase;
        }
        .bell-profile {
            display: flex;
            align-items: center;
            font-family: Mulish;
            font-size:10px;

            .bell {
                margin: 10px;
            }
            .fa-bell {
                color:#fff; 
                font-size:28px;
                margin-right:10px;
            }

            .dropdown-menu {
                background: #00802f;
                font-size: 12px;
                margin-left: -70px;
            }
        }
       
        .dropdown-menu > a {
            color:#FFF; 
        }
        .dropdown-menu > a:hover {
            color:#fbd43a; 
        }      
        .profile{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            width: auto;
            height: 35px;
            background: #00802f;
            border-radius: 82px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 28px;
            color: #FFFFFF;
            margin-top: -8px;
        }
     }

     .footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-top: 100px;
        margin-bottom: 20px;
        font-family: Mulish;
        box-shadow: 10px 4px 10px -3px rgba(0, 0, 0, 0.3);

        span{
            font-family: Mulish;
            font-size: 10px;
            font-weight: 800;
        }
    }
    @media (min-width:320px) and (max-width: 1200px) {
        .logo {
            display:none;
        }
        .bar {
            display: block;
            height: 5px;
            width: 40px;
            background-color: #00802f;
            margin: 6px auto;
           
          }
          .button {
            cursor: pointer;
            display: inline-block;
            width: auto;
            margin: 0 auto;
            -webkit-transition: all .7s ease;
            -moz-transition: all .7s ease;
            -ms-transition: all .7s ease;
            -o-transition: all .7s ease;
            transition: all .7s ease;
            margin-left: 0px;

          }
          .btn-close{
            margin-left: 25px;
            margin-top: -20px;
            .close{
                width: 42px;
                height: 40px;
            }

          }
          .bar {
            -webkit-transition: all .7s ease;
            -moz-transition: all .7s ease;
            -ms-transition: all .7s ease;
            -o-transition: all .7s ease;
            transition: all .7s ease;
          }
        .sidebar {
            padding: 4rem 1rem;
            //padding-left: -50px;
        }

    }
 
     @media (min-width:320px) and (max-width: 1200px) {
        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .logo {
                display: none;
            }
            .logo > img{
                width: 120px;
                height: 30px;
                display: none;
            }
            .e-waiter {
                font-weight: 800;
                color:#fff;
                text-shadow: 2px 1px #000;
                font-size: 16px;
            }
            .company-name {
                font-weight: bold;
                font-family: Mulish;
                margin-left: -25px;
                word-wrap:normal;
                text-transform: capitalize;
                font-size:2vw
            }
            .bell-profile {
                display: flex;
                align-items: center;
    
                .bell {
                    margin: 10px;
                }
                .fa-bell {
                    color:#fff; 
                    font-size:28px;
                    margin-right:10px;
                }
    
            }
            .dropdown-menu {
                width: 10px;
               // display: flex;
                flex-direction: row;
                align-items: center;
                height: 40px;
                color:#0e0606; 
                font-size: 10px;
                //margin-left: -60px;
            }
            .dropdown-menu > a {
                color:#FFF; 
            }
            .dropdown-menu > a:hover {
                color:#000; 
            }
            .dropdown-menu:hover {
                color:#0A070F; 
            }
          
            .profile{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: auto;
                height: 35px;
                background: #00802f;
                border-radius: 82px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 28px;
                color: #FFFFFF;
                margin-top: -8px;
            }
         }

         .footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 12px;
            margin:10px;

            span{
                font-family: Mulish;
                font-size: 10px;
                font-weight: 800;
            }
        }
        .sidebar {
            display:none;
        }
        .sidebar {
            height: 100vh;
            padding: 4rem 1rem;
        }
     }
     
     .sidebar {
        padding: 4rem 1rem;
        margin-left: 100px;
      }
      .sidebar a {
        display: block;
        color: white;
        padding: 16px;
        text-decoration: none;
      }
       
      .sidebar a.active {
        background-color: #0A070F;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
      }
      .sidebar a:hover{
        background-color: #21182f;
      }
      .profile-menu{
        background-color: #21182f;
      }
      .hangout a:hover {
        background-color: #FFFFFF !important;
      }
      .game a:hover {
        background-color: #FFFFFF !important;
      }
      
      @media screen and (max-width: 700px) {
        .sidebar {
          width: auto;
          height: auto;
          position: fixed;
          z-index: 9998;
          transition: opacity .4s ease;
          background-color: rgba(0, 0, 0, .5);
          border-radius: 5px;
          margin-left: 20px;
          .window-close{
            display: block;
            color: #FFF;
            margin-left: 15px;
          }
        }
        .sidebar a {float: left;}
        div.content {margin-left: 0;}
      }
      
      @media screen and (max-width: 400px) {
        .sidebar a {
          text-align: center;
          float: none;
        }
      }

}

@media screen and (max-width: 768px) { 
    .main{
        background: #0A070F;
        width: 100%;
        height: 100vh;    
    }
}
</style>