<template>
    <div>
        <p>&nbsp;</p>
        <p class="welcome"> <router-link to="/shopping"> <i class="fa fa-arrow-left"></i> Back </router-link> | <i class="fa fa-cubes"></i> Product</p>
        <div class="row account-details" v-if="addProduct">
          <div class="col-sm-2">  
          </div>
          <div class="col-sm-8">
            <div  @click="view_products" style="cursor:pointer">
              <u> View Product</u>
            </div>
         
            <form @submit.prevent="createBrand" enctype="multipart/form-data">
            <div class="inputs">
                <v-autocomplete
                  required
                  v-model="shop_name"
                  label="Shop *"
                  :items="shops"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                  required
                  v-model="brand_name"
                  label="Brand *"
                  :items="brands"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                  @input="selectCategory"
                  required
                  v-model="category_name"
                  label="Category *"
                  :items="categorys"
                  variant="outlined"
                ></v-autocomplete>
                <v-autocomplete
                required
                v-model="subcategory_name"
                label="Category *"
                :items="subcategorys"
                variant="outlined"
              ></v-autocomplete>
            </div>
            <div class="inputs">              
                <label for=""> Product name *</label>
                <input type="text" class="form-control inputs-field" placeholder="Enter product name" v-model="product_name" required>
            </div>
            <div class="inputs">              
                <label for=""> Product description *</label>
                <quill-editor class="quill-container" v-model="product_description" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
              <label for=""> Product key features </label>
              <quill-editor class="quill-container" v-model="product_keyfeature" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
              <label for=""> Product specification </label>
              <quill-editor class="quill-container" v-model="product_specification" :options="editorOptions"></quill-editor>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <div class="inputs">              
                <label for=""> Price *</label>
                <input type="text" class="form-control inputs-field" placeholder="Enter product price" v-model="price" required>
            </div>
            <div class="inputs">              
                <label for="">Browse image *</label>
                <input type="file" ref="fileInput" id="image_input"  class="file-input"  @change="pickFile"/>
            </div>
            </form>
           
            <div class="inputs">
                <button id="submit2" class="btn btn-danger btn-block" :disabled="isProductEnabled"  @click="createProduct()" v-if="!loading">save</button>
                <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
          </div>
          <div class="col-sm-2">
          </div>
        </div>
        <div class="row account-details" v-if="viewProduct">
            <div class="col-sm-1">  
            </div>
            <div class="col-sm-10">
              <div  @click="add_products" style="cursor:pointer">
                 <u>Add Product</u>
              </div>
              
              <div class="inputs table table-responsive">
                <h6>All Products</h6>
                <table class="shop-detail table table-bordered">
                  <thead>
                    <tr>
                      <th>Sn</th>
                      <th>Shop</th>
                      <th>Brand</th>
                      <th>Category</th>
                      <th>Sub Category</th>
                      <th>Product name</th>
                      <th>Price</th>
                      <th>Variations</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in products" :key="index">
                      <td>{{ index + 1}}</td>
                      <td> 
                          <span class="shop-name">{{ data.shop_name}}</span>
                      </td>
                      <td> 
                        <span class="shop-name">{{ data.brand_name}}</span>
                        </td>
                        <td> 
                            <span class="shop-name">{{ data.category_name}}</span>
                        </td>
                        <td> 
                            <span class="shop-name">{{ data.subcategory_name}}</span>
                        </td>
                        <td> 
                            <span class="shop-name">{{ data.product_name}}</span>
                        </td>
                        <td> 
                            <span class="shop-name">{{  new Intl.NumberFormat('en-NG', {
                              style: 'currency',
                              currency: 'NGN',
                            }).format(data.product_price) }}</span>
                        </td>
                        <td> <button class="btn btn-light btn-sm" @click="addVariations(data.productid)"><i class="fa fa-plus"></i> Add</button> </td>
                      <td style="cursor: pointer"><button @click="removeCategory(data.subcategoryid)"><i class="fa fa-trash"></i></button></td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="col-sm-1">
            </div>
        </div>
        <div v-if="isVariations">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                   Add variations
                                    <span class="add-fund">.</span>
                                </div>
                                <div class="account-content-d">
                                  <input type="hidden" class="form-control inputs-field" v-model="productid" required>
                                  <label for="">Variation </label>
                                  <input type="text" id="variation" class="form-control inputs-field" placeholder="Enter variations" v-model="variations" required>
                                  <label for="">Price</label>
                                  <input type="text" id="price" class="form-control inputs-field" placeholder="Enter price" v-model="vprice" >
                                </div>
                                <button id="submit2" class="btn btn-secondary"  @click="createVariation()" v-if="!is_submit">submit</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_submit">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button class="btn btn-default" @click="isVariations = false"><i class="fa fa-arrow-left"></i> back</button>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
  </template>
  <script>

  import 'quill/dist/quill.snow.css';
  import { quillEditor } from 'vue-quill-editor';
  import swal from 'sweetalert2';
  export default {
    
    name: "ProductPage",
    components: {
        quillEditor,
    },
  
    data(){
      return {
        
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
          shop_name: '',
          brand_name: '',
          category_name: '',
          subcategory_name: '',
          product_name: '',
          product_description: '',
          product_keyfeature: '',
          product_specification: '',
          price: '',
          response: '',
          subcategory: [],
          categorys:[],
          shops: [],
          brands: [],
          products: [],
          subcategorys:[],
          selectedFile: '',
          addProduct: false,
          viewProduct: true,
          editorOptions: {
            placeholder: 'Write something...',
        },
        isVariations: false,
        productid: '',
        variations: '',
        vprice: '',
        is_submit: false,
        
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.fetchcategorys()
        this.fetchProducts()
        this.fetchshops()
        this.fetchbrands()
    },
  
    created(){
          
    },
  
    computed: {
      isProductEnabled() {
        return (
            this.shop_name === '' ||
            this.brand_name === '' ||
            this.subcategory_name === '' ||
            this.category_name === '' ||
            this.product_name === '' ||
            this.product_description === '' ||
            this.price === ''
        );
      },
    },
  
    methods: {

      addVariations(productid){
          this.productid = productid
          this.isVariations = true
        },
        async createProduct() {
            try {

                    this.loading = true

                    const formData = new FormData();
                    formData.append('images', this.selectedFile),
                    formData.append('shop_id', this.shop_name),
                    formData.append('brand_id', this.brand_name),
                    formData.append('category_id', this.category_name),
                    formData.append('subcategory_id', this.subcategory_name),
                    formData.append('product_name', this.product_name),
                    formData.append('product_description', this.product_description),
                    formData.append('product_keyfeature', this.product_keyfeature),
                    formData.append('product_specification', this.product_specification),
                    formData.append('product_price', this.price),
                    formData.append('addedby',  this.user_id),

                     this.response = await this.$http.post('/create-product', formData
    
                    );
                    this.loading = false

                    if( !this.response.data.success ){
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
                        return;
                    }
                    else{
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: this.response.data.message,
                        })
 
                        this.shop_name = '' 
                        this.brand_name = ''
                        this.subcategory_name = ''
                        this.category_name = ''
                        this.product_name = ''
                        this.product_description = ''
                        this.product_keyfeature = ''
                        this.product_specification = ''
                        this.price = ''              
  
                        return;
                    }
            } catch (err) {
              console.log(err);
        }
        },
        async createVariation() {
            try {
               this.is_submit = true
               const response =  await this.$http.post('/create-variation', {
                product_id: this.productid,
                variation: this.variations,
                price: this.vprice,
                addedby: this.user_id
            })
              this.is_submit = false

            swal.fire({
                icon: 'success',
                title: 'Success',
                width: 450,
                height: 5,
                text: response.data.message,
            })
                 
            } catch (err) {
              console.log(err);
          }
        },

        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })
                response.data.categories.forEach(el => {
                    this.categorys.push({
                        text: el.category_name,
                        value: el.categoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchshops() {
            try {
               const response =  await this.$http.get('/fetch-all-shops', {
            })
                response.data.shops.forEach(el => {
                    this.shops.push({
                        text: el.shop_name,
                        value: el.shopid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
                response.data.brands.forEach(el => {
                    this.brands.push({
                        text: el.brand_name,
                        value: el.brandid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async selectCategory(){
            try {

                this.subcategorys = [] // reset list

               const response =  await this.$http.post('/fetch-all-subcategories-byid', {
                category_id: this.category_name
            })
                response.data.categories.forEach(el => {
                    this.subcategorys.push({
                        text: el.subcategory_name,
                        value: el.subcategoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchProducts() {
            try {
               const response =  await this.$http.get('/fetch-all-products', {
            })
                this.products = response.data.products
                    
            } catch (err) {
              console.log(err);
          }
        },

        async removeCategory(categoryid){
        let con = confirm("          You're about to remove this category. Are you sure?")
          if (con == true) {
            try {

              const response =  await this.$http.post('/delete-subcategory', {
                subcategory_id: categoryid,
              })

              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchProducts()
                return;

              } catch (err) {
              console.log(err);
              }
          }
        },

        pickFile() {
            this.selectedFile = this.$refs.fileInput.files[0];            
        },
        view_products(){
            this.viewProduct = true,
            this.addProduct = false
        },
        add_products(){
            this.viewProduct = false,
            this.addProduct = true
        }

    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .quill-container {
    height: 200px; /* Set the desired height here */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
}
.shop-detail{
    font-size: 12px;
    font-family: Mulish;
}
.btn-danger{
  text-transform: uppercase;
  font-size: 13px;
  font-family: Mulish;
  background: #071278;
  border: 0px;
  height: 45px;
}
  .welcome{
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    color: #170e0fac;
  }
  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.9rem;
    margin: 4px;
  }
  .account-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .wallet-detail{
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;
    h4{
      font-weight: 650;
    }
    .acct-d{
      line-height: 0.8;
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wallet-icon{
    font-size: 60px
  }
  .fund-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: Mulish;
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
  .content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box-icon{
    padding-left: 0.4rem;
    font-size: 35px;
    color:#637807;
  }
  .box-details{
    font-size: 14px;
    color:#170e0fac;
  }
  .box1:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box2:hover{
     transform: scale(1.1); 
     transition: transform 0.3s ease; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
   }
  .box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box3:hover{
      transform: scale(1.1); 
      transition: transform 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
      cursor: pointer;
   }
   .box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box4:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box5:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: Mulish;
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box6:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: Mulish;
      font-weight: 550;
      font-size: 10px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: Mulish;
      font-size: 12px;
    }
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  .add-fund{
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .btn-secondary{
    text-transform: uppercase;
  }
  
  .modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  }
  
  .modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  
    display: none;
  }
  
  a {
    text-decoration: none;
  }
  .shop-name{
    //font-weight: 550;
    color: #000;
    font-family: Mulish;
  }
  @media screen and (max-width: 768px) {
    .counter{
      font-size: 13px;
      font-weight: 500 !important;
    }
    .welcome{
      font-family: Mulish;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #170e0fac;
    }
    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.9rem;
    }
    .account-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px #170e0fac;
      border-radius: 4px;
      padding: 0.9rem;
      margin: 4px;
    }
    .account-balance{
      margin-bottom: 10px;
    }
  
    .box-details{
      padding: 0.4rem;
      line-height: 1.4px;
      .title{
        font-family: Mulish;
        font-weight: 550;
        font-size: 8px;
        text-transform: capitalize;
        color: #ff4d63;
      }
      .description{
        font-family: Mulish;
        font-size: 12px;
      }
    }
    
    .inputs{
      font-size: 12px;
    }
    .box1 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box2 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box3 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box4 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box5 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .continue{
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-right: 0.4rem;
      font-size: 30px;
      margin-top: -10px;
      color: rgb(237, 138, 108);
    }
  
    .modal-close-mobile {
      display: block;
    }
    .modal-close {
      display: none;
    }
  
    .account-content{ 
      margin: 10px;
      margin-right: 30px;
    }
  
  }
  
  </style>