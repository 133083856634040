<template>
    <div class="background">
            <div class="header">
                <span class="logo">
                   <router-link to="/home"> <img src="../../assets/arrow-back.png" alt="" width="30px"> </router-link>
                </span>
                <div class="contact">
                    <router-link to="/list-category" class="list-category"> <i class="fa fa-cogs "></i> List Category &nbsp;</router-link> 
                    <router-link to="/list-menu" class="list-menu"> <i class="fa fa-tasks"></i> List Menus&nbsp;</router-link> 
                    <router-link to="/list-purchases" class="list-purchase"> <i class="fa fa-shopping-cart"></i> List Purchases</router-link>
                </div>
            </div>
            <div class="tab">
                <div class="add-category" @click="addCategorys">
                    <i class="fa fa-cogs" aria-hidden="true"></i> Add Category
                </div>
                <div class="add-menus" @click="addMenus">
                    <i class="fa fa-tasks" aria-hidden="true"></i> Add Menus
                </div>
                <div class="add-purchases" @click="addPurchases">
                    <i class="fa fa-shopping-cart"></i> Add Purchases
                </div>
                <div class="add-qrcode" @click="generateQrcode">
                    <i class="fa fa-qrcode"></i> QR Code
                </div>
            </div>
            <div class="container-addmenu" v-if="addcategory">
                <div class="add-menu-items">
                    <h2>
                        Add Category
                    </h2>
                    <label class="categoty-name"> Category name:</label>
                    <input type="text" class="form-control input-text" v-model="category_name" placeholder="Enter category name" required>
                    <button class="btn-next" @click="add_category()" v-if="!loading1"><i class="fa fa-plus"></i> Add </button>
                    <button class="btn-next" type="button" disabled v-if="loading1">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Adding...
                    </button>
                </div>
            </div>
            <div class="container-addmenu" v-if="addmenu">   
                <div class="add-menu-items">
                    <h2>
                        Add Menus
                    </h2>
                  
                    <span>
                        <div class="row">
                            <label> Category</label>
                            <select class="form-control input-text" id="category" v-model="item_category" required>
                                <option value="">-Select Category-</option>
                                <option v-for="(data, index) in categories" :key="index" :value="data.id">{{ data.category_name}}</option>
                            </select>
                        </div>
                        <div class="row">
                            <label> Name</label>
                            <input type="text" class="form-control input-text" v-model="menuname" placeholder="Your menu name" required>
                        </div>
                        <div class="row">
                            <label for=""> Description</label>
                            <input type="text" class="form-control input-text" v-model="description" placeholder="Your menu description" required>
                        </div>
                        <div class="row">
                            <label for="">Cost Price</label>
                            <input type="text" class="form-control input-text" v-model="cost_price" placeholder="Item cost price">
                        </div>
                       
                        <div class="row">
                            <label for="">Selling Price</label>
                            <input type="text" class="form-control input-text" v-model="price" placeholder="Item selling price" required>
                        </div>

                        <div class="row">
                            <center>
                            <span v-if="previewImage == ''">
                                <div class="add-picture" >
                                    <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-0 overflow-hidden absolute" accept="image/png, image/jpg" style="cursor:pointer" @input="pickFile"/>
                                </div>
                            </span>
                            <span v-else>
                                <div class="add-picture" :style="{ 'background-image': `url(${previewImage})` }">
                                    <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-0 overflow-hidden absolute" accept="image/png, image/jpg" style="cursor:pointer" @input="pickFile"/>
                                    <!-- <span v-if="btn_success">
                                        <button class="btn btn-success" @click="uploadFile()"><i class="fa fa-plus"></i> upload</button>
                                    </span> -->
                                 </div>
                            </span>
                            </center>
                           
                        </div>
                      
                        <div class="row">
                            <button class="btn-next" @click="add_menu()" v-if="!loading2"><i class="fa fa-plus"></i> Add </button>
                            <button class="btn-next" type="button" disabled v-if="loading2">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Adding...
                            </button>
                        </div>
                    </span>
                </div>
            </div>
            <div class="container-addmenu" v-if="addpurchase">
                <div class="add-menu-items">
                    <h2>
                        Add Purchases
                    </h2>
                  
                    <span>
                        <div class="row">
                            <label> Category</label>
                            <select class="form-control input-text" id="category_id" v-model="category_id" required @change="getMenuByCategoryId()">
                                <option value="">-Select Category-</option>
                                <option v-for="(data, index) in categories" :key="index" :value="data.id">{{ data.category_name}}</option>
                            </select>
                        </div>
                        <div class="row">
                            <label for=""> Item</label>
                            <select class="form-control input-text" id="menu_id" v-model="menu_id" required>
                                <option value="">-Select Item-</option>
                                <option v-for="(data, index) in menubycategory" :key="index" :value="data.id">{{ data.title}} {{ data.description}}</option>
                            </select>
                        </div>
                        <div class="row">
                            <label for=""> Quantity</label>
                            <input type="text" class="form-control input-text" v-model="quantity" placeholder="Enter quantity purchased" required>
                        </div>
                      
                        <div class="row">
                            <button class="btn-next" @click="add_purchase()" v-if="!loading3"><i class="fa fa-plus"></i> Add </button>
                            <button class="btn-next" type="button" disabled v-if="loading3">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Adding...
                            </button>
                        </div>
                    </span>
                </div>
            </div>
            <div class="container-addmenu" v-if="qrcode">
                <div class="add-menu-items" style="display: flex; flex-direction: column; align-content: center"> 

                    <p>Download this qrcode and print it on your menu card for your customers to scan.</p> 
                    <center>
                        <button style="margin-top: 4px; font-size: 10px;" class="btn btn-warning btn-sm" @click="downloadQrcode"><i class="fa fa-download"></i> Download</button>
                    </center>             
                </div>
                <div class="add-menu-items">
                    <h3>
                       Menu QRCode
                    </h3>
                  
                    <span>
                        <div class="row" ref="printcontent" style="padding: 10px;">
                            <qr-code 
                                :text="qrcode_value"
                                size="250"
                                color="#fff"
                                bg-color="#000" 
                                error-level="L">
                            </qr-code>                        
                        </div>
                    </span>
                </div>
               
            </div>
    </div>
    </template>
    <script>

    import Vue from 'vue'
    import swal from 'sweetalert2';
    import VueQRCodeComponent from 'vue-qrcode-component'
    import html2canvas from 'html2canvas';
    Vue.component('qr-code', VueQRCodeComponent)
    
    export default {
       
    data() {
      return {
        loading1: false,
        loading2: false,
        loading3: false,
        addcategory: true,
        addmenu: false,
        addpurchase: false,
        qrcode: false,
        response: '',
        userid: '',
        options: [],
        item_category: '',
        menuname: '',
        description: '',
        cost_price:'',
        price: '',
        previewImage:'',
        categories: [],
        category_name: '',
        category_id: '',
        menu_id: '',
        quantity: '',
        menubycategory: [],
        qrcode_value: '',
        company_website: '',
    
      };
    },
    
    beforeMount(){
        
    },
    
    mounted(){
       
        this.app_role = localStorage.getItem("app_status");
        this.cfirstname = localStorage.getItem("firstname");
        this.clastname = localStorage.getItem("lastname");
        this.cusername = localStorage.getItem("username");
        this.userid = localStorage.getItem("userid");
        this.company_website = localStorage.getItem("company_website");
        this.qrcode_value  = this.company_website + "/menu-list"
        this.getMenu();
        this.getCategory();
    },
    created() {
    //this.getMenu();
   // this.getCategory();

    // Set an interval to call fetchAllOrders() every 5 seconds
    this.timerInterval = setInterval(this.getMenu, 5000);
   // this.timerInterval2 = setInterval(this.getCategory, 5000);
    },
    beforeDestroy() {
        // Clear the interval when the component is about to be destroyed
        clearInterval(this.timerInterval);
       // clearInterval(this.timerInterval2);
    },
    
    methods: {
        async downloadQrcode() {

            const el = this.$refs.printcontent;

            const options = {
                type: "dataURL",
            };
            const printCanvas = await html2canvas(el, options);

            const link = document.createElement("a");
            link.setAttribute("download", "qrcode.png");
            link.setAttribute(
                "href",
                printCanvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream")
            );
            link.click();

        },

        addMenus() {
            this.getCategory()
            this.addmenu = true,
            this.addcategory = false,
            this.addpurchase = false,
            this.qrcode = false
        },
        addCategorys() {
            this.addcategory = true,
            this.addmenu = false,
            this.addpurchase = false,
            this.qrcode = false
        },
        addPurchases() {
            this.getCategory()
            this.addpurchase = true,
            this.addcategory = false,
            this.addmenu = false,
            this.qrcode = false
        },
        generateQrcode(){
            this.qrcode = true,
            this.addpurchase = false,
            this.addcategory = false,
            this.addmenu = false
        },
        async pickFile() {
            let input = this.$refs.fileInput;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                this.previewImage = e.target.result;
                };
                reader.readAsDataURL(file[0]);
                this.$emit("input", file[0]);  
                this.btn_success = true;    
            }
        },
        async add_category() {
            try{

                if (!this.category_name ) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width:450,
                        height:5,
                        text: 'Please enter category name',
                    })
                    return;
                }

                                              
                    this.loading1 = true;

                    this.response = await this.$http.post('/create-category', {
                        category_name: this.category_name,
                        userid: this.userid,
                    });

                        this.loading1 = false;

                        if (!this.response.data.success) {

                            swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: this.response.data.message,
                                });
                                return;

                        } else {

                        swal.fire({
                            icon: 'success',
                            width:450,
                            height:5,
                            title: "Success",
                            text: this.response.data.message,
                        });

                        this.category_name = "";                                    
                        return;

                    }      
                
            } catch (err) {
                console.log(err);
            }
        },
        async add_menu() {
            try{

                if (!this.item_category) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width:450,
                        height:5,
                        text: 'Please select category',
                    })
                    return;
                }

                if (!this.menuname ) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width:450,
                        height:5,
                        text: 'Please enter menu title ',
                    })
                    return;
                }
                
                if (!this.price ){
                
                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width:450,
                    height:5,
                    text: 'Please enter price ',
                })
                return;
            }
                                
                    this.loading2 = true;

                    this.response = await this.$http.post('/add-menu', {
                        category: this.item_category,
                        menutitle: this.menuname,
                        description: this.description,
                        cost_price: this.cost_price,
                        price: this.price,
                        created_by: this.userid,
                        image: this.previewImage,
                    });

                        this.loading2 = false;

                        if (!this.response.data.success) {

                            swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: this.response.data.message,
                                });
                                return;

                        } else {

                        swal.fire({
                            icon: 'success',
                            width:450,
                            height:5,
                            title: "Success",
                            text: this.response.data.message,
                        });

                        this.item_category = "";
                        this.menuname = "";
                        this.description = "";
                        this.cost_price = "";
                        this.price = "";      
                        this.previewImage = '';                                     
                        return;

                    }      
                
            } catch (err) {
                console.log(err);
            }
        },
        async add_purchase() {
            try{

                if (!this.category_id ) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width:450,
                        height:5,
                        text: 'Please select category name',
                    })
                    return;
                }
                if (!this.menu_id ) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width:450,
                        height:5,
                        text: 'Please select menu name',
                    })
                    return;
                }
                if (!this.quantity ) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width:450,
                        height:5,
                        text: 'Please enter quantity',
                    })
                    return;
                }

                                              
                    this.loading1 = true;

                    this.response = await this.$http.post('/add-purchase', {
                        category_id: this.category_id,
                        menu_id: this.menu_id,
                        quantity: this.quantity,
                        userid: this.userid,
                    });

                        this.loading1 = false;

                        if (!this.response.data.success) {

                            swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: this.response.data.message,
                                });
                                return;

                        } else {

                        swal.fire({
                            icon: 'success',
                            width:450,
                            height:5,
                            title: "Success",
                            text: this.response.data.message,
                        });

                        this.category_id = "";  
                        this.menu_id = "";
                        this.quantity = "";                                  
                        return;

                    }      
                
            } catch (err) {
                console.log(err);
            }
        },
        async getMenu() {
         try{
            const response = await this.$http.get('/all-menus', )
            this.options = response.data.menu;    
                        
            }catch (err) {
                console.log(err);
            }
        },
        async getCategory() {
         try{
            const response = await this.$http.get('/fetch-category', )
            this.categories = response.data.categories;    
                        
            }catch (err) {
                console.log(err);
            }
        },
        async getMenuByCategoryId() {
         try{
            const response = await this.$http.post('/fetch-menus-bycategory', {
                category_id: this.category_id,
            })
            this.menubycategory = response.data.menus;    
                        
            }catch (err) {
                console.log(err);
            }
        },
              
      }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

    a {
        text-decoration: none;
    }
    body {
        background: #fbd43a !important;
    }
    .tab{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        font-family: Mulish;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        background: red;
        width: 95%;
        height: 25px;
        margin-top: 10px;
        padding: 10px;
        border-radius: 2px;
    }
    .container-addmenu{
        background: transparent;
        display:flex;
        flex-direction: row;
        justify-content:space-evenly;
        align-items: center;
        box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
        width:95%
        
    }
    .add-menu-items{
        display:flex;
        flex-direction: column;
        justify-content: left;
        width: 45%;

        p{
            margin-top: 30px;
            font-weight:700;
            font-size:14px;
        }
        label{
            text-align: left !important;
            color:#000 !important;
            font-size:10px;
        }
        .form-control{
           
        }
    }
    .add-category{
        color: #FFF;
    }
    .add-category:hover{
      border-bottom: solid 2px #000;
      cursor: pointer;
    }

    .add-menus{
        color: #FFF;
    }
    .add-menus:hover{
        border-bottom: solid 2px #000;
        cursor: pointer;
    }

    .add-purchases{
        color: #FFF;
    }
    .add-purchases:hover{
        border-bottom: solid 2px #000;
        cursor: pointer;
    }

    .add-qrcode{
        color: #FFF;
    }
    .add-qrcode:hover{
        border-bottom: solid 2px #000;
        cursor: pointer;
    }
    .add-picture {
        margin-top: 10px;
        background: url("../../assets/photo_add.png");
        background-size: cover;
        width: 140px;
        height: 150px;
        border-radius: 15px;
        padding-bottom: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
    }
    .password-check{
            margin-top:10px;
            width: 100%!important;
            font-size: 10px;
            font-family: Mulish;
            .five-char {
                color: red
            }
            .uppercase{
                color: red
            }
            .number{
                color: red
            }
            .special-char{
                color: red
            }
            .password-match{
                color: red
            }
        }
    .modal-window {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        &:target {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
        & > div {
          width: 680px;
          height: 400px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2em;
          position: absolute;
          width: 372px;
          height: 420px;
          background: #19181B;
          border-radius: 50px;
        }
        h1 {
          font-size: 150%;
          font-family: Mulish;
          margin: 0 0 15px;
        }
        h2 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
            font-family: Mulish;
        }
        .welcome {
            margin-top: 15px;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
            font-family: Mulish;
        }
        label {
            text-align: left;
            color: #fff;
            font-size: 12px;
            margin-left:15px;
            margin-bottom: -6px;
            font-family: Mulish;
        }
        span {
            margin-bottom: 20px;
        }
    
      }
      
      .modal-close {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
        font-family: Mulish;
        &:hover {
          color: black;
        }
      }
      .modal-close-mobile {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
        font-family: Mulish;
        &:hover {
          color: black;
        }
        display: none;
      }
      .modal-window {
        & > div {
            border-radius: 63px;
        }
      }
      .modal-logo {
        text-align: center;
        font-family: Mulish;
      }
      .modal-logo > img {
        width: 100px;
        height: 100;
    }
    h2 {
       
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        line-height: 190%;
        margin-top: 20px;
        
    
    }
    .modal-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 19px;
        width: 380px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 50px;
        margin: 3%;
        cursor: pointer;
        font-family: Mulish;
    }
    .login-modal-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 311px;
        width: 496px;
        height: 45px;
        background: #0A070F;
        border: 0.5px solid #FFFFFF;
        border-radius: 50px;
        margin: 10px;
        color: #767475;
        font-family: Mulish;
    }
    .btn-proceed {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 250px;
        height: 40px;
        background: #D33E74;
        border-radius: 82px;
        color: #FFFFFF;
        margin-left: -10px;
        font-family: Mulish;
    }
    .background{
        background: #fbd43a;
        width: 100%;
    
    
            .card{
                margin: 6px;
                width: 100%;
                height: auto;
                padding: 4px;
                background: transparent;
                font-size: 12px;
                border: none;
                color: #000;

                img{
                    width: 250px;
                }
            }
            
    
            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 30px;
                line-height: 42px;
                text-align: left;
                color: #000;
            }
            p {
                font-family: Mulish;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                color: #000;
            }     

            span {
                .row{
                    label {
                        color: #000;
                        text-align: left;
                    }
                }

               
            }
    
            .signup-toggle {
                width: 100%;
                height: 80px;
                background: #1C1B1E;
                border-radius: 50px;
                display: flex;
                flex-direction: row;
                padding: 15px;
                margin-bottom: 50px;
                cursor: pointer;
                font-size: 11px;
                font-family: Mulish;
    
                .personal-info {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 51px;
                    background: #D33E74;
                    border-radius: 50px;
                    color:#FFFFFF;
                    font-size: 12px;
                    font-family: Mulish;
                }
            }
            .form-group {
                margin: 4px;
            }
            .input-text {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                width: 100%;
                height: auto;
                background: #FFF;
                border: 0.5px solid #FFFFFF;
                border-radius: 4px;
                color: #000;
                font-size:11px;    
                font-family: Mulish;
            }
        
            input:focus {
                border: 0.5px solid #D33E74;
            }
            select:focus {
                border: 0.5px solid #D33E74;
            }
            textarea:focus {
                border: 0.5px solid #D33E74;
            }
            option:focus {
                border: 0.5px solid #D33E74;
            }
            .input-select {
                box-sizing: border-box;
                align-items: center;
                padding: 10px 40px;
                width: 110%;
                height: auto;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                font-family: Mulish;
                color: #767475;
                font-size:11px;
            }
            label {
                text-align: left;
                color: #FFFFFF;
                margin-left:4px;
                margin-top: 10px;
                font-family: Mulish;
            }
    
           
            .gender {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: auto;
                background: #fbd43a;
                font-family: Mulish;
                border-radius: 10px;
                color: #000;
                font-size:11px;
            }
        
            .location {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                gap: 202px;
                width: 100%;
                height: auto;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                color: #767475;
                font-size:11px;
                font-family: Mulish;
            }
            .btn-next {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: 100%;
                height: 30px;
                background: #000;
                border-radius: 4px;
                margin-top: 20px;
                margin-bottom: 40px;
                color:#FFFFFF;
                font-family: 'Source Sans 3';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                font-family: Mulish;
            }
            .btn-done {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 435px;
                height: 50px;
                background: #D33E74;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color:#FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
        
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            background: #000;
            width: 95%;
            padding: 10px;
            border-radius: 4px;
            font-family: Mulish;
        
            .logo > img{
                width: 150px;
                height: 30px;
            }
            .contact{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: auto;
                font-weight: 400;
                font-size: 10px;
                color: #fff !important;
                line-height: 0px;
                font-family: Mulish;

                .list-category:hover {
                    text-decoration: underline;
                }
                .list-menu:hover {
                    text-decoration: underline;
                }
                .list-purchase:hover {
                    text-decoration: underline;
                }
            }
           
            a{
                color: #fff !important;
            }
         }

    }
    @media screen and (max-width: 768px) {

        .tab{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            font-family: Mulish;
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            background: red;
            width: 95%;
            height: 25px;
            margin-top: 10px;
            padding: 10px;
            border-radius: 2px;
            margin: 8px;
        }

        .container-addmenu{
            background: transparent;
            display:flex;
            flex-direction: column;
            justify-content:space-evenly;
            align-items: center;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width:95%;
            margin-left: 8px;
            
        }
        .add-menu-items{
            display:flex;
            flex-direction: column;
            justify-content: left;
            width:80% !important;
        }

        .modal-window {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s;
            &:target {
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            }
            & > div {
              width: 680px;
              height: 490px;
              position: absolute;
              top: 50%;
              left: 53%;
              transform: translate(-50%, -50%);
              padding: 2em;
              position: absolute;
              width: 300px;
              height: 400px;
              background: #19181B;
              border-radius: 50px;
            }
          
            h1 {
              font-size: 150%;
              margin: 0 0 15px;
            }
            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                text-align: center;
                color: #FFFFFF;
            }
            .welcome {
                margin-top: 15px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                color: #FFFFFF;
            }
            label {
                text-align: left;
                color: #fff;
                font-size: 12px;
                margin-left:15px;
                margin-bottom: -6px;
                font-family: Mulish;
            }
            span {
                margin-bottom: 20px;
            }
        
        }
          
          .modal-close {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            font-family: Mulish;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
          }
          .modal-close-mobile {
            color: #fff;
            line-height: 50px;
            font-family: Mulish;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
            display: none;
          }
          .modal-window {
            & > div {
                border-radius: 63px;
            }
          }
          .modal-logo > img {
            margin-top: -30px;
            width: 120px;
            height: 120;
        }
        h2 {
           
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            line-height: 190%;
            margin-top: 20px;
        
        }
        .modal-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 19px;
            width: 380px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 50px;
            margin: 3%;
            cursor: pointer;
        }
        .login-modal-box {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 311px;
            width: 496px;
            height: 45px;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 50px;
            margin: 10px;
            font-family: Mulish;
            color: #767475;
        }
        .btn-proceed {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 235px;
            height: 35px;
            background: #D33E74;
            border-radius: 82px;
            color: #FFFFFF;
            margin-left: -10px;
            font-size: 13px;
            font-family: Mulish;
        }
    }
    
    @media screen and (max-width: 768px) {
    
        .background{
            background: #fbd43a;
            width: 100%;
            height: auto;

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                width: 100%;
                background: #000;
                font-family: Mulish;
            
                .logo > img{
                    width: 40%;
                }
                .contact {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-family: Mulish;
                    padding: 10px;
                    width: auto;
                    font-weight: 400;
                    font-size: 10px;
                    color: #000;
                    line-height: 0px;
    
                }    
            }
    
        }

      
            .container{
                background: transparent;
                width: 100%;
                height: 100%;
                display:flex;
                flex-direction: column;
                justify-content:space-evenly;
                padding: 30px;
                font-family: Mulish;

                .card {
                    
                    img{
                        margin-top: -100px;
                        width: 50%;

                    }
                }
        
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 42px;
                    text-align: left;
                    color: #000;
                }
                p {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12.5px;
                    line-height: 23px;
                    text-align: center;
                    color: #000;
                }     
        
                .form-group {
                    margin: 4px;
                }
                .input-text {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 30px;
                    width: 100%;
                    height: 40px;
                    background: #fff;
                    border: 0.5px solid #fff;
                    border-radius: 10px;
                    font-family: Mulish;
                }
                input:focus {
                    border: 0.5px solid #D33E74;
                }
                select:focus {
                    border: 0.5px solid #D33E74;
                }
                textarea:focus {
                    border: 0.5px solid #D33E74;
                }
                option:focus {
                    border: 0.5px solid #D33E74;
                }
                .birthday {
                    margin-left: -30px;
                }
                .input-select {
                    box-sizing: border-box;
                    align-items: center;
                    padding: 10px 40px;
                    width: 100%;
                    height: 40px;
                    background: #fff;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 10px;
                    margin: 2px;
                }
                label {
                    text-align: left;
                    color: #000;
                    margin-left:4px;
                    margin-top: 10px;
                    font-size:12px;
                    font-family: Mulish;
                }
                   .btn-next {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 42px;
                    background: #000;
                    border-radius: 82px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    color:#FFFFFF;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;

                }
                .btn-done {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 42px;
                    background: #D33E74;
                    border-radius: 82px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    margin-left: -10px;
                    color:#FFFFFF;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;
    
                }
        
        }
    }
    
    </style>