import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "../views/index/IndexPage.vue"
import VerifyEmail from "../views/index/VerifyEmail.vue"
import SignupPage from "../views/signup/SignupPage.vue"
import DashboardPage from "../views/dashboard/DashboardPage.vue"
import AdminPage from "../views/dashboard/AdminPage.vue"
import AddUserPage from "../views/dashboard/AddUserPage.vue"
import AddMenuPage from "../views/dashboard/AddMenuPage.vue"
import ViewUserPage from "../views/dashboard/ViewUserPage.vue"
import ReceiptsPage from "../views/dashboard/ReceiptsPage.vue" 
import ReportPage from "../views/dashboard/ReportPage.vue"
import ManageTransactionPage from "../views/dashboard/ManageTransactionPage.vue" 
import UsersPage from "../views/dashboard/UsersPage.vue"
import EditMemberPage from "../views/dashboard/EditMemberPage.vue"
import SetupPage from "../views/dashboard/SetupPage.vue"
import SetupPackageDuration from "../views/dashboard/SetupPackageDuration.vue"
import ApplicationModulesPage from "../views/dashboard/ApplicationModulesPage.vue"
import SavingsBreakdownPage from "../views/dashboard/SavingsBreakdownPage.vue"
import InvestmentBreakdownPage from "../views/dashboard/InvestmentBreakdownPage.vue"
import FixedDepositPage from "../views/dashboard/FixedDepositPage.vue"
import AllSavingsPage from "../views/dashboard/AllSavingsPage.vue"
import WalletPage from "../views/dashboard/WalletPage.vue"
import ManageProfile from "../views/dashboard/ManageProfile.vue"
import ManageBank from "../views/dashboard/ManageBank.vue"
import MyUsersPage from "../views/dashboard/MyUsersPage.vue"
import MyUsersWallet from "../views/dashboard/MyUsersWallet.vue"

// ECOMMERCE COMPONENTS
import ShopPage from '../views/ecommerce/ShopPage.vue'
import BrandPage from '../views/ecommerce/BrandPage.vue'
import CategoryPage from '../views/ecommerce/CategoryPage.vue'
import SubCategoryPage from '../views/ecommerce/SubCategoryPage.vue'
import ProductPage from '../views/ecommerce/ProductPage.vue'
import MyProduct from '../views/ecommerce/MyProductPage.vue'
import ShoppingPage from '../views/ecommerce/ShoppingPage.vue'
import ShoppingDashboard from '../views/ecommerce/ShoppingDashboard.vue'
import ProductDetailPage from '../views/ecommerce/ProductDetailPage.vue'
import CartPage from '../views/ecommerce/CartPage.vue'
import PaymentDashboard from '../views/ecommerce/PaymentDashboard.vue'
import CheckoutDashboard from '../views/ecommerce/CheckoutDashboard.vue'
import PaymentPage from '../views/ecommerce/PaymentPage.vue'
import CheckoutPage from '../views/ecommerce/CheckoutPage.vue'

// FORGOT PASSWORD
import ForgotPassword from '../views/index/ForgotPassword.vue'
import ResetPassword from '../views/index/ResetPassword.vue'

Vue.use(VueRouter);
// parse jwt token
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
  }

function beforeRouteEnter (to, from, next) {
  try{
  if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
    const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
    if (jwtPayload.exp < Date.now()/1000) {
        // token expired
        //deleteTokenFromLocalStorage();
        next("/");
    }
    next();
  } else {
    next("/");
  }
  }catch (err) {
    console.log(err);
  }
}

/*
function guardMyroute(to, from, next)
{
    var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
    if(localStorage.getItem('wertyuiojhdfghhdrtfyguh'))
      isAuthenticated = true;
    else
      isAuthenticated= false; if(isAuthenticated) 
    {
      next(); // allow to enter route
    } 
    else
    {
      next('/'); // go to '/login';
    }
    //next("/business-card");
}
*/

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/verify-email/:id',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/sign-up',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },  
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },  
  {
    path: '/home',
    name: 'DashboardPage',
    component: DashboardPage,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/home',
        name: 'AdminPage',
        beforeEnter : beforeRouteEnter,
        component: AdminPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/add-staff',
        name: 'AddUserPage',
        beforeEnter : beforeRouteEnter,
        component: AddUserPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/setup-packages',
        name: 'SetupPage',
        beforeEnter : beforeRouteEnter,
        component: SetupPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/setup-package-duration',
        name: 'SetupPackageDuration',
        beforeEnter : beforeRouteEnter,
        component: SetupPackageDuration,
        meta: {
          secure:true,
        }
      },
      {
        path: '/add-menu',
        name: 'AddMenuPage',
        beforeEnter : beforeRouteEnter,
        component: AddMenuPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/list-staff',
        name: 'ViewUserPage',
        beforeEnter : beforeRouteEnter,
        component: ViewUserPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/receipts',
        name: 'ReceiptsPage',
        beforeEnter : beforeRouteEnter,
        component: ReceiptsPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/manage-transactions',
        name: 'ManageTransactionPage',
        beforeEnter : beforeRouteEnter,
        component: ManageTransactionPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/view-all-fixed-deposits',
        name: 'FixedDepositPage',
        beforeEnter : beforeRouteEnter,
        component: FixedDepositPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/view-all-savings/:savings_typeid',
        name: 'AllSavingsPage',
        beforeEnter : beforeRouteEnter,
        component: AllSavingsPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/manage-users',
        name: 'UsersPage',
        beforeEnter : beforeRouteEnter,
        component: UsersPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/view-more/:memberid/:savingid',
        name: 'SavingsBreakdownPage',
        beforeEnter : beforeRouteEnter,
        component: SavingsBreakdownPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/more/:investmentid',
        name: 'InvestmentBreakdownPage',
        beforeEnter : beforeRouteEnter,
        component: InvestmentBreakdownPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/edit-member/:memberid',
        name: 'EditMemberPage',
        beforeEnter : beforeRouteEnter,
        component: EditMemberPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/report',
        name: 'ReportPage',
        beforeEnter : beforeRouteEnter,
        component: ReportPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/application-modules',
        name: 'RoomReservationPage',
        beforeEnter : beforeRouteEnter,
        component: ApplicationModulesPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/view-wallet/:memberid',
        name: 'WalletPage',
        beforeEnter : beforeRouteEnter,
        component: WalletPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/manage-profile',
        name: 'ManageProfile',
        beforeEnter : beforeRouteEnter,
        component: ManageProfile,
        meta: {
          secure:true,
        }
      },
      {
        path: '/manage-bank',
        name: 'ManageBank',
        beforeEnter : beforeRouteEnter,
        component: ManageBank,
        meta: {
          secure:true,
        }
      },
      {
        path: '/my-users',
        name: 'MyUsersPage',
        beforeEnter : beforeRouteEnter,
        component: MyUsersPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/myusers-wallet/:memberid',
        name: 'MyUsersWallet',
        beforeEnter : beforeRouteEnter,
        component: MyUsersWallet,
        meta: {
          secure:true,
        }
      }, 

      // E-COMMERCE ROUTES
      {
        path: '/shop',
        name: 'ShopPage',
        beforeEnter : beforeRouteEnter,
        component: ShopPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/brand',
        name: 'BrandPage',
        beforeEnter : beforeRouteEnter,
        component: BrandPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/category',
        name: 'CategoryPage',
        beforeEnter : beforeRouteEnter,
        component: CategoryPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/subcategory',
        name: 'SubCategoryPage',
        beforeEnter : beforeRouteEnter,
        component: SubCategoryPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/product',
        name: 'ProductPage',
        beforeEnter : beforeRouteEnter,
        component: ProductPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/myproduct',
        name: 'MyProduct',
        beforeEnter : beforeRouteEnter,
        component: MyProduct,
        meta: {
          secure:true,
        }
      },      
    ]
  },

  {
    path: '/shopping',
    name: 'ShoppingDashboard',
    component: ShoppingDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/shopping',
        name: 'ShoppingPage',
        beforeEnter : beforeRouteEnter,
        component: ShoppingPage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/item/:id/:title',
        name: 'ProductDetailPage',
        beforeEnter : beforeRouteEnter,
        component: ProductDetailPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/cart',
        name: 'CartPage',
        beforeEnter : beforeRouteEnter,
        component: CartPage,
        meta: {
          secure:true,
        }
      },      
    ]
  },

  {
    path: '/checkout/summary',
    name: 'CheckoutDashboard',
    component: CheckoutDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/checkout/summary',
        name: 'CheckoutPage',
        beforeEnter : beforeRouteEnter,
        component: CheckoutPage,
        meta: {
          secure:true,
        }
      },       
    ]
  },

  {
    path: '/checkout/payment/review',
    name: 'PaymentDashboard',
    component: PaymentDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/checkout/payment/review',
        name: 'PaymentPage',
        beforeEnter : beforeRouteEnter,
        component: PaymentPage,
        meta: {
          secure:true,
        }
      },       
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
