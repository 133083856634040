<template>
    <div class="background">
        <div class="bg-content">
            <div class="row">
                <div class="col-md-12">
                    <br>
                    <div class="menu-bar">
                        <img src="../../assets/icon5.png" alt="" width="40px"> <h2>&nbsp; My Fixed Deposits</h2>
                    </div>
                </div>
            </div>

            <h6 style="font-size:12px; font-weight:bold"></h6>
            <div class="main-content">
                <div class="view-content">
                    <p style="font-size:10px; font-weight:bold; line-height: 0px"><router-link to="/home"><i class="fa fa-arrow-left"></i> Back</router-link></p> 
                    <table id="myTable" class="table table-bordered table-striped">
                        <thead>
                            <tr style="font-size:9px">
                                <th>SN</th>
                                <th>Package</th>
                                <th>Principal</th>
                                <th>Rate</th>
                                <th>Duration</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Month</th>
                                <th>Year</th>
                                <th>Total Earning </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in allInvestments" :key="index" style="font-size:9px">
                                <td style="font-weight: 400; width:4%">
                                    {{ index + 1 }}
                                </td>
                                <td style="font-weight: 400; width:20%">
                                    {{ data.category_name }} 
                                </td>
                                <td style="font-weight: 400; width:10%">
                                    NGN {{ data.investment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }} 
                                </td>
                                <td style="font-weight: 400; width:4%">
                                    {{ data.rate }}%
                                </td>
                                <td style="font-weight: 400; width:10%">
                                    {{ data.title }}
                                </td>
                                <td style="font-weight: 400; width:20%">
                                    {{  new Date(data.date).toDateString() }}
                                </td>
                                <td style="font-weight: 400; width:20%">
                                    {{ new Date(data.end_date).toDateString()}}
                                </td>
                                <td style="font-weight: 400; width:8%">
                                    {{ data.month }}
                                </td>
                                <td style="font-weight: 400; width:10%">
                                    {{ data.year }}
                                </td>
                                <td style="font-weight: 400; width:30%">
                                    NGN {{ data.accumulatedSum.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
    </template>
    <script>
    //import swal from 'sweetalert2';
    //import Pusher from 'pusher-js';
    //import PaystackPop from '@paystack/inline-js';
   // import VueApexCharts from 'vue-apexcharts';

    export default {
       // components: { apexchart: VueApexCharts,},
        
    data() {

    return {
      
        users: [],
        options: [],
        user_role: '',
        today_orders: 0,
        today_receipts: 0,
        staff_users: 0,
        member_users: 0,
        total_menus: 0,
        monthly_orders: [],
        monthly_receipts: [],
        days: [],
        ndays: [],
        mytoday_receipts: 0,
        total_menus_sold: 0,
        myorder_processing: 0,
        customer_items: [],
        total_amount: '',
        investment_modal: '',
        savings_modal: false,
        new_order: [],
        notifications: [],
        pusher_app_key: '',

        investments: [],
        investment_package: '',
        investment_amount: '',
        durations: [],
        isDuration: false,
        package_duration: '',
        client_account_number: '',
        isAmount: false,
        totalDeposit: '',
        totalWithdrawal: '',
        totalWalletAmount: '',
        isActiveInvestment: '',
        balanceEnough: false,
        loading: false,
        allInvestments: [],
        totalSavings: [],
        saving_type: [],
        saving_package: '',
        saving_amount: '',
        saving_duration: '',
        modes: [],
        isSavingAmount: false,
        isSavingDuration: false,
        balanceSavingEnough: false,
        isActiveSavings: '',

        total_savings: 0,
        total_investments: 0,
        payment_status: '',
        user_email: '',
        paystack_key: '',
        payment_ref: '',
        activation_fee: process.env.VUE_APP_FEE,
                    
        }
    },
    
        beforeMount(){
            
            
        },
        mounted() {
            
            this.user_role = localStorage.getItem("user_role");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");

            this.fetchMyAccumulatedInvestmentAmount(this.userid)
        },
        created() {
            
        },
        computed: {

            myallInvestments(){
                return this.allInvestments.reduce((accumulator, data) =>  accumulator + data.investment_amount, 0);
            },
        },
    
        methods: {
                       
            async fetchMyAccumulatedInvestmentAmount(member_id){
                try {
                        const response = await this.$http.post('/fetchall-members-investments', {
                            memberid: member_id
                        })

                        this.allInvestments = response.data.allInvestments;
                        
                } catch (err) {
                    console.log(err);
                }
            },
        },
        
    }
    </script>
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

    .background{
        display: flex;
        flex-direction: row;
        justify-content: center;
        background:#ccc;
        border-radius: 10px;
    }
    .bg-content{
        padding: 10px;
        padding-right: 20px;
        width: 100%;
    }

    .main-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .view-content {
        width: 100%;
        overflow-x: auto;
        overflow-y: auto;
        height: 320px;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
        padding: 10px
    }

    .notifications{
        width: 30%;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: #fff;
        font-size:8px;
        font-weight: 650;
        font-family: Mulish;
        overflow-x: auto;
        overflow-y: auto;  
        height: 320px;
        background: #fff;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
        
        .notification-header {
            width: 100%;
            background: #000;
            padding: 5px;
            border-radius:2px;
        }
        .notification-content{
            padding:6px;
        }

    }
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #2b0c178c;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }

    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #fff;
        }
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #FFF;
        }
    
        display: none;
    }

    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #2b0c178c;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #fff;
        }
    }
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: #FFF;
        }
    
        display: none;
    }

    .chart {
        display:flex; 
        flex-direction: row; 
        justify-content: center; 
        background: #fff; 
        border-radius: 5px; 
        width: 95%;
        margin-left: 20px;
    }
    .order-report{
        p{
            margin-top: 10px;
            margin-bottom: 5px;
            text-align: center;
            font-size: 12px;
            font-weight: 800;
        }
    }
    .receipts-report{
        p{
            margin-top: 10px;
            margin-bottom: 5px;
            text-align: center;
            font-size: 12px;
            font-weight: 800;
        }
    }
    .content{
        h2{
            color: #000;
            font-weight: 650;
            font-family: Mulish;
        }
    }

    .menu-bar{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        box-sizing: border-box;
        height: 50px;
        background: #000;
        padding: 10px;
        border-radius: 4px;
    }
    .card-row{
        display:flex;
        flex-direction: row;
        justify-content:space-evenly;
        
    }
    .wallet{
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
    }
    .my-account{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        
        p {
            margin-top: 10px;
            line-height: 1px;
            font-weight: 700;
            font-size: 12px;
        }
        .account-number{
            font-size: 16px;
            color:#704141;
            font-weight: 800;
            font-family: Mulish;
            line-height: 1px;
            margin-bottom: 10px;
        }
    }
    .my-wallet{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        
        p {
            margin-top: 5px;
            line-height: 1px;
            font-weight: 700;
            font-size: 12px;
        }
        .amount{
            font-size: 16px;
            color:#803f3f;
            font-weight: 800;
            font-family: Mulish;
            line-height: 1px;
            margin-bottom: 10px;
            text-align: right;
        }
    }
    .note{
            font-size: 11px;
            color:#000;
            font-family: Mulish;
            margin-bottom: -2px;
        }
    .card{
        margin: 6px;
        width: 100%;
        height: 80%;
        padding: 10px;
        border: 1px solid #21182f;
        color:#000;
        box-shadow: 2px 1px 8px 1px #888888;

        h3 {
            margin-top: 4px;
            font-size: 16px;
            font-weight: 500;
            color:#000;
            font-weight: 650;
            font-family: Mulish;
        }
        .today-orders{
            margin-top: 2px;
            font-family: Mulish;
            font-size: 10px;
            line-height: 1px;
        }
        .order-figure {
            margin-top: 2px;
            font-family: Mulish;
            font-size: 10px;
            line-height: 1px;
            font-weight: 800;
            text-align: left;
            height: 10px;
        }

        span{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Mulish;
            .fa-users{
                font-size: 20px;
            }
            .fa-user{
                font-size: 20px;
            }
        }
        .count{
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            font-family: Mulish;
        }
        .count_mode{
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 12px;
            font-weight: 400;
            padding-left: 30px;
        }
    }

    .background{
        padding:20px
    }

    .bg-user{
        background: #3afbdb28;
       // opacity: 0.8;
    }
    .bg-order{
        background: #b73afb28;
        //opacity: 0.8;
    }
    .bg-menu{
        background: #abfb3a28;
        //opacity: 0.8;
    }

    h2 {
        color: #FFF;
        font-size:18px;
        font-family: Mulish;
    }
    table > thead {
        font-size: 12px;
        font-family: Mulish;
    }
    table > tbody {
        font-size: 11px;
        font-family: Mulish;
    }

    @media screen and (max-width: 700px) {

        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
        
            &>div {
                width: 80%;
                height: 40%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #2b0c178c;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
        
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
        
            }
        
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
        
            span {
                margin-bottom: 20px;
            }
        
        }
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }

            display: none;
        }
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }
    
            display: block;
        }
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
        
            &>div {
                width: 80%;
                height: 40%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #2b0c178c;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
        
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
        
            }
        
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
        
            span {
                margin-bottom: 20px;
            }
        
        }
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }

            display: none;
        }
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: #FFF;
            }
    
            display: block;
        }
        .chart {
            display:flex; 
            flex-direction: column; 
            justify-content: center; 
            background: #fff; 
            border-radius: 5px; 
            width: 90%;
            align-items: center;
            font-size: 8px;
            display: none;
        }
        .menu-bar{
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            box-sizing: border-box;
            height: 50px;
            background: #000;
            padding: 10px;
            border-radius: 4px;
        }
        .background{
            height: 130vh;
            background:#cccccc46;
            padding-right: 20px;
        }
        .main-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .view-content {
            overflow-x: auto;
            overflow-y: auto;
            height: 230px;
            width: 96%;
        }
        .notifications{
            margin-top: 10px;
            width: 90%;
            display: flex;
            flex-direction: column;
            color: #fff;
            font-size:8px;
            font-weight: 650;
            font-family: Mulish;
            overflow-x: auto;
            overflow-y: auto;  
            height: 200px;
            background: #fff;
            box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
            
            .notification-header {
                width: 100%;
                background: #000;
                padding: 5px;
                border-radius:2px;
            }
            .notification-content{
                padding:6px;
            }
    
        }
        .my-account{
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            
            p {
                margin-top: 10px;
                line-height: 1px;
                font-weight: 700;
                font-size: 10px;
            }
            .account-number{
                font-size: 10px;
                color:#704141;
                font-weight: 800;
                font-family: Mulish;
                line-height: 1px;
                margin-bottom: 10px;
            }
        }
        .my-wallet{
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            
            p {
                margin-top: 5px;
                line-height: 1px;
                font-weight: 700;
                font-size: 10px;
            }
            .amount{
                font-size: 10px;
                color:#803f3f;
                font-weight: 800;
                font-family: Mulish;
                line-height: 1px;
                margin-bottom: 10px;
                text-align: right;
            }
        }
        .note{
                font-size: 8px;
                color:#000;
                font-family: Mulish;
                margin-bottom: -2px;
            }
        .card-row{
            display:flex;
            flex-direction: column;
            justify-content:space-evenly;
        }
        .card{
            margin: 6px;
            width: 95%;
            height: 120px;
            padding: 10px;
            border: 1px solid #21182f;
    
            h3 {
                margin-top: 4px;
                font-size: 15px;
                font-weight: 300;
                color:#000;
                font-family: Mulish;
            }
    
            span{
                display: flex;
                flex-direction: row;
                .fa-users{
                    font-size: 20px;
                }
                .fa-user{
                    font-size: 20px;
                }
            }
            .count{
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-size: 20px;
                font-weight: 500;
                font-family: Mulish;
            }
            .count_mode{
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 13px;
                font-weight: 200;
                padding-left: 30px;
                font-family: Mulish;
            }
        }
        h2 {
            color: #FFF;
            font-size: 20px;
            font-family: Mulish;
        }

    }

    </style>