<template>
    <div class="background">
        <div class="bg-content">
                <div class="menu-bar">
                    <h2><i class="fas fa-book"></i> &nbsp;Manage Transactions</h2>
                </div>
                <div class="tab">
                    <div class="tab-investments" @click="tabInvestment()">
                        <i class="fa fa-home"></i> Pool
                    </div>
                    <div class="tab-savings" @click="tabSavings()">
                        <i class="fas fa-door-open"></i> Savings
                    </div>
                    <div class="tab-loans" @click="tabLoans()">
                        <i class="fas fa-cash-register"></i> Loans
                    </div>

                </div>
    
                <div class="transaction-container" v-if="investment_status">
                    <div class="reservation-content">
                        <p><i class="fas fa-hotel"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
                       
                        <div v-if="show_list_investments">
                            <label for="">Search by member id: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter member id" v-model="searchby_accountno">&nbsp;
                            <label for="">Search by status: &nbsp;</label>
                            <select class="form-control input" v-model="active_investment" @change="checkInvestmentStatus">
                                <option value="">-Select Status-</option>
                                <option value="1"> Active </option>
                                <option value="0"> Inactive </option>
                            </select>
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Customer </th>
                                            <th>Member Id</th>
                                            <th>Pool Type </th>
                                            <th>Tenure</th>
                                            <th>Rate </th>
                                            <th>Amount </th>
                                            <th>Accumulated Amount </th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in all_investments" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.firstname }} {{ data.middlename }} {{ data.lastname }} 
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.account_number }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.category_name }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.title }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.rate }} % 
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                               NGN {{ data.investment_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                            </td>  
                                            <td style="font-weight: 400; width:auto">
                                                NGN {{ data.accumulatedSum.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                             </td>   
                                             <td style="font-weight: 400; width:auto">
                                                <span v-if="data.istatus == 1" class="badge badge-success">
                                                    Active
                                                </span>
                                                <span v-if="data.istatus == 0" class="badge badge-danger">
                                                    InActive
                                                </span>
                                            </td>                                        
                                            <td style="font-weight: 400;">
                                                <router-link :to="url2+data.investment_id"> <span style="color:green; cursor:pointer" data-toggle="tooltip" title="View more"><i class="fas fa-eye"></i> More</span></router-link> &nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="transaction-container2" v-if="savings_status">
                    <div class="reservation-content2">
                        <div class="guest-information">
                            <p> <i class="fas fa-hotel"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
                        </div>
                        <div v-if="show_list_savings">
                            <label for="">Search by account number: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter account number" v-model="savings_search">&nbsp;
                            <label for="">Search by status: &nbsp;</label>
                            <select class="form-control input" v-model="active_status" @change="checkStatus">
                                <option value="">-Select Status-</option>
                                <option value="1"> Active </option>
                                <option value="0"> Inactive </option>
                            </select>
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Customer </th>
                                            <th>Member Id</th>
                                            <th>Savings Type </th>
                                            <th>Frequency</th>
                                            <th>Saving Amount </th>
                                            <th>Total Saved </th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in filteredSavings" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.firstname }} {{ data.middlename }} {{ data.lastname }} 
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.account_number }} 
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.category_name }} 
                                            </td>
                                          
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.mode }}
                                            </td>
                                             <td style="font-weight: 400; width:auto">
                                                NGN{{ data.saving_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                NGN{{ data.amountSaved.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                <span v-if="data.status == 1" class="badge badge-success">
                                                    Active
                                                </span>
                                                <span v-if="data.status == 0" class="badge badge-danger">
                                                    InActive
                                                </span>
                                            </td>
                                            <td style="font-weight: 400;">
                                                <router-link :to="url+data.member_id + '/' + data.savings_type_id"> <span style="color:green; cursor:pointer" data-toggle="tooltip" title="View more"><i class="fas fa-eye"></i> More</span></router-link> &nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
    <script>
    //import Vue from 'vue'
   // import swal from 'sweetalert2';
    //import $ from "jquery";
    import PageLoader from '../../components/PageLoader.vue'
    
    export default {
        components: {
            PageLoader,
        },
    
        data() {
            return {
                isLoading: false,
                report: [],
                app_role: '',
                cfirstname: '',
                clastname: '',
                cusername: '',
                userid: '',
                check_items: [],
                customer_order: false,
               
                investment_status: true,
                savings_status: false,
                housekeeping_status: false,
                process_payment: false,
                report_status: false,
                edit_item: false,
                edit_savings: false,
                category_name: '',
                savings_category_name: '',
                categories: [],
                edit_category_name: '',
                edit_category_name2: '',
                item_id: '',
                item_id2: '',
                loading: false,
                loading2: false,
                loading3: false,
                loading4: false,
                loading5: false,
                loading6: false,
                loading7: false,
                loading8: false,
                loading9: false,
                loading10: false,
                loading11: false,
                loading12: false,
                loading13: false,
                loading14: false,
                show_investment_type: false,
                show_savings_type: false,
                show_list_investments: true,
                show_list_savings: true,

                panel_name: "List Pool",
                panel_header: "Manage Pool",
                room_type: '',
                room_number: '',
                bed_type: '',
                room_capacity: '',
                room_description: '',
                room_amount: '',
                rooms: [],
                searchby_accountno: '',
                vinvestment_status: '',
               
                url: '',
                url2: '',
                savings_search: '',
                response: '',
                    
                savings_categories: [],
                savings_type_name: '',
                active_status: '',
                all_savings: [],
                all_investments: [],
                active_investment: '',
    
            };
        },
    
        beforeMount() {
            this.fetchAllSavings();
            this.fetchAllInvestments();
        },
    
        mounted() {
            this.app_role = localStorage.getItem("app_status");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");    
            this.url = "view-more/";
            this.url2 = "more/";
        },
    
        created() {

    
        },
        beforeDestroy() {
           
        },
        computed: {
          
            filteredSavings() {
                const searchString = this.savings_search.toLowerCase();
                return this.all_savings.filter(item => item.account_number.toLowerCase().includes(searchString));
            },
            filteredInvestments() {
                const searchString = this.searchby_accountno.toLowerCase();
                return this.all_investments.filter(item => item.account_number.toLowerCase().includes(searchString));
            },
    
               
        },
    
        methods: {
        
            tabInvestment() {

                this.investment_status = true
                this.savings_status = false
                this.panel_header = "Manage Investments"
                this.panel_name = "List Investments"
    
                const tab1 = document.getElementsByClassName("tab-investments");
                tab1[0].style.color = "#fff";
    
                const tab2 = document.getElementsByClassName("tab-savings");
                tab2[0].style.color = "#000";
        
                const tab5 = document.getElementsByClassName("tab-loans");
                tab5[0].style.color = "#000";
    
            },
    
            tabSavings() {
                this.investment_status = false
                this.savings_status = true
                this.report_status = false
                this.process_payment = false
                this.panel_header = "Savings"
                this.panel_name = "List Savings"
    
                const tab1 = document.getElementsByClassName("tab-investments");
                tab1[0].style.color = "#000";
    
                const tab2 = document.getElementsByClassName("tab-savings");
                tab2[0].style.color = "#fff";
        
                const tab5 = document.getElementsByClassName("tab-loans");
                tab5[0].style.color = "#000";
        
            },
        
            tabLoans() {
               
                this.process_payment = true
                this.list_payments = true
                this.investment_status = false
                this.savings_status = false
             
                this.panel_header = "Loans"
                this.panel_name = "List Loans"
    
                const tab5 = document.getElementsByClassName("tab-loans");
                tab5[0].style.color = "#fff";
    
                const tab1 = document.getElementsByClassName("tab-investments");
                tab1[0].style.color = "#000";
    
                const tab2 = document.getElementsByClassName("tab-savings");
                tab2[0].style.color = "#000";
    
            },
            
            closeMenu() {
                this.edit_item = false
                this.edit_savings = false
            },
            async fetchAllSavings() {
                try {
                    this.isLoading = true
                    const response = await this.$http.get('/list-all-savings', )
                    this.isLoading = false
                    this.all_savings = response.data.allsavings;
    
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllInvestments() {
                try {
                    this.isLoading = true
                    const response = await this.$http.get('/list-all-investments', )
                    this.isLoading = false
                    this.all_investments = response.data.allInvestments;
    
                } catch (err) {
                    console.log(err);
                }
            },

            async checkStatus() {
                try {
                    const response = await this.$http.post('/check-active-savings', {
                        active_status: this.active_status
                    })
                    this.all_savings = response.data.allsavings;
    
                } catch (err) {
                    console.log(err);
                }
            },  

            async checkInvestmentStatus(){
                try {
                    const response = await this.$http.post('/check-active-investments', {
                        active_investment: this.active_investment
                    })
                    this.all_investments = response.data.allInvestments;
    
                } catch (err) {
                    console.log(err);
                }
            },
            backto() {
                this.show_list_savings = true
                this.show_guest_information = false
                this.panel_header = "Manage Reservations"
                this.panel_name = "List Reservations"
                document.getElementById("guest-info").style.display = "block"
                document.getElementById("backto").style.display = "none"
            },   
    
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 35%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask4 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .background {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .bg-content{
        padding: 10px;
        background:#ccc;
        padding-right: 20px;
        width: 100%;
        border-radius: 10px;
        height: auto;
    }
    
    
        .menu-bar {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            box-sizing: border-box;
            height: 50px;
            background: #000;
            padding: 10px;
            border-radius: 4px;
        }
    
        .tab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 4px;
            margin-top: 15px;
        }
    
        .tab-investments {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            border-bottom: solid 1px #fff;
            color: #fff;
            cursor: pointer;
        }
    
        .tab-investments:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .tab-savings {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
        .tab-savings:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
        .tab-loans {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            color: #000;
            cursor: pointer;
        }
        .tab-loans:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
        .tab-housekeepings {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-housekeepings:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .tab-reports {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            //border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-reports:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .transaction-container {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .transaction-container2 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .transaction-container3 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .search-input {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            font-size: 10px !important;
        }
    
        .search-name {
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-align: left;
            color: #000;
            margin-bottom: -2px;
        }
    
        .orderid {
            width: 10%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .searchdate {
            width: 13%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .btn-search1 {
            width: 8%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            background: #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
            color: #fff;
        }
    
        .rm-menu {
            margin: 4px;
        }
    
        .rm-category:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .rm-category3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .add-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .reservation-content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
            font-size: 12px;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu2 {
            margin: 4px;
        }
    
        .add-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .reservation-content2 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
            font-size: 12px;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .reservation-content3 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu3 {
            margin: 4px;
        }
    
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .guest-information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .guests {
                font-size: 10px;
                font-weight: 400 !important;
                cursor: pointer;
            }
    
            .guests:hover {
                text-decoration: underline;
            }
        }
    
        .add-category {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    
        .add-category2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    
        .add-room {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-room2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-rm-center {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .add-rm-center2 {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .list-category {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            height: 200px;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .list-category2 {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .input {
            border: solid 0px #000;
            border-radius: 4px;
            width: 30%;
            height: 30px;
            font-size: 12px;
        }
    
        .btn-primarys {
            background: #FFA500;
            font-size: 12px;
            height: 30px;
            width: 10%;
    
        }
    
    img {
        width: 50px;
        border-radius: 40px;
    }
    
    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        text-align: left;
        color: #fff;
    }
    
    
    
    @media screen and (max-width: 768px) {
    
        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: 25%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    }
    
    @media screen and (max-width: 768px) {
        .background {
            background: #fbd43a;
            width: 100%;
            height: 100vh;
    
            .container {
                background: #fbd43a;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 30px;
    
                .card {
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
    
                    .menu-bar {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        height: 50px;
                        background: #000;
                        padding: 10px;
                        border-radius: 4px;
                    }
    
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        padding: 4px;
                        border-radius: 4px;
                        margin-top: 15px;
                    }
    
                    .tab-investments {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 0px;
                        border-right: solid 2px #fff;
                        border-bottom: solid 1px #fff;
                        color: #fff;
                        cursor: pointer;
                    }
    
                    .tab-savings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        border-right: solid 2px #fff;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .tab-housekeepings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .transaction-container {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .transaction-container2 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .transaction-container3 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .search-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 10px !important;
                    }
                    .searchdate {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                    }
        
                    .btn-search1 {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        background: #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                        color: #fff;
                    }
    
                    .rm-menu {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .rm-category:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .add-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .reservation-content {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .rm-menu2 {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .add-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .reservation-content2 {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .add-room {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-room2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-rm-center {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-rm-center2 {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-category {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .add-category2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .input {
                        width: 100%;
                        height: 30px;
                    }
    
                    .btn-primarys {
                        margin-top: -10px;
                        width: 100%;
                        height: 30px;
                    }
    
                    img {
                        width: 50px;
                    }
                }
    
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: left;
                    color: #fff;
                }
    
            }
        }
    }
    </style>
    