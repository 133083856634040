<template>
    <div class="background">
        <div class="bg-content">
                <div class="menu-bar">
                    <h2> <i class="fas fa-users"></i> &nbsp;Edit Member</h2>
                </div>
    
                <div class="users-container2">
                    <div class="users-content">
                        <div style="display: flex; flex-direction: row; justify-content: space-between">
                            <p><i class="fas fa-users"></i> Registration Form <i class="fa fa-angle-right" style="color:red"></i> Edit member</p>
                            <span v-if="app_role == 1">
                                <router-link to="/manage-users"> <i class="fas fa-arrow-left"></i> Back </router-link>
                            </span>
                            <span v-if="app_role == 2">
                                <router-link to="/my-users"> <i class="fas fa-arrow-left"></i> Back </router-link>
                            </span>
                        </div>
                       
                        <div class="members-form">
                            <div style="margin-top:10px;margin-bottom:10px; font-weight:800;">
                                MEMBERS' REGISTRATION FORM
                            </div>
                            <form @submit.prevent="update_form" enctype="multipart/form-data">
                                <!-- <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-10 overflow-hidden absolute" accept="image/png, image/jpg" style="cursor:pointer" @change="handleFileChange"/> -->
                            <center>
                                <div class="img-profile" v-if="previewImage == ''" style="margin-bottom:10px;">
                                    <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-0 overflow-hidden absolute" accept="image/png, image/jpg" style="cursor:pointer" @change="pickFile"/>
                                </div>
                                <span v-else style="margin-bottom:10px;">
                                    <div class="img-profile" :style="{ 'background-image': `url(${previewImage})` }">
                                        <input type="file" ref="fileInput" id="image_input"  class="w-px h-px opacity-0 overflow-hidden absolute" accept="image/png, image/jpg" style="cursor:pointer" @change="pickFile"/>
                                    </div>
                                </span>
                            </center>
                            
                            <div class="row col-md-12">
                                <div class="col-md-4" v-if="app_role == 1">
                                    <label for="">Account officer</label>
                                    <v-autocomplete
                                    v-model="client_account_officer"
                                    :items="staffListing"
                                    dense
                                    label="Search"
                                    class="form-select"
                                   ></v-autocomplete>
                                </div>
                                <div class="col-md-4" v-else-if="app_role == 2">
                                    
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-2">
                                    <label for="">Title</label>
                                    <select class="form-control form-select" v-model="client_title" required>
                                        <option value="">-Select-</option>
                                        <option value="1"> Mr. </option>
                                        <option value="2"> Mrs. </option>
                                        <option value="3"> Dr. </option>
                                        <option value="4"> Miss </option>
                                        <option value="5"> Chief </option>
                                        <option value="6"> Barr. </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Firstname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter firstname"   v-model="client_firstname" required>&nbsp;
                                </div>
                                <div class="col-md-3">
                                    <label for="">Middlename</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter middlename (Optional)"   v-model="client_middlename">&nbsp;
                                </div>
                                <div class="col-md-4">
                                    <label for="">Surname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter surname"   v-model="client_surname" required>&nbsp;
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Gender</label>
                                    <select class="form-control form-select" v-model="client_gender">
                                        <option value="">-Select-</option>
                                        <option value="1"> Male </option>
                                        <option value="2"> Female </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Date of birth</label>
                                    <input type="date" class="form-control form-input"   v-model="client_dob" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Phone</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter phone number"   v-model="client_phone_number" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Email</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter email address (Optional)"   v-model="client_email_address" required>&nbsp;
                                </div>
                                
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Marital Status</label>
                                    <select class="form-control form-select" v-model="client_ms">
                                        <option value="">-Select-</option>
                                        <option value="1"> Married </option>
                                        <option value="2"> Single </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Spouse</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter spouse name"   v-model="client_spouse" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Mother's maiden name</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter mother's maiden name"   v-model="client_mother_name" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Religion</label>
                                    <select class="form-control form-select" v-model="client_religion">
                                        <option value="">-Select-</option>
                                        <option value="1"> Christianity </option>
                                        <option value="2"> Islam </option>
                                        <option value="3"> Others </option>
                                    </select>
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">State of origin</label>
                                    <select class="form-control form-select" v-model="client_state" @change="getStateLga()">
                                        <option value="">-Select-</option>
                                        <option v-for="(data, index) in states" :key="index" :value="data.id"> {{ data.state }} </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Lga of origin</label>
                                    <select class="form-control form-select" v-model="client_lga">
                                        <option value="">-Select-</option>
                                        <option v-for="(data, index) in lgas" :key="index" :value="data.id"> {{ data.lga }} </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Country of birth</label>
                                    <select class="form-control form-select" v-model="birth_country">
                                        <option value="">-Select-</option>
                                        <option value="1"> Nigeria </option>
                                        <option value="2"> Other </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Nationality</label>
                                    <select class="form-control form-select" v-model="client_nationality">
                                        <option value="">-Select-</option>
                                        <option value="1"> Nigeria </option>
                                        <option value="2"> Others </option>
                                    </select>
                                </div>
                               
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-6">
                                    <label for="">Residential/Contact address</label>
                                    <textarea  class="form-control form-input" v-model="client_contact_address" id="" cols="30" rows="10" placeholder="Enter contact address" required></textarea>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Whatsapp number(if any)</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter whatsapp number "   v-model="client_whatsapp_number" >
                                </div>
                                <div class="col-md-3">
                                    <label for="">Means of ID (NIN)</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter nin "   v-model="client_nin" >
                                </div>                               
                            </div> 
                            <div style="margin-top:10px; margin-bottom:10px; font-weight:800;">
                                <center>  NEXT OF KIN </center>
                            </div>
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Title</label>
                                    <select class="form-control form-select" v-model="nok_title" required>
                                        <option value="">-Select-</option>
                                        <option value="1"> Mr. </option>
                                        <option value="2"> Mrs. </option>
                                        <option value="3"> Dr. </option>
                                        <option value="4"> Miss </option>
                                        <option value="5"> Chief </option>
                                        <option value="6"> Barr. </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Firstname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter firstname"   v-model="nok_firstname" required>&nbsp;
                                </div>
                                <div class="col-md-3">
                                    <label for="">Middlename</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter middlename (Optional)"   v-model="nok_middlename">&nbsp;
                                </div>
                                <div class="col-md-3">
                                    <label for="">Surname</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter surname"   v-model="nok_surname" required>&nbsp;
                                </div>
                            </div> 
                            <div class="row col-md-12">
                                <div class="col-md-3">
                                    <label for="">Gender</label>
                                    <select class="form-control form-select" v-model="nok_gender">
                                        <option value="">-Select-</option>
                                        <option value="1"> Male </option>
                                        <option value="2"> Female </option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Date of birth</label>
                                    <input type="date" class="form-control form-input"   v-model="nok_dob" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Phone</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter phone number"   v-model="nok_phone_number" required>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Nature of business</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter nature of business"   v-model="nok_business" >&nbsp;
                                </div>
                                
                            </div> 
                            <div style="margin-top:10px">
                            <center>
                                <button style="width: 50%" class="form-control btn-success" v-if="!loading" >
                                    Update </button>
                                <button style="width: 50%" class="form-control btn-success" type="button" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </center>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
    
        </div> 
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
    <script>
    //import Vue from 'vue'
    import swal from 'sweetalert2';
    import PageLoader from '../../components/PageLoader.vue'
    
    export default {
        components: {
            PageLoader,
        },
    
        data() {
            return {
                isLoading: false,
                app_role: '',
                cfirstname: '',
                clastname: '',
                cusername: '',
                userid: '',
               
                clients_status: false,
                loading: false,
                show_list_clients: true,
                account_officer: '',
                client_account_officer: '',
                client_title: '',
                client_firstname: '',
                client_middlename: '',
                client_surname: '',
                client_gender: '',
                client_phone_number: '',
                client_email_address: '',
                client_password: '',
                client_cpassword:'',
                client_account_type: '',
                client_account_number: '',
                client_state: '',
                client_lga: '',
                client_spouse: '',
                client_mother_name: '',
                client_ms: '',
                client_dob: '',
                birth_country: '',
                client_nationality: '',
                client_religion: '',
                client_contact_address: '',
                client_whatsapp_number: '',
                client_nin: '',
                nok_title: '',
                nok_firstname: '',
                nok_middlename: '',
                nok_surname: '',
                nok_gender: '',
                nok_dob:'',
                nok_phone_number: '',
                nok_business:'',
                states: [],
                lgas: [],
                previewImage: '',
                branchid: '',
                branches: [],
                staffList: [],
                staffListing: [],
                edit_user: false,
                membersList: '',
                search_account_number: '',
                selectedFile: '',
                memberid: '',
                image_url: process.env.VUE_APP_IMAGE_URL,
                user_id: '',
                account_number: '',
                picture_url: '',
    
            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
            this.app_role = localStorage.getItem("user_role");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");
            this.branchid = localStorage.getItem("branchid");
            this.fetchAllStates();
            this.fetchAllBranches();
            this.fetchAllUsers();
           
            this.memberid = this.$route.params.memberid
            this.fetchMembersDetail(this.memberid);
    
        },
    
        created() {
    
        },
        beforeDestroy() {

        },
        computed: {
           
        },
    
        methods: {

            async update_form(){
                try {
                    if (this.app_role == 1) {
                        if (!this.client_account_officer) {
                            swal.fire({
                                icon: 'warning',
                                title: 'Error...',
                                width: 450,
                                height: 5,
                                text: 'Please select account officer',
                            })
                            return;
                        }  
                    }
                    

                    if (!this.client_firstname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter firstname',
                        })
                        return;
                    }                
                    if (!this.client_surname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter surname',
                        })
                        return;
                    }
                    if (!this.client_gender) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select gender',
                        })
                        return;
                    }
                    if (!this.client_phone_number) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter phone',
                        })
                        return;
                    }
                    if (!this.client_email_address) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter email address',
                        })
                        return;
                    }
                    if (!this.client_ms) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select marital status',
                        })
                        return;
                    }
                    else {
                        if (this.client_ms == 1) {
                            if (!this.client_spouse) {
                                swal.fire({
                                    icon: 'warning',
                                    title: 'Error...',
                                    width: 450,
                                    height: 5,
                                    text: 'Please enter spouse name',
                                })
                                return;
                            }
                        }
                    }
                    if (!this.client_mother_name) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: "Please enter mother maiden's name",
                        })
                        return;
                    }
                    if (!this.client_religion) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select religion',
                        })
                        return;
                    }
                    if (!this.client_state) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select state',
                        })
                        return;
                    }
                    if (!this.client_lga) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select lga',
                        })
                        return;
                    }
                    if (!this.birth_country) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select birth country',
                        })
                        return;
                    }
                    if (!this.client_nationality) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select nationality',
                        })
                        return;
                    }
                    if (!this.client_contact_address) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter contact address',
                        })
                        return;
                    }
                    if (!this.nok_title) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select next of kin title',
                        })
                        return;
                    }
                    if (!this.nok_firstname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin firstname',
                        })
                        return;
                    }
                    if (!this.nok_surname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin surname',
                        })
                        return;
                    }
                    if (!this.nok_gender) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select next of kin gender',
                        })
                        return;
                    }
                    if (!this.nok_dob) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select next of kin date of birth',
                        })
                        return;
                    }
                    if (!this.nok_phone_number) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin phone',
                        })
                        return;
                    }
                    if (!this.nok_business) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter next of kin nature of business',
                        })
                        return;
                    }
    
                    this.loading = true;
                    
                    if (this.app_role == 1) {
                        this.account_officer = this.client_account_officer
                    }else if(this.app_role == 2){
                        this.account_officer = this.userid
                    }

                    const formData = new FormData();
                    formData.append('image', this.selectedFile),
                    formData.append('account_officer', this.account_officer),
                    formData.append('title', this.client_title),
                    formData.append('firstname', this.client_firstname),
                    formData.append('middlename', this.client_middlename),
                    formData.append('surname', this.client_surname),
                    formData.append('gender', this.client_gender),
                    formData.append('dob', this.client_dob),
                    formData.append('phone', this.client_phone_number),
                    formData.append('email', this.client_email_address),
                    formData.append('marital_status', this.client_ms),
                    formData.append('spouse_name', this.client_spouse),
                    formData.append('maiden_name', this.client_mother_name),
                    formData.append('religion', this.client_religion),
                    formData.append('state', this.client_state),
                    formData.append('lga', this.client_lga),
                    formData.append('birth_country', this.birth_country),
                    formData.append('nationality', this.client_nationality),
                    formData.append('contact_address', this.client_contact_address),
                    formData.append('whatsapp_number', this.client_whatsapp_number),
                    formData.append('id_means', this.client_nin),
                    formData.append('nok_title', this.nok_title),
                    formData.append('nok_firstname', this.nok_firstname),
                    formData.append('nok_middlename', this.nok_middlename),
                    formData.append('nok_surname', this.nok_surname),
                    formData.append('nok_gender', this.nok_gender),
                    formData.append('nok_dob', this.nok_dob),
                    formData.append('nok_phone', this.nok_phone_number),
                    formData.append('nok_business_nature', this.nok_business),
                    formData.append('user_id',  this.user_id),
                    formData.append('memberid', this.memberid),
                    formData.append('account_number', this.account_number),
                    formData.append('picture_url', this.picture_url)

                    this.response = await this.$http.post('/update-member', formData
    
                    );
    
                    this.loading = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                    } else {
    
                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: this.response.data.message,
                        });

                        this.$router.push("/manage-users")
                    }
                    
    
                } catch (err) {
                    console.log(err);
                }
            },

            getRandomAlphanumeric(length) {
                const alphanumeric = '0123456789';
                let result = '';
                for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(Math.random() * alphanumeric.length);
                    result += alphanumeric[randomIndex];
                }
                return result;
            },

            async fetchAllStates() {
                try {
                    const response = await this.$http.get('/all-states', )
                    this.states = response.data.states;
    
                } catch (err) {
                    console.log(err);
                }
            },

            async getStateLga(){
                try {
                                                   
                    this.response = await this.$http.post('/get-state-lga', {
                        state_id: this.client_state,
                    });
        
                    if (this.response.data.lgas != "") {
                        this.lgas = this.response.data.lgas
    
                    } else {
    
                    swal.fire({
                        icon: 'warning',
                        width:450,
                        height:5,
                        title: "Not Found",
                        text: "Record not found",
                        });

                        this.client_lga = ""
                    }
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllBranches() {
                try {
                    const response = await this.$http.get('/fetch-branches', )
                    this.branches = response.data.branches;
    
                } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllUsers() {
                try {
                    const response = await this.$http.get('/all-staff', )
                    this.staffList = response.data.staff;

                    this.staffList.forEach(element => {
                    this.staffListing.push({
                        value: element.id,
                        text: element.firstname + ' ' + element.lastname +'('+ element.staff_id + ')'
                    })
                });
    
                } catch (err) {
                    console.log(err);
                }
            },
            pickFile() {
                this.selectedFile = this.$refs.fileInput.files[0];
                let input = this.$refs.fileInput;
                let file = input.files;
                
                if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImage = e.target.result;
                };
                reader.readAsDataURL(file[0]);
                this.$emit("input", file[0]);
                }
            },

            handleFileChange() {
                this.selectedFile = this.$refs.fileInput.files[0];
                console.log(this.selectedFile);
            },

            async fetchMembersDetail(member_id) {
                try {
                        this.isLoading = true
                        const response = await this.$http.post('/fetch-member-detail', {
                            memberid: member_id
                        })
                        this.isLoading = false
                        this.membersList = response.data.members;
                        this.client_account_officer = this.membersList[0].account_officer,
                        this.client_title = this.membersList[0].title,
                        this.client_firstname  = this.membersList[0].firstname,
                        this.client_middlename  = this.membersList[0].middlename,
                        this.client_surname  = this.membersList[0].lastname,
                        this.client_gender  = this.membersList[0].gender,
                        this.client_dob  = this.membersList[0].dob,
                        this.client_phone_number  = this.membersList[0].phone,
                        this.client_email_address  = this.membersList[0].email,
                        this.client_ms  = this.membersList[0].marital_status,
                        this.client_spouse  = this.membersList[0].spouse_name,
                        this.client_mother_name  = this.membersList[0].maiden_name,
                        this.client_religion  = this.membersList[0].religion,
                        this.client_state  = this.membersList[0].state,
                        this.client_lga  = this.membersList[0].lga,
                        this.birth_country  = this.membersList[0].country_birth,
                        this.client_nationality  = this.membersList[0].nationality,
                        this.client_contact_address  = this.membersList[0].contact_address,
                        this.client_whatsapp_number  = this.membersList[0].whatsapp_number,
                        this.client_nin  = this.membersList[0].id_means,
                        this.nok_title  = this.membersList[0].nok_title,
                        this.nok_firstname  = this.membersList[0].nok_firstname,
                        this.nok_middlename  = this.membersList[0].nok_middlename,
                        this.nok_surname  = this.membersList[0].nok_surname,
                        this.nok_gender  = this.membersList[0].nok_gender,
                        this.nok_dob  = this.membersList[0].nok_dob,
                        this.nok_phone_number  = this.membersList[0].nok_phone,
                        this.nok_business  = this.membersList[0].nok_business_nature,
                        this.previewImage  = this.image_url + this.membersList[0].picture,
                        this.user_id = this.membersList[0].user_id,
                        this.account_number = this.membersList[0].account_number,
                        this.picture_url = this.membersList[0].picture

    
                } catch (err) {
                    console.log(err);
                }
            },
        
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .password-check{
        margin-left:50px;
        font-size: 10px;
        font-family: Mulish;
        .five-char {
            color: red
        }
        .uppercase{
            color: red
        }
        .number{
            color: red
        }
        .special-char{
            color: red
        }
        .password-match{
            color: red
        }
}
.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #2b0c17;
        background: #FFF;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    .welcome {
        margin-top: 8px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 23px;
        justify-content: center;
        color: #FFFFFF;

    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

    .counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .order-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        height: 250px;
        overflow-y: auto;
       
    }
    .edit-left{
        width:100%;
        .space-top{
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }  
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }

    }

    .edit-right{
        width:100%;
        margin-left: 4px;
        .space-top{
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }  
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }    

    }

    .edit-button {
        margin-bottom: 15px;
    }
   
}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask4 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .background {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .bg-content{
        padding: 10px;
        background:#ccc;
        padding-right: 20px;
        width: 100%;
        border-radius: 10px;
        height: auto;
    }
    
    
        .menu-bar {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            box-sizing: border-box;
            height: 50px;
            background: #000;
            padding: 10px;
            border-radius: 4px;
        }
    
        .tab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 4px;
            margin-top: 15px;
        }
    
        .tab-staff {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            border-bottom: solid 1px #fff;
            color: #fff;
            cursor: pointer;
        }
    
        .tab-staff:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .tab-clients {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            color: #000;
            cursor: pointer;
        }
        .tab-clients:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
       
        .tab-reports {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            //border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-reports:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .users-container {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .users-container2 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .users-container3 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .search-input {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            font-size: 10px !important;
        }
    
        .search-name {
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-align: left;
            color: #000;
            margin-bottom: -2px;
        }
    
        .orderid {
            width: 10%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .searchdate {
            width: 13%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .btn-search1 {
            width: 8%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            background: #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
            color: #fff;
        }
    
        .rm-menu {
            margin: 4px;
        }
    
        .rm-category:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .rm-category3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .add-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .users-content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu2 {
            margin: 4px;
        }
    
        .add-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .users-content2 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .users-content3 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu3 {
            margin: 4px;
        }
    
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .guest-information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .guests {
                font-size: 10px;
                font-weight: 400 !important;
                cursor: pointer;
            }
    
            .guests:hover {
                text-decoration: underline;
            }
        }
    
        .add-user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 10px;
            font-weight: 700;
            font-family: Mulish;

            .staff-form {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
       
        .members-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
        }

        .btn-input{
            height: 30px;
            width: auto;
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            margin: 2px;
        }

        .btn-group{
            display:flex; 
            flex-direction: row; 
            justify-content: space-between;
        }

        .img-profile {
            width: 120px;
            height: 120px;
            background: url("../../assets/photo_add.png");
            background-size: cover;
            padding-bottom: 0rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            border-radius: 10px;
        }
        .form-input{
            font-weight: 500;
            font-size: 12px;
            height: 30px;  
        }
        
        .form-select{
            font-weight: 500;
            font-size: 12px;
            height: 30px;  
        }
        .form-button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
        }
    
        .add-user2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    
        .add-room {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-room2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-rm-center {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .add-rm-center2 {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .list-category {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            height: 200px;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .list-category2 {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .input {
            border: solid 0px #000;
            border-radius: 4px;
            width: 30%;
            height: 30px;
            font-size: 12px;
        }
    
        .btn-primarys {
            background: #FFA500;
            font-size: 12px;
            height: 30px;
            width: 10%;
    
        }
    
    img {
        width: 50px;
        border-radius: 40px;
    }
    
    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        text-align: left;
        color: #fff;
    }
    
    
    
    @media screen and (max-width: 768px) {
    
        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: 25%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    }
    
    @media screen and (max-width: 768px) {

        .bg-content{
            padding: 10px;
            background:#ccc;
            padding-right: 20px;
            width: 100%;
            border-radius: 10px;
            height: auto;
        }
    
        .background {
            background: #fbd43a;
            width: 100%;
            height: 100vh;
    
            .container {
                background: #fbd43a;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 30px;
    
                .card {
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
    
                    .menu-bar {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        height: 50px;
                        background: #000;
                        padding: 10px;
                        border-radius: 4px;
                    }
    
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        padding: 4px;
                        border-radius: 4px;
                        margin-top: 15px;
                    }
    
                    .tab-staff {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 0px;
                        border-right: solid 2px #fff;
                        border-bottom: solid 1px #fff;
                        color: #fff;
                        cursor: pointer;
                    }
    
                    .tab-clients {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .tab-housekeepings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .users-container {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .users-container2 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .users-container3 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .search-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 10px !important;
                    }
                    .searchdate {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                    }
        
                    .btn-search1 {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        background: #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                        color: #fff;
                    }
    
                    .rm-menu {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .rm-category:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .add-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .users-content {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .rm-menu2 {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .add-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .users-content2 {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .add-room {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-room2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-rm-center {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-rm-center2 {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-user {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .add-user2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .input {
                        width: 100%;
                        height: 30px;
                    }
    
                    .btn-primarys {
                        margin-top: -10px;
                        width: 100%;
                        height: 30px;
                    }
    
                    img {
                        width: 50px;
                    }
                }
    
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: left;
                    color: #fff;
                }
    
            }
        }
    }

    </style>
    