<template>
    <div class="background">
        <div class="header navbar navbar-default">
            <div>
                <router-link to="/"> <img :src="logo_url" alt="Logo" width="40"> </router-link>
            </div>
            <div>
                <i class="fas fa-phone"></i> {{ company_phone }} |  <i class="fas fa-envelope"></i> {{ company_email}}
            </div>
        </div>
        <div class="content">
            <div class="login">
                <h4> Forgot password</h4>
                <div class="phone">
                    <label>Email</label>
                    <input type="text" placeholder="Your Email" class="phone-input" v-model="email_address">
                </div>
                <div class="login-button">
                    <button class="form-control btn-login" @click="submit()" v-if="!loading"> Submit
                    </button>
                    <button class="form-control btn-login" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <span class="forgot-password"><router-link to="/"><i class="fa fa-arrow-left"></i> Back</router-link></span>
                </div>
            </div>
            <div class="banner">
                <img :src="banner_url" alt="banner">
            </div>
        </div>
        <div class="footer">
           <p class="copyright">© Belmaz Multipurpose Cooperative Society, 2023. All rights reserved.</p> 
        </div>
    </div>
</template>

<script>

import swal from 'sweetalert2';
import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css';
import VueCountryCode from "vue-country-code";
//import axios from 'axios';

Vue.use(VueTelInput)
Vue.use(VueCountryCode)
export default {
    data: () => ({
        logo_url: process.env.VUE_APP_API_LOGO,
        banner_url: process.env.VUE_APP_API_BANNER,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        pusher_app_key: '',
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        email_address: '',
        user_password: '',
        title_designation: '',
        company_name: '',
        user_country: '',
        country_dial_code: 0,
        user_state: '',
        user_city: '',
        user_date: '',
        user_month: '',
        user_day: '',
        user_gender: '',
        notify_birthday: 1,
        previewImage: null,
        user_role: '',
        error_code: '',
        message: '',
        status: '',
        response: '',
        loading: false,

        user_id: '',
        signUp: true,
        signIn: false,
      
    }),

    beforeMount() {

    },

    mounted() {
        localStorage.removeItem("wertyuiojhdfghhdrtfyguh")
    },

    methods: {

        async submit() {

            if (!this.email_address) {

                swal.fire({
                    icon: 'warning',
                    title: 'Error...',
                    width: 450,
                    height: 4,
                    text: 'Please enter your email address',
                })
                return;
            }

            try {

                this.loading = true;

                this.response = await this.$http.post('/forgot-password', {
                    email_address: this.email_address,
                });

                this.loading = false;

                if (!this.response.data.success) {

                    swal.fire({
                        icon: "error",
                        title: "Error",
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    });

                    return;

                } else {

                    swal.fire({
                        icon: "success",
                        title: "Success",
                        width: 450,
                        height: 5,
                        text: this.response.data.message,
                    });

                    return;
                }

            } catch (err) {
                console.log(err);
            }


        },


    },

}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

.header {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    font-size: 12px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
    color: #00802f;
}

.e-waiter {
    font-weight: 800;
    color:#fff;
    text-shadow: 2px 1px #000;
}

.background {
    height: 100vh;
    background: #00802f12;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: left;
    border: solid 1px #000;
    border-radius: 10px;
    width: 80%;
    padding: 40px;
    margin-left: 50px;
    padding-bottom: 20px;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);

    h4 {
        font-weight: 705;
        margin-top: 10px;
        text-align: center;
        font-family: Mulish;
    }

    .phone {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 25px;

        .phone-input {
            border-radius: 4px;
            height: 50px;
            padding: 10px;
            background: #fff;
        }
    }

    .password {
        display: flex;
        flex-direction: column;
        justify-content: left;

        .password-input {
            border-radius: 4px;
            height: 50px;
            padding: 10px;
            background: #fff;
        }
    }

    .login-button {
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin-top: 10px;

        .btn-login {
            border-radius: 4px;
            height: 50px;
            padding: 10px;
            background: #eb8313;
            color: #fff
        }
    }
    .forgot-password{
        font-size: 14px;
        margin-top: 5px;
    }

    .btn-login:hover {
        background: #00802f;
    }
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    img {
        text-align: center;
        width: 50%;
    }
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
    font-weight: 600;
    border-top: 1px solid #00802f;
    color: #fff;
    background: #00802f;
    text-align: center;
    bottom: 0;
    position: fixed;
    width: 100%;
}
.copyright{
    margin-top: 10px;
    color: #fff;
}


@media screen and (max-width: 768px) {

.header{
    font-size: 10px;
}   
.e-waiter {
    font-weight: 800;
    color:#fff;
    text-shadow: 2px 1px #000;
    font-size: 16px;
}

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 86%;
    }

    .login {
        display: flex;
        flex-direction: column;
        border: solid 1px #000;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);

        h4 {
            font-weight: 705;
            margin-top: 10px;
            text-align: center;
            font-size: 15px;
            font-family: Mulish;
        }

        .phone {
            display: flex;
            flex-direction: column;
            justify-content: left;
            margin-top: 25px;
            font-size: 11px;

            label {
                font-size: 12px;
                font-family: Mulish;
            }

            .phone-input {
                border-radius: 4px;
                height: 40px;
                padding: 7px;
                background: #fff;
            }
        }

        .password {
            display: flex;
            flex-direction: column;
            justify-content: left;
            font-size: 11px;

            label {
                font-size: 12px;
                font-family: Mulish;
            }

            .password-input {
                border-radius: 4px;
                height: 40px;
                padding: 7px;
                background: #fff;
            }
        }

        .login-button {
            display: flex;
            flex-direction: column;
            justify-content: left;
            margin-top: 10px;

            .btn-login {
                border-radius: 4px;
                height: 50px;
                padding: 10px;
                background: #FFA500;
                color: #fff
            }
        }

        .btn-login:hover {
            background: #00802f;
        }
    }

    .banner {
        display: none;
        flex-direction: column;
        align-items: center;
        width: 100%;

        img {
            margin-left: 30px;
            margin-top: 30px;
            width: 94%;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        font-family: Mulish;
        color: #fff;
        background: #00802f;
        margin-bottom: 0px;
        text-align: center;
        bottom: 0;
        position: fixed;
        width: 100%;
    }

    .copyright{
        margin-top: 10px;
        color: #fff;
    }

}</style>