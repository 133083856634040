<template>
    <div class="background">
        <div class="bg-content">
                <div class="menu-bar">
                    <h2> <i class="fas fa-tools"></i> &nbsp;Setup</h2>
                </div>
                <div class="transaction-container" v-if="investment_status">
                    <p><span class="rm-menu rm-category" @click="investment_type()"><i class="fa fa-plus"></i> Pool System</span> | <span class="rm-menu add-rm" @click="savings_type()"><i class="fa fa-plus"></i> Savings Type</span> | <span class="rm-menu list-rm" @click="branches()"><i class="fa fa-plus"></i> Branches</span></p>
                    <div class="transaction-content">
                        <p><i class="fas fa-tools"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
                        <div v-if="show_investment_type">
                            <div class="add-category">
                                <label for="" style="font-size:10px">Package: &nbsp;</label>
                                <input type="text" class="form-control inputs" placeholder="Enter Investment package" v-model="category_name" required>&nbsp;
                                <button class="form-control btn-primarys" v-if="!loading" @click="addinvestment_category()">
                                    Save </button>
                                <button class="form-control btn-primarys" type="button" disabled v-if="loading">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Package </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in categories" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.category_name }}
                                            </td>
                                            <td style="font-weight: 400;">
                                                <span class="category-edit" data-toggle="tooltip" title="Edit Item" @click="editItem(data.category_id, data.category_name)"><i class="fa fa-edit"></i>&nbsp;</span>
                                                <span class="category-delete" @click="delete_package(data.category_id)" data-toggle="tooltip" title="Delete Item"><i class="fa fa-trash"></i></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-if="show_savings_type">
                            <div class="add-category">
                                <label for="" style="font-size:10px">Savings Type: &nbsp;</label>
                                <input type="text" class="form-control inputs" placeholder="Enter Savings Type" v-model="savings_category_name" required>&nbsp;
                                <button class="form-control btn-primarys" v-if="!loading2" @click="addsavings_category()">
                                    Save </button>
                                <button class="form-control btn-primarys" type="button" disabled v-if="loading2">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                            </div>
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Type </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in savings_categories" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.category_name }}
                                            </td>
                                            <td style="font-weight: 400;">
                                                <span class="category-edit" data-toggle="tooltip" title="Edit Item" @click="editSavingstype(data.category_id, data.category_name)"><i class="fa fa-edit"></i>&nbsp;</span>
                                                <span class="category-delete" @click="delete_savingstype(data.category_id)" data-toggle="tooltip" title="Delete Item"><i class="fa fa-trash"></i></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div v-if="show_branches">
                            <label for="" style="font-size: 10px">Branch name: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter branch name" v-model="branch_name">&nbsp;

                            <label for="" style="font-size: 10px">Address: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter address" v-model="branch_address">&nbsp;

                            <label for="" style="font-size: 10px">Phone: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter phone number" v-model="branch_phone">&nbsp;

                            <label for="" style="font-size: 10px">Email: &nbsp;</label>
                            <input type="text" class="form-control input" placeholder="Enter branch email" v-model="branch_email">&nbsp;
                            <label class="form-check-label" style="margin-left:20px; font-size:12px">
                                <input type="checkbox" class="form-check-input" value="" v-model="branch_head"> Is head office ?
                            </label>
                            <br><br>
                            <button class="form-control btn-primarys" v-if="!loading17" @click="addbranches()">
                                Save
                            </button>
                            <button class="form-control btn-primarys" type="button" disabled v-if="loading17">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Name </th>
                                            <th>Address </th>
                                            <th>Phone </th>
                                            <th>Email </th>
                                            <th>Status </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in branchList" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.branch_name }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.address }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.phone }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.email }}
                                            </td>
                                            <td style="font-weight: 400; width:auto" v-if="data.is_head == 1">
                                                Head Office
                                            </td>
                                            <td style="font-weight: 400; width:auto" v-if="data.is_head == 0">
                                               N/A
                                            </td>
                                           
                                            <td style="font-weight: 400;">
                                                <span class="category-edit" data-toggle="tooltip" title="Edit Item" @click="editBranch(data.branchid, data.branch_name, data.address, data.phone, data.email, data.is_head)"><i class="fa fa-edit"></i></span> &nbsp; &nbsp;
                                                <span class="category-delete" @click="delete_branch(data.branchid)" data-toggle="tooltip" title="Delete Item"><i class="fa fa-trash"></i></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    
        <!-- Edit item Modal -->
        <div v-if="edit_item">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile" @click="closeMenu"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        Edit Pool
                                    </h3>
                                    <div class="order-content">
                                        <input type="hidden" class="form-control " v-model="item_id">
                                        <p class="space-top">Title</p>
                                        <input type="text" class="form-control edit-input" v-model="edit_category_name">
    
                                        <button class="btn btn-success btn-block input-button" @click="updateItem" v-if="!loading15">Update </button>
                                        <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading15">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Updating...
                                        </button>
    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close" @click="closeMenu"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    
         <!-- Edit savings type Modal -->
         <div v-if="edit_savings">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile" @click="closeMenu"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        Edit Savings Type
                                    </h3>
                                    <div class="order-content">
                                        <input type="hidden" class="form-control " v-model="item_id2">
                                        <p class="space-top">Title</p>
                                        <input type="text" class="form-control edit-input" v-model="edit_category_name2">
                                           
                                        <button class="btn btn-success btn-block input-button" @click="updateSavingsType" v-if="!loading16">Update </button>
                                        <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading16">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Updating...
                                        </button>
    
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close" @click="closeMenu"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    
        <!-- Edit room Modal -->
        <div v-if="edit_branch_data">
                <transition name="modal">
                    <div class="modal-mask2">
                        <div class="modal-wrapper col-md-5" style="margin:auto">
                            <div class="row">
                                <div class="col-md-1">
                                    <a title="Close" class="modal-close-mobile2" @click="closeMenu2"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    <br>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <h3>
                                            Edit Branch
                                        </h3>
                                        <div class="order-content">
                                                
                                            <label for="" style="font-size:12px">Branch name: &nbsp;</label>
                                            <input type="text" class="form-control edit-input" v-model="edit_branch_name">&nbsp;
                                            <label for="" style="font-size:12px">Address: &nbsp;</label>
                                            <input type="text" class="form-control edit-input" v-model="edit_branch_address">&nbsp;
                                            <label for="" style="font-size:12px">Phone: &nbsp;</label>
                                            <input type="text" class="form-control edit-input" v-model="edit_branch_phone">&nbsp;
                                            <label for="" style="font-size:12px">Email: &nbsp;</label>
                                            <input type="text" class="form-control edit-input" v-model="edit_branch_email">&nbsp;
                                            <label class="form-check-label" style="margin-left:20px; font-size:12px">
                                                <input type="checkbox" class="form-check-input" value="" v-model="edit_branch_head"> Is branch head ?
                                            </label>
    
                                            <button class="btn btn-success btn-block input-button" @click="updateBranch" v-if="!loading4"> Update </button>
                                            <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading4">
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Updating...
                                            </button>
    
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div>
                                        <a href="#" title="Close" class="modal-close2" @click="closeMenu2"> <i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </transition>
        </div>      
    
    </div>
    </template>
    
    <script>
    //import Vue from 'vue'
    import swal from 'sweetalert2';
    //import $ from "jquery";
    
    export default {
    
        data() {
            return {
      
                report: [],
                app_role: '',
                cfirstname: '',
                clastname: '',
                cusername: '',
                userid: '',
                check_items: [],
                customer_order: false,
               
                investment_status: true,
                savings_status: false,
                housekeeping_status: false,
                process_payment: false,
                report_status: false,
                edit_item: false,
                edit_savings: false,
                category_name: '',
                duration: '',
                rate: '',
                savings_category_name: '',
                saving_duration: '',
                saving_rate: '',
                categories: [],
                edit_category_name: '',
                edit_duration: '',
                edit_rate: '',
                edit_category_name2: '',
                edit_saving_duration: '',
                edit_saving_rate: '',
                item_id: '',
                item_id2: '',
                loading: false,
                loading2: false,
                loading3: false,
                loading4: false,
                loading5: false,
                loading6: false,
                loading7: false,
                loading8: false,
                loading9: false,
                loading10: false,
                loading11: false,
                loading12: false,
                loading13: false,
                loading14: false,
                loading15: false,
                loading16: false,
                loading17: false,
                show_investment_type: false,
                show_savings_type: false,
                show_branches: true,
                show_list_reservation: true,
                show_guest_information: false,
                room_extension_status: false,
                panel_name: "List Branches",
                panel_header: "Setup",
                room_type: '',
                room_number: '',
                bed_type: '',
                room_capacity: '',
                room_description: '',
                room_amount: '',
                rooms: [],
                searchby_roomno: '',
                vinvestment_status: '',
                gender: '',
                url: '',
                reservations: [],
                reservation_search: '',
                guest_status: '',
                update_guest_status: false,
                reservation_id: '',
                guest_status_input: '',
                guest_roomid: '',
                guest_phone_number: '',
                guest_info: [],
                edit_quest_id: '',
                edit_firstname: '',
                edit_middlename: '',
                edit_lastname: '',
                edit_gender: '',
                edit_phone: '',
                edit_email: '',
                edit_address: '',
                edit_guest_data: false,
                checkout_date: '',
                reservation_summary: true,
                occupancy_report: false,
                revenue_report: false,
                roomtype_report: false,
                vguest_status: '',
                date_to: '',
                date_from: '',
                date_to2: '',
                date_from2: '',
                date_to3: '',
                date_from3: '',
                date_to4: '',
                date_from4: '',
                summaryReservation: [],
                response: '',
                occupancyReport: [],
                revenueReports:[],
                roomtypeReports:[],
                bookedStatus: '',
                extension_amount: '',
                extension_date: '',
                extension_reservation_id:'',
                ext_checkout_date: '',
                list_payments: false,
                guestPayments: [],
                payment_date_from: '',
                payment_date_to: '',
                guest_fullname: '',
                new_payment_status: false,
                guest_found: false,
                guest_name: '',
                guest_payments: [],
                payment_description: '',
                guestLists: [],
    
                savings_categories: [],
                savings_type_name: '',
                branch_name: '',
                branch_address: '',
                branch_phone: '',
                branch_email: '',
                branch_head: '',
                branchList: [],
                edit_branchid: '',
                edit_branch_name: '',
                edit_branch_address: '',
                edit_branch_phone: '',
                edit_branch_email: '',
                edit_branch_head: '',
                edit_branch_data: false,
                durations: [],
                modes: [],

    
            };
        },
    
        beforeMount() {
    
        },
    
        mounted() {
            this.app_role = localStorage.getItem("app_status");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");
    
            this.fetchAllPackages();
            this.fetchAllSavingsType();
            this.fetchAllBranches();
            this.fetchAllDurations();
            this.fetchAllSavingsDurations();
    
        },
    
        created() {
    
        },
        beforeDestroy() {
            clearInterval(this.timerInterval);
        },
        computed: {
                
        },
    
        methods: {
        
            tabInvestment() {
                this.investment_status = true
                this.savings_status = false
                this.housekeeping_status = false
                this.report_status = false
                this.process_payment = false
                this.panel_header = "Setup"
                this.panel_name = "List Branches"
    
            },
        
            async addinvestment_category() {
    
                try {
    
                    if (!this.category_name) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter investment package',
                        })
                        return;
                    }
                       
                    this.loading = true;
    
                    this.response = await this.$http.post('/save-investment-package', {
                        category_name: this.category_name,
                        userid: this.userid
                    });
    
                    this.loading = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                    }
                    this.category_name = "";
                    this.duration = "",
                    this.rate = "",
                    this.fetchAllPackages();
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async addsavings_category() {
                try {
    
                    if (!this.savings_category_name) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter savings type',
                        })
                        return;
                    }
    
                    this.loading2 = true;
    
                    this.response = await this.$http.post('/save-savings-type', {
                        category_name: this.savings_category_name,
                        userid: this.userid
                    });
    
                    this.loading2 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                    }
                    this.savings_type_name = "";
                    this.fetchAllSavingsType();
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchAllPackages() {
                try {
                    const response = await this.$http.get('/fetch-investment-package', )
                    this.categories = response.data.categories;
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async fetchAllSavingsType() {
                try {
                    const response = await this.$http.get('/fetch-savings-type', )
                    this.savings_categories = response.data.categories;
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async delete_package(category_id) {
                let msg = confirm("               You are about to delete this package. Are you sure?");
    
                if (msg == true) {
                    try {
    
                        this.response = await this.$http.post('/delete-investment-package', {
                            category_id: category_id,
                        });
    
                        if (!this.response.data.success) {
                            swal.fire({
                                icon: 'error',
                                width: 450,
                                height: 5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                        } else {
    
                            swal.fire({
                                icon: 'success',
                                width: 450,
                                height: 5,
                                title: "Success",
                                text: this.response.data.message,
                            });
                        }
                        this.fetchAllPackages()
    
                    } catch (err) {
                        console.log(err);
                    }
                }
            },
    
            async delete_savingstype(category_id) {
                let msg = confirm("               You are about to delete type. Are you sure?");
    
                if (msg == true) {
                    try {
    
                        this.response = await this.$http.post('/delete-savings-type', {
                            category_id: category_id,
                        });
    
                        if (!this.response.data.success) {
                            swal.fire({
                                icon: 'error',
                                width: 450,
                                height: 5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                        } else {
    
                            swal.fire({
                                icon: 'success',
                                width: 450,
                                height: 5,
                                title: "Success",
                                text: this.response.data.message,
                            });
                        }
                        this.fetchAllSavingsType()
    
                    } catch (err) {
                        console.log(err);
                    }
                }
            },
    
            editItem(itemid, title) {
                try {
                    this.item_id = itemid
                    this.edit_category_name = title
    
                    this.edit_item = true
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            async updateItem() {
                try {
    
                    this.loading15 = true;
    
                    this.response = await this.$http.post('/update-investment-package', {
                        itemid: this.item_id,
                        category_name: this.edit_category_name,
                    });
    
                    this.loading15 = false;
    
                    if (!this.response.data.success) {
    
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
    
                        this.edit_item = false
                        this.edit_category_name = ''
    
                        this.fetchAllPackages()
    
                        return;
    
                    }
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            editSavingstype(itemid, title) {
                try {
                    this.item_id2 = itemid
                    this.edit_category_name2 = title
                    this.edit_savings = true
    
                } catch (err) {
                    console.log(err);
                }
            },
            async updateSavingsType() {
                try {
    
                    this.loading16 = true;
    
                    this.response = await this.$http.post('/update-savings-type', {
                        itemid: this.item_id2,
                        category_name: this.edit_category_name2,
 
                    });
    
                    this.loading16 = false;
    
                    if (!this.response.data.success) {
    
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;
    
                    } else {
    
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
    
                        this.edit_savings = false
                        this.edit_category_name2 = ''
    
                        this.fetchAllSavingsType()
    
                        return;
    
                    }
    
                } catch (err) {
                    console.log(err);
                }
            },
    
            closeMenu() {
                this.edit_item = false
                this.edit_savings = false
            },
    
            investment_type() {
                this.show_investment_type = true
                this.show_savings_type = false
                this.show_branches = false
                this.panel_name = "Pool System"
            },
    
            savings_type() {
                this.show_savings_type = true
                this.show_branches = false
                this.show_investment_type = false
                this.panel_name = "Savings Type"
            },
    
            branches() {
                //this.fetchAllRooms()
                this.show_branches = true
                this.show_savings_type = false
                this.show_investment_type = false
                this.panel_name = "List Branches"
            },

            async addbranches() {
                try {

                    if (!this.branch_name) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter branch name',
                        })
                        return;
                    }
                    if (!this.branch_address) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter branch address',
                        })
                        return;
                    }
                    if (!this.branch_phone) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter branch phone number',
                        })
                        return;
                    }

                    this.loading17 = true;

                    this.response = await this.$http.post('/save-branches', {
                        branch_name: this.branch_name,
                        branch_address: this.branch_address,
                        branch_phone: this.branch_phone,
                        branch_email: this.branch_email,
                        branch_head: this.branch_head,
                        userid: this.userid
                    });

                    this.loading17 = false;

                    if (!this.response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width: 450,
                            height: 5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                    } else {

                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Success",
                            text: this.response.data.message,
                        });
                    }
                    this.branch_name = ""
                    this.branch_address = ""
                    this.branch_phone = ""
                    this.branch_email = ""
                    this.branch_head = ""
                    this.fetchAllBranches();

                } catch (err) {
                    console.log(err);
                }
            },

            async fetchAllBranches() {
                try {
                    const response = await this.$http.get('/fetch-branches', )
                    this.branchList = response.data.branches;
    
                } catch (err) {
                    console.log(err);
                }
            },

            editBranch(branchid, name, address, phone, email, ishead) {
                try {
                    this.edit_branchid = branchid
                    this.edit_branch_name = name
                    this.edit_branch_address = address
                    this.edit_branch_phone = phone
                    this.edit_branch_email = email
                    this.edit_branch_head = ishead
    
                    this.edit_branch_data = true
    
                } catch (err) {
                    console.log(err);
                }
            },
            closeMenu2(){
                this.edit_branch_data = false
            },
            async updateBranch() {
            try {

                this.loading4 = true;

                this.response = await this.$http.post('/update-branch', {
                    branchid: this.edit_branchid,
                    name: this.edit_branch_name,
                    address: this.edit_branch_address,
                    phone: this.edit_branch_phone,
                    email: this.edit_branch_email,
                    branch_head: this.edit_branch_head,
                    userid: this.userid
                });

                this.loading4 = false;

                if (!this.response.data.success) {
                    swal.fire({
                        icon: 'error',
                        width: 450,
                        height: 5,
                        title: "Error",
                        text: this.response.data.message,
                    });
                    return;

                } else {

                    swal.fire({
                        icon: 'success',
                        width: 450,
                        height: 5,
                        title: "Success",
                        text: this.response.data.message,
                    });
                    this.fetchAllBranches()
                    this.edit_branch_data = false

                }
               
            } catch (err) {
                console.log(err);
            }
            },
            async delete_branch(branchid) {
                    let msg = confirm("               You are about to delete this branch. Are you sure?");
        
                    if (msg == true) {
                        try {
        
                            this.response = await this.$http.post('/delete-branch', {
                                branchid: branchid,
                            });
        
                            if (!this.response.data.success) {
                                swal.fire({
                                    icon: 'error',
                                    width: 450,
                                    height: 5,
                                    title: "Error",
                                    text: this.response.data.message,
                                });
                                return;
        
                            } else {
        
                                swal.fire({
                                    icon: 'success',
                                    width: 450,
                                    height: 5,
                                    title: "Success",
                                    text: this.response.data.message,
                                });
                            }
                            this.fetchAllBranches()
        
                        } catch (err) {
                            console.log(err);
                        }
                    }
            },
            async fetchAllDurations() {
                    try {
                        const response = await this.$http.get('/fetch-durations', )
                        this.durations = response.data.durations;
        
                    } catch (err) {
                        console.log(err);
                    }
            },
            async fetchAllSavingsDurations() {
                    try {
                        const response = await this.$http.get('/fetch-savings-mode', )
                        this.modes = response.data.durations;
        
                    } catch (err) {
                        console.log(err);
                    }
            },
    
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 30%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 65%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask4 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .background {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .bg-content{
        padding: 10px;
        background:#ccc;
        padding-right: 20px;
        width: 100%;
        border-radius: 10px;
        height: auto;
    }
    
    
        .menu-bar {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            font-family: Mulish;
            box-sizing: border-box;
            height: 50px;
            background: #000;
            padding: 10px;
            border-radius: 4px;
        }
    
        .tab {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 95%;
            font-family: Mulish;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 4px;
            margin-top: 15px;
        }
    
        .tab-investments {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            border-bottom: solid 1px #fff;
            color: #fff;
            cursor: pointer;
        }
    
        .tab-investments:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .tab-savings {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
        .tab-savings:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
        .tab-loans {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
        .tab-loans:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
        .tab-housekeepings {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-housekeepings:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .tab-reports {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            //border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-reports:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .transaction-container {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .transaction-container2 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .transaction-container3 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .search-input {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            font-size: 10px !important;
        }
    
        .search-name {
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-align: left;
            color: #000;
            margin-bottom: -2px;
        }
    
        .orderid {
            width: 10%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .searchdate {
            width: 13%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .btn-search1 {
            width: 8%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            background: #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
            color: #fff;
        }
    
        .rm-menu {
            margin: 4px;
        }
    
        .rm-category:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .rm-category3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .add-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .transaction-content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu2 {
            margin: 4px;
        }
    
        .add-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .transaction-content2 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 95%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .transaction-content3 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 95%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu3 {
            margin: 4px;
        }
    
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .guest-information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .guests {
                font-size: 10px;
                font-weight: 400 !important;
                cursor: pointer;
            }
    
            .guests:hover {
                text-decoration: underline;
            }
        }
    
        .add-category {
            display: flex;
            flex-direction: row;
            justify-content: center;
            
        }
    
        .add-category2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    
        .add-room {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-room2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-rm-center {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .add-rm-center2 {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .list-category {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            height: 280px;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .list-category2 {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .inputs {
            border: solid 0px #000;
            border-radius: 4px;
            width: 50%;
            height: 30px;
            font-size: 12px;
        }

        .input {
            border: solid 0px #000;
            border-radius: 4px;
            width: 100%;
            height: 30px;
            font-size: 12px;
        }
    
        .btn-primarys {
            background: #FFA500;
            font-size: 12px;
            height: 30px;
            width: 20%;
    
        }
    
    img {
        width: 50px;
        border-radius: 40px;
    }
    
    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        text-align: left;
        color: #fff;
    }
    
    
    
    @media screen and (max-width: 768px) {
    
        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: 25%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 1px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 1px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    }
    
    @media screen and (max-width: 768px) {
        .background {
            background: #fbd43a;
            width: 100%;
            height: 100vh;
    
            .container {
                background: #fbd43a;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 30px;
    
                .card {
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
    
                    .menu-bar {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        height: 50px;
                        background: #000;
                        padding: 10px;
                        border-radius: 4px;
                    }
    
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        padding: 4px;
                        border-radius: 4px;
                        margin-top: 15px;
                    }
    
                    .tab-investments {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 0px;
                        border-right: solid 2px #fff;
                        border-bottom: solid 1px #fff;
                        color: #fff;
                        cursor: pointer;
                    }
    
                    .tab-savings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        border-right: solid 2px #fff;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .tab-housekeepings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .transaction-container {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .transaction-container2 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .transaction-container3 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .search-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 10px !important;
                    }
                    .searchdate {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                    }
        
                    .btn-search1 {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        background: #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                        color: #fff;
                    }
    
                    .rm-menu {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .rm-category:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .add-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .transaction-content {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .rm-menu2 {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .add-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .transaction-content2 {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .add-room {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-room2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-rm-center {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-rm-center2 {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-category {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        .inputs {
                            border: solid 0px #000;
                            border-radius: 4px;
                            width: 100%;
                            height: 30px;
                            font-size: 12px;
                        }
                    }
    
                    .add-category2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                   
    
                    .input {
                        width: 100%;
                        height: 30px;
                    }
    
                    .btn-primarys {
                        margin-top: -10px;
                        width: 100%;
                        height: 30px;
                    }
    
                    img {
                        width: 50px;
                    }
                }
    
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: left;
                    color: #fff;
                }
    
            }
        }
    }
    </style>
    