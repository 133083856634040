<template>
    <div class="background">
       
        <div class="container">

            <div class="card">
                <div class="menu-bar">
                    <h2>
                        <img src="../../assets/receipts.png" alt="" width="20px"> Receipts
                    </h2>
                </div>
                
                <p>List of orders payment has been confirmed</p>
                <span style="margin-bottom: 10px; width:20px; display:none" id="take-order"><button class="btn btn-info"
                        @click="viewOrder()" style="width:auto !important; height:auto !important; opacity: 0.9"> <img
                            src="../../assets/add_items.png" alt="" width="20px"> Preview Order</button></span>
                <div class="view-content">
                    <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                        <thead>
                            <tr style="font-size:9px">
                                <th>SN</th>
                                <th>Waiter</th>
                                <th>Order Id </th>
                                <th>Customer ID</th>
                                <th>Date Ordered </th>
                                <th>Amount(NGN)</th>
                                <th>Payment Status</th>
                                <th>Date Confirmed</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in options" :key="index" style="font-size:9px">
                                <td style="font-weight:400; width:4%">
                                    {{ index + 1 }}
                                </td>
                                <td style="font-weight: 400; width:20%" v-if="data.firstname == NULL">
                                    Selfserve
                                </td>
                                <td style="font-weight: 400; width:20%" v-else>
                                    {{ data.firstname }} {{ data.lastname }}
                                </td>
                                <td style="font-weight: 400;">
                                    {{ data.order_id }}
                                </td>
                                <td style="font-weight: 400;">
                                    {{ data.customer_name }}
                                </td>
                                <td style="font-weight: 400;">
                                    {{ new Date(data.date_ordered).toUTCString() }}
                                </td>
                                <td style="font-weight: 400;">
                                    {{ data.tamount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                </td>
                                <td style="font-weight: 400;">
                                    <span class="badge badge-success"> Payment
                                        confirmed</span>
                                </td>
                                <td style="font-weight: 400;">
                                    {{ new Date(data.date_confirmed).toUTCString() }}
                                </td>
                                <td>
                                    <div style="display: flex; flex-direction:row;">
                                        <span v-if="data.payment_status == 1"></span>
                                        <span v-if="data.payment_status == 0">
                                            <button style="margin-right:4px; width: auto; font-size:10px"
                                                class="btn btn-success btn-sm" @click="confirmPayment(data.orderID)"><i
                                                    class="fa fa-check"></i> Confrm payment</button>
                                        </span>
                                        <button style="width: auto; font-size:10px" class="btn btn-warning btn-sm"
                                            @click="viewOrders(data.order_id)"><i class="fa fa-shopping-cart"
                                                aria-hidden="true"></i> View orders</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Customer order Modal -->
        <div v-if="customer_order">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile" @click="closeMenu"> <i
                                        class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">

                                    <p class="modal-logo">
                                    </p>
                                    <h3>
                                        Customer Order
                                    </h3>
                                    <div class="order-content">
                                        <table class="table table-bordered" style="font-size:10px;">
                                            <thead>
                                                <tr style="font-size:9px">
                                                    <th>SN</th>
                                                    <th>Items</th>
                                                    <th>Qty </th>
                                                    <th>Price(NGN) </th>
                                                    <th>Amount(NGN) </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in customer_items" :key="index" style="font-size:9px">
                                                    <td>
                                                        {{ index + 1 }}
                                                    </td>
                                                    <td style="font-weight: bold; height:40px">
                                                        {{ data.title }} - {{ data.description }}
                                                    </td>
                                                    <td>
                                                        {{ data.orderQty }}
                                                    </td>
                                                    <td>
                                                        {{ data.price.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}
                                                    </td>
                                                    <td>
                                                        {{ data.total_amount.toLocaleString('en-NG', {
                                                            minimumIntegerDigits:
                                                                2
                                                        }) }}
                                                    </td>
                                                </tr>
                                                <tr style="font-weight: bold; font-size: 11px">
                                                    <td colspan="4">Total:</td>
                                                    <td> N {{ total_amount.toLocaleString('en-NG', {
                                                        minimumIntegerDigits:
                                                            2
                                                    }) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <!-- <div class="row">
                                        <button class="btn-login" @click="processOrder()" v-if="!loading"> <i class="fas fa-plus"></i>
                                            &nbsp;Send order for processing </button>
                                        <button class="btn-login" type="button" disabled v-if="loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            &nbsp;Sending...
                                        </button>
                                    </div> -->

                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close" @click="closeMenu"> <i
                                            class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
//import Vue from 'vue'
import swal from 'sweetalert2';
//import $ from "jquery";

export default {

    data() {
        return {
            options: [],
            app_role: '',
            cfirstname: '',
            clastname: '',
            cusername: '',
            userid: '',
            check_items: [],
            customer_order: false,
            loading: false,
            customer_items: [],
            selected_items: '',
            total_amount: '',
            quantity: [],
            customer_name: '',
            customer_phone: '',
            timerInterval: '',
        };
    },

    beforeMount() {

    },

    mounted() {

        this.app_role = localStorage.getItem("app_status");
        this.cfirstname = localStorage.getItem("firstname");
        this.clastname = localStorage.getItem("lastname");
        this.cusername = localStorage.getItem("username");
        this.userid = localStorage.getItem("userid");
        this.fetchAllReceipts();
    },
    created() {
    // Call the fetchAllOrders() initially when the component is created
    this.fetchAllReceipts();

    // Set an interval to call fetchAllOrders() every 5 seconds
    this.timerInterval = setInterval(this.fetchAllReceipts, 5000);
    },
    beforeDestroy() {
        // Clear the interval when the component is about to be destroyed
        clearInterval(this.timerInterval);
    },

    methods: {

        checkItem() {
            if (this.check_items != '') {
                document.getElementById('take-order').style.display = "block";
            }
            else {
                document.getElementById('take-order').style.display = "none";
            }
        },

        async viewOrders(orderid) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/view-orders', {
                    orderid: orderid,
                });
                this.customer_items = this.response.data.orders
                this.customer_order = true
                this.loading = false;

                this.calculateOrder();


            } catch (err) {
                console.log(err);
            }
        },

        async confirmPayment(orderid) {

            let msg = confirm("               Are you sure, you want to confirm this payment?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/confirm-payment', {
                    orderid: orderid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
                this.fetchAllOrders();


            } catch (err) {
                console.log(err);
            }
        }
        },

        async processOrder() {
            try {


                if (!this.customer_name) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width: 450,
                        height: 5,
                        text: 'Please enter customer',
                    })
                    return;
                }
                if (!this.total_amount) {
                    swal.fire({
                        icon: 'warning',
                        title: 'Error...',
                        width: 450,
                        height: 5,
                        text: 'Please calculate customer order',
                    })
                    return;
                }

                this.loading = true;

                this.response = await this.$http.post('/process-order', {
                    items: this.customer_items,
                    customer_name: this.customer_name,
                    customer_phone: this.customer_phone,
                    orderid: this.getRandomAlphanumeric(10)
                });

                this.loading = false;

                if (!this.response.data.success) {

                    swal.fire({
                        icon: 'error',
                        width: 450,
                        height: 5,
                        title: "Error",
                        text: this.response.data.message,
                    });
                    return;

                } else {

                    swal.fire({
                        icon: 'success',
                        width: 450,
                        height: 5,
                        title: "Success",
                        text: this.response.data.message,
                    });
                }

                this.customer_order = false;
                this.check_items = []
                this.customer_items = []
                this.total_amount = ''
                this.customer_name = ''
                this.customer_phone = ''

            } catch (err) {
                console.log(err);
            }
        },

        closeMenu() {
            this.customer_order = false
            this.check_items = []
            this.customer_items = []
            this.total_amount = 0
            document.getElementById('take-order').style.display = "none";

        },
        calculateOrder() {
            this.total_amount = this.customer_items.reduce(
                (accumulator, item) => accumulator + item.total_amount,
                0
            );
    },

        async fetchAllReceipts() {
            try {
                const response = await this.$http.get('/view-receipts',)
                this.options = response.data.menu;

            } catch (err) {
                console.log(err);
            }
        },

    }
}
</script>
    
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #2b0c17;
        background: #FFF;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
    }

    .welcome {
        margin-top: 8px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 23px;
        justify-content: center;
        color: #FFFFFF;

    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

    .counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        font-family: Mulish;
    }

    .btn-counter {
        padding: 6px;
        font-weight: bold;
        font-size: 10px;
        border: 1px solid #fbd43a;
        margin: 5px;
        width: 20%;
        font-family: Mulish;
    }

    .order-content {
        height: 200px;
        overflow-y: scroll;
    }

}

.customer-input {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    font-family: Mulish;
}

.btn-login {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 52px;
    background: #000;
    border-radius: 8px;
    margin: 10px;
    color: #fff;
    width: 100%;
    font-size: 10px;
    margin-bottom: 20px;
    font-family: Mulish;

}

.btn-login:hover {
    background: #21182f;
    border: 1px solid #21182f;
    font-family: Mulish;
}

a {
    text-decoration: none;
}

body {
    background: #fbd43a !important;
}

.modal-window {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &:target {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &>div {
        width: 680px;
        height: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2em;
        position: absolute;
        width: 372px;
        height: 420px;
        background: #19181B;
        border-radius: 50px;
    }

    header {
        font-weight: bold;
        font-family: Mulish;
    }

    h1 {
        font-size: 150%;
        margin: 0 0 15px;
        font-family: Mulish;
    }

    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
    }

    .welcome {
        margin-top: 15px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
    }

    label {
        text-align: left;
        color: #fff;
        font-size: 12px;
        margin-left: 15px;
        margin-bottom: -6px;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

.modal-window {
    &>div {
        border-radius: 63px;
    }
}

.modal-logo {
    text-align: center;
}

.modal-logo>img {
    width: 100px;
    height: 100;
}

h2 {

    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    line-height: 190%;
    margin-top: 20px;

}

.modal-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 52px;
    gap: 19px;
    width: 380px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 50px;
    margin: 3%;
    font-family: Mulish;
    cursor: pointer;
}

.login-modal-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    width: 100%;
    height: 30px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    margin: 10px;
    color: #000;
    font-size: 10px;
    font-family: Mulish;

}

.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 30%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 4px;
    color: #000;
    font-weight: 800;
    font-family: Mulish;
}

.btn-proceed {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 250px;
    height: 40px;
    background: #D33E74;
    border-radius: 82px;
    color: #FFFFFF;
    margin-left: -10px;
    font-family: Mulish;
}

.background {
    height: 100vh;
    background: #fbd43a;
    width: 100%;

    .container {
        background: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .card {
            margin: 6px;
            width: 100%;
            height: auto;
            padding: 4px;
            background: transparent;
            font-size: 12px;
            border: none;
            color: #000;
            font-family: Mulish;

            .menu-bar{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 95%;
                font-family: Mulish;
                box-sizing: border-box;
                height: 50px;
                background: #000;
                padding: 10px;
                border-radius: 4px;
            }

            img {
                width: 50px;
                margin-top: 5px;
            }

            .view-content {
                padding: 10px;
                overflow-x: auto;
                overflow-y: auto;
                height: 350px;
                width: 96%;
                box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            }
        }


        h2 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 42px;
            text-align: left;
            color: #fff;
        }

        p {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 23px;
            text-align: left;
            color: #000;
        }

        span {
            .row {
                label {
                    color: #000;
                    font-family: Mulish;
                    text-align: left;
                }
            }


        }

        .form-group {
            margin: 4px;
        }

        .input-text {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            width: 100%;
            height: auto;
            background: #FFF;
            border: 0.5px solid #FFFFFF;
            border-radius: 4px;
            color: #000;
            font-size: 11px;
            font-family: Mulish;
        }

        .birthday {
            margin-left: -20px;
        }

        input:focus {
            border: 0.5px solid #D33E74;
        }

        select:focus {
            border: 0.5px solid #D33E74;
        }

        textarea:focus {
            border: 0.5px solid #D33E74;
        }

        option:focus {
            border: 0.5px solid #D33E74;
        }

        .input-select {
            box-sizing: border-box;
            align-items: center;
            padding: 10px 40px;
            width: 110%;
            height: auto;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 10px;
            font-family: Mulish;
            color: #767475;
            font-size: 11px;
        }

        label {
            text-align: left;
            color: #FFFFFF;
            margin-left: 4px;
            margin-top: 10px;
            font-family: Mulish;
        }


        .gender {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 40px;
            width: 100%;
            height: auto;
            background: #fbd43a;
            font-family: Mulish;
            border-radius: 10px;
            color: #000;
            font-size: 11px;
        }

        .location {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 202px;
            width: 100%;
            height: auto;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 10px;
            color: #767475;
            font-size: 11px;
            font-family: Mulish;
        }

        .btn-next {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 100%;
            height: 50px;
            background: #000;
            border-radius: 82px;
            margin-top: 40px;
            margin-bottom: 40px;
            color: #FFFFFF;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .btn-done {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 435px;
            height: 50px;
            background: #D33E74;
            border-radius: 82px;
            margin-top: 40px;
            margin-bottom: 40px;
            color: #FFFFFF;
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        background: #fbd43a;
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        border-radius: 4px;
        font-family: Mulish;

        .logo>img {}

        .contact {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            width: auto;
            font-weight: normal;
            font-size: 13px;
            color: #000;
            line-height: 0px;
            font-family: Mulish;
        }
    }

    .btn-chips {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 40px;
        gap: 19px;
        width: 117.61px;
        height: 35px;
        background: #0A070F;
        border: 0.5px solid #FFFFFF;
        border-radius: 50px;
        margin: 6px;
        color: #767475;
        font-family: Mulish;
    }

    .btn-chips:hover {
        border: 0.5px solid #D33E74;
    }

    .rel-goal {
        color: #D33E74;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }

}

@media screen and (max-width: 768px) {
    .modal-window {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;

        &:target {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }

        &>div {
            width: 680px;
            height: 490px;
            position: absolute;
            top: 50%;
            left: 53%;
            transform: translate(-50%, -50%);
            padding: 2em;
            position: absolute;
            width: 300px;
            height: 400px;
            background: #19181B;
            border-radius: 50px;
        }

        header {
            font-weight: bold;
            font-family: Mulish;

        }

        h1 {
            font-size: 150%;
            margin: 0 0 15px;
            font-family: Mulish;
        }

        h2 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }

        .welcome {
            margin-top: 15px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }

        label {
            text-align: left;
            color: #fff;
            font-size: 12px;
            margin-left: 15px;
            margin-bottom: -6px;
            font-family: Mulish;
        }

        span {
            margin-bottom: 20px;
        }

    }

    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: black;
        }
    }

    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: black;
        }

        display: none;
    }

    .modal-window {
        &>div {
            border-radius: 63px;
        }
    }

    .modal-logo>img {
        margin-top: -30px;
        width: 120px;
        height: 120;
    }

    h2 {

        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        line-height: 190%;
        margin-top: 20px;

    }

    .modal-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 19px;
        width: 380px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 50px;
        margin: 3%;
        cursor: pointer;
        font-family: Mulish;
    }

    .login-modal-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        padding: 5px;
        width: 100%;
        height: 30px;
        background: #ccc;
        border-radius: 4px;
        margin: 10px;
        color: #000;
        font-size: 10px;
        font-family: Mulish;
    }

    .login-modal-box2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        width: 80%;
        height: 30px;
        background: #fff;
        border: 1px solid #000;
        border-radius: 4px;
        color: #000;
        font-weight: 800;
        font-family: Mulish;
    }

    .btn-proceed {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 235px;
        height: 35px;
        background: #D33E74;
        border-radius: 82px;
        color: #FFFFFF;
        margin-left: -10px;
        font-size: 13px;
        font-family: Mulish;
    }
}

@media screen and (max-width: 768px) {

    .modal-close {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        //text-decoration: none;

        &:hover {
            color: #fff;
        }

        display: none;
    }

    .modal-close-mobile {
        color: #fff;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        //text-decoration: none;

        &:hover {
            color: #fff;
        }

        display: block;
    }

    .btn-login {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 52px;
        background: #000;
        border-radius: 8px;
        margin: 10px;
        color: #fff;
        width: 100%;
        font-size: 10px;
        margin-bottom: 20px;
        font-family: Mulish;

    }

    .btn-login:hover {
        background: #D33E74;
        border: 1px solid #21182f;
    }

    .background {
        background: #fbd43a;
        width: 100%;
        height: 100vh;

        .container {
            background: #fbd43a;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 30px;
            font-family: Mulish;

            .card {
                margin: 6px;
                width: 100%;
                height: auto;
                padding: 4px;
                background: transparent;
                font-size: 12px;
                border: none;
                color: #000;

                .menu-bar{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    font-family: Mulish;
                    box-sizing: border-box;
                    height: 50px;
                    background: #000;
                    padding: 10px;
                    border-radius: 4px;
                }

                img {
                    width: 50px;
                }
            }

            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 20px;
                line-height: 42px;
                text-align: left;
                color: #fff;
            }

            p {
                font-family: Mulish;
                font-style: normal;
                font-weight: 400;
                font-size: 12.5px;
                line-height: 23px;
                text-align: left;
                color: #000;
            }


            .form-group {
                margin: 4px;
            }

            .input-text {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 30px;
                width: 100%;
                height: 40px;
                background: #FFF;
                border: 0.5px solid #fff;
                border-radius: 10px;
                font-family: Mulish;
            }

            input:focus {
                border: 0.5px solid #D33E74;
            }

            select:focus {
                border: 0.5px solid #D33E74;
            }

            textarea:focus {
                border: 0.5px solid #D33E74;
            }

            option:focus {
                border: 0.5px solid #D33E74;
            }

            .birthday {
                margin-left: -30px;
            }

            .input-select {
                box-sizing: border-box;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: 40px;
                background: #FFF;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                margin: 2px;
                font-family: Mulish;
            }

            label {
                text-align: left;
                color: #000;
                margin-left: 4px;
                margin-top: 10px;
                font-size: 12px;
                font-family: Mulish;
            }

            .gender {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: 40px;
                font-family: Mulish;
                border-radius: 10px;

                img {
                    margin-top: 10px;
                    width: 50px;
                }
            }

            .location {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                gap: 202px;
                width: 100%;
                height: 40px;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                font-family: Mulish;
            }

            .btn-next {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 100%;
                height: 42px;
                background: #000;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color: #FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
            }

            .btn-done {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 100%;
                height: 42px;
                background: #D33E74;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                margin-left: -10px;
                color: #FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;

            }


        }

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 105px;
            background: #fbd43a;
            width: 100%;
            padding: 15px;
            font-family: Mulish;

            .logo>img {
                width: 50%;
            }

            .contact {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: right;
                padding: 10px;
                font-weight: bold;
                font-size: 15px;
                color: #000;
                line-height: 0px;
                font-family: Mulish;

            }
        }

    }
}</style>