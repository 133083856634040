<template>
    <div>
      <nav class="breadcrumbs">
        <ul>
          <li><router-link to="/shopping">Home</router-link></li>
          <li>
            <router-link to="">{{ products.category_name}}</router-link>
          </li>
          <li>
            <router-link to="">{{ products.subcategory_name}}</router-link>
          </li>
          <li>
            <router-link to="">{{ products.product_name}}</router-link>
          </li>
        </ul>
      </nav>
        <div class="product-container">
            <div class="column9">
                <div class="product">
                    <div class="product-img">
                        <img class="img" :src="img_url + 'products/' + products.product_image" alt="">
                        <div class="img-slide">
                            <img class="img1" :src="img_url + 'products/' + products.product_image" width="70px" height="70px" alt="">
                            <img class="img1" :src="img_url + 'products/' + products.product_image" width="70px" height="70px" alt="">
                            <img class="img1" :src="img_url + 'products/' + products.product_image" width="70px" height="70px" alt="">
                            <img class="img1" :src="img_url + 'products/' + products.product_image" width="70px" height="70px" alt="">
                        </div>
                    </div>
                    <div class="product-detail">
                        <div class="product-name">
                            <p class="title">{{ products.product_name}}</p>
                            <p class="brand">Brand: {{ products.brand_name }} | Similar products from {{ products.brand_name }} </p>
                            <hr>
                        </div>
                        
                        <div class="product-price">
                            <p class="title">₦ {{ products.product_price}}</p>
                            <p class="in-stock" v-if="products.in_stock == 1">In stock</p>
                            <p class="in-stock text-danger" v-if="products.in_stock == 0">Out of stock</p>
                            <p class="shipping-detail">+ shipping from ₦ 570 to Abuja-Prince and Princess</p>
                        </div>
                        <div class="variation-style" v-if="products.product_variationid !=null">
                          Variation available
                        </div>
                        <div v-if="products.product_variationid !=null" style="margin-bottom: 10px">
                          <button class="variation" v-for="(data, index) in products.variations" :key="index" @click="select_variation(data.variation)">{{ data.variation }}</button> 
                        </div>
                        <div v-else>
                          
                        </div>
                        
                        <div  v-if="products.in_stock == 1">
                          <button id="submit4" class="btn btn-primary btn-block"  @click="addToCart(products.productid, products.product_name, products.product_price, products.product_image)" v-if="!cartLoading"> <i class="fa fa-cart-plus" aria-hidden="true"></i> &nbsp; add to cart</button>
                          <button class="btn btn-primary btn-block" type="button" disabled v-if="cartLoading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>
                        </div>
                        <div  v-if="products.in_stock == 0">
                          <button id="submit4" class="btn btn-primary btn-block"  disabled v-if="!cartLoading"> <i class="fa fa-cart-plus" aria-hidden="true"></i> &nbsp; add to cart</button>
                          <button class="btn btn-primary btn-block" type="button" disabled v-if="cartLoading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </button>
                        </div>
                        <hr>   
                        <p style="font-size: 12px;"><span class="order-number" ><a href="https://wa.me/2347012482023 
                          "><i class="fa fa-whatsapp"></i> 07012482023</a> to order</span></p>    
                        <hr>
                        <router-link to="/shopping">
                          <button class="btn btn-light continue-shopping"> <i class="fa fa-arrow-left"></i> Go back & continue shopping</button>
                        </router-link>            
                    </div>
                </div>   
            </div>
            <div class="column3">
              <div class="delivery-status">
                <div class="delivery-return">
                  Delivery & Returns
                </div>
                <div  class="d-info">
                  Free delivery on thousands of products in Lagos, Ibadan & Abuja 
                </div>
                <div class="location">
                  <h5>Choose your location</h5>
                  <v-autocomplete
                  class="class-input"
                  required
                  label="Select *"
                  :items="items"
                 
                  ></v-autocomplete>
                  <v-autocomplete
                    class="class-input"
                    required
                    label="Select *"
                    :items="items"
                    
                  ></v-autocomplete>
                </div>
              </div>
              <div class="delivery-status">
                <div class="delivery-return">
                  seller information
                </div>
                <div  class="d-info">
                 <span><i class="fa fa-user-circle"></i> {{ products.shop_name }} </span> <i class="fa fa-long-arrow-right"></i>
                </div>     
              </div>
            </div>
        </div>   
        <div class="product-container">
            <div class="column12">
              <div class="product-d">
                <div class="p-detail">
                  <div class="p-header">
                    Product details
                  </div>
                  <div class="p-content">
                     <div v-html="products.product_description"></div>
                  </div>
                </div>
              </div>
              <div class="product-d">
                <div class="p-detail">
                  <div class="p-header">
                    Specifications
                  </div>
                  <div class="specification">
                    <div class="p-content-d">
                      <div class="content-header">
                        key features
                      </div>
                      <div v-html="products.product_keyfeature" style="padding: 0.4rem; border: solid 1px #a5a3a3;"></div>
                    </div>
                    <div class="p-content-d">
                      <div class="content-header">
                        specifications
                      </div>
                      <div v-html="products.product_specification" style="padding: 0.4rem; border: solid 1px #a5a3a3;"></div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
        </div>  
        <div v-if="is_variations">
        <transition name="modal">
          <div class="modal-mask">
              <div class="modal-wrapper col-md-5" style="margin:auto">
                  <div class="row">
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-10">
                          <div class="row account-content">
                              <div class="account-content-h">
                                variation
                              </div>
                              <div class="account-content-d">
                                <button class="btn btn-light btn-sm">{{ this.selectedVariation }}</button>
                                <div class="counter">
                                  <button class="btn-counter" @click="decrement()" > <span class="counter-action">-</span> </button> 
                                  <input type="text" v-model="counter" class="login-modal-box2" readonly> 
                                  <button class="btn-counter" @click="increment()"> <span class="counter-action">+</span> </button>
                                </div>
                              </div>
                              <p>&nbsp;</p>
                              <button class="btn btn-secondary" @click="submit_variation()">Done</button>
                              <button class="btn-bottom" @click="is_variations = false"><i class="fa fa-long-arrow-left"></i> back</button>
                          </div>
                      </div>
                      <div class="col-md-1">                         
                      </div>
                  </div>
              </div>
          </div>
        </transition>
        </div> 
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  //import EventBus from '../util/event-bus.js'
  import PageLoader from '../../components/PageLoader.vue'
  export default {
    
    name: "ProductDetailPage",
    components: {
        PageLoader,
    },
  
    data(){
      return {

          isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          img_url: process.env.VUE_APP_IMAGE_URL,
          cart_id: '',
          cartLoading: false,
          items: [],
          cart: [],
          cart_count: '',
          cart_items: '',
          selectedVariation: '',
          is_variations: false,
          counter: 1,
      };
    },
  
    beforeMount(){
      this.cart_id = localStorage.getItem('cart_id');
    },
  
    mounted(){

        this.cart_id = localStorage.getItem('cart_id');
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");

        this.fetchProductById(this.$route.params.id)    
    },
  
    created(){
          
    },
  
    computed: {
      // cartCount() {
      //   return this.$store.getters.getCartCount;
      // },
    },
  
    methods: {
      select_variation(variation){
        this.selectedVariation = variation
        this.is_variations = true
      },
      submit_variation(){
        this.is_variations = false
        //this.addToCart()
      },
      increment() {
            this.counter++; // Increment the quantity for the specific data item
        },

        decrement() {
            if (this.counter > 1) {
                this.counter--; // Decrement the quantity for the specific data item (if it's greater than 0)
            }
        },
      async fetchCartInformation(cart_id) {
          try {

              
              const response = await this.$http.post('/fetch-cart-byunique_code', {
                cart_unique_code: cart_id
              })
              

              this.cart_items = response.data.cart;
              this.$store.dispatch('updateCartCount', this.cart_items.length);
              //EventBus.$emit('update-cart-count', this.cart_items.length);
            
          } catch (err) {
              console.log(err);
          }
      },

      async addToCart(productid, product_name, price, image){
        try {

              this.cartLoading = true

              this.cart.push([{'cart_id': this.cart_id}, {'product_id': productid}, {'product_name': product_name}, {'price':price}, {'image':image},{'selectedVariation':this.selectedVariation}, {'variation_qty':this.counter}]);

              const response = await this.$http.post('/add-cart', {
                cart:this.cart
              })

              if( !response.data.success ){
                  swal.fire({
                      icon: 'error',
                      title: 'Error',
                      width: 450,
                      height: 5,
                      text: response.data.message,
                  })
                  return;
              }

              swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
              })
              this.fetchCartInformation(this.cart_id) 
              this.cartLoading = false
             

          } catch (err) {
              console.log(err);
          }
      },

      async fetchProductById(productid) {
          try {
              this.isLoading = true
              const response =  await this.$http.post('/fetch-product-byid', {
              productid: productid
          })
              this.isLoading = false
              this.products = response.data.products
                  
          } catch (err) {
            console.log(err);
        }
      },

    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:#fff;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
    border-bottom: solid 1px #00802f;
    color: #00802f;
  }
  .account-content-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
    margin: 10px;
  }
  .btn-bottom{
    margin-bottom: 20px;
  }
  .btn-secondary{
    margin-bottom: 10px;
  }

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #a5a3a3;
    color: #fff;
    border-radius: 4px;
}
.login-modal-box2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 20%;
  height: 30px;
  padding: 10px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 0px;
  color: #000;
  font-weight: 500;
  font-family: Mulish;
}
.counter-action{
  font-size: 13px;
}
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.9rem;
    margin-top: -20px;
  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  .product:hover{
    //border: solid 1px #071278;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 300px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
  }
  .continue-shopping{
    margin-left: 5px;
    font-size: 12px;
  }
  .variation-style{
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    border-top: solid 1px #ccc;
    margin-bottom: 10px;
    font-size: 14px;
    color: #7b7979
  }
  .variation{
    padding: 0.4rem;
    border: solid 1px #ccc;
    margin: 4px;
    height: auto;
    font-size: 12px;
  }
  .product-name{
    //border-bottom: solid 2px #a5a3a3;
    font-family: Mulish;
    .title{
        font-size: 20px;
        font-weight: 400;
        color: #2b2929;
    }
    .brand{
        font-size: 13px;
    }
  }
  .product-price{
    .title{
        padding: 16px 0;
        font-size: 20px;
        font-weight: 600;
        color: #2b2929;
    }
    .shipping-detail{
        font-size: 12px;
    }
    .in-stock{
      font-size: 12px;
      line-height: 1;
      margin-top: -20px;
      color: #a5a3a3;
    }
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #00802f;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  margin-bottom: 8px;
}
.delivery-return{
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-family: Mulish;
    padding: 0.3rem;
    border-bottom: solid 1px #a5a3a37b;
    span{
      color: #071278;
    }
}
.location{
  font-size: 12px;
  font-family: Mulish;
  padding: 20px 8px;
  h5{
    font-size: 14px;
    font-weight: bold;
  }
}
.class-input{
  height: 40px;
  font-size: 13px;
}

  @media screen and (max-width: 768px) {
    .account-content{ 
      margin: 2px;
      margin-right: 30px;
    }
    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
      
      .column9{
        width: 100%;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>