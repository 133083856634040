<template>
    <div class="background">
        <div class="bg-content">
                <div class="menu-bar">
                    <h2> <i class="fas fa-users"></i> &nbsp;Manage Members Wallet</h2>
                </div>
                <div class="tab">
                    <div class="tab-clients" @click="tabClients()">
                        <i class="fas fa-users"></i> Members Wallet
                    </div>
                </div>
        
                <div class="users-container2" v-if="clients_status">
                    <p><router-link to="/my-users"><span class="rm-menu list-rm" ><i class="fa fa-arrow-left"></i> Back</span></router-link> </p>
                    <div class="users-content">
                        <p><i class="fas fa-users"></i> {{ panel_header}} <i class="fa fa-angle-right" style="color:red"></i> {{ panel_name }}</p>
                      
                        <div v-if="show_list_clients">
                          
                            <div class="list-category">
                                <table id="myTable" class="table table-bordered table-striped" style="font-size:11px">
                                    <thead>
                                        <tr style="font-size:9px">
                                            <th>SN</th>
                                            <th>Deposit</th>
                                            <th>Withdrawal </th>
                                            <th>Description </th>
                                            <th>Status </th>
                                            <!-- <th>Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="font-size:9px" v-for="(data, index) in membersList" :key="index">
                                            <td style="font-weight: 400; width:auto">
                                                {{ index +1 }}
                                            </td>
                                          
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.deposit_amount }}
                                            </td>
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.withdrawal_amount }}
                                            </td>
                                            
                                            <td style="font-weight: 400; width:auto">
                                                {{ data.description }}
                                            </td>
                                            
                                            <td style="font-weight: 400; width:auto" v-if="data.funding_status == 0">
                                                <span class="badge badge-danger">New Payment</span><br>
                                                <button class="badge badge-primary" @click="confirm_payment(data.wallet_id, data.member_id )">confirm payment</button>
                                            </td>
                                            <td style="font-weight: 400; width:auto" v-if="data.funding_status == 1">
                                              
                                            </td>
<!--                                            
                                            <td style="font-weight: 400; display: flex; flex-direction: row">
                                                <router-link :to="url+data.members_id"> <span style="color:green; cursor:pointer" data-toggle="tooltip" title="Edit"><button data-toggle="tooltip" title="Edit" class="btn btn-primary btn-sm btn-input"><i class="fa fa-edit"></i></button></span></router-link>
                                                <button class="btn btn-success btn-sm btn-input" v-if="data.active_status == 1" @click="disable_member(data.user_id)"><i class="fa fa-user-secret"></i> Disable</button> &nbsp;
                                                <button class="btn btn-danger btn-sm btn-input" v-if="data.active_status == 0" @click="enable_member(data.user_id)"><i class="fa fa-user-secret"></i> Enable</button> &nbsp;
                                                <button data-toggle="tooltip" title="Delete" class="btn btn-danger btn-sm btn-input" @click="delete_member(data.user_id)"><i class="fa fa-trash"></i></button> 
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    
    <script>
    //import Vue from 'vue'
    import PageLoader from '../../components/PageLoader.vue'
    import swal from 'sweetalert2';
    //import $ from "jquery";
    
    export default {
        components: {
            PageLoader,
        },
        data() {
            return {

                isLoading: false,
                report: [],
                app_role: '',
                cfirstname: '',
                clastname: '',
                cusername: '',
                userid: '',
                check_items: [],
                customer_order: false,
               
                staff_status: true,
                clients_status: true,
                housekeeping_status: false,
                process_payment: false,
                report_status: false,
                edit_item: false,
                category_name: '',
                categories: [],
                edit_category_name: '',
                item_id: '',
                loading: false,
                loading2: false,
                loading3: false,
                loading4: false,
                loading5: false,
                loading6: false,
                loading7: false,
                loading8: false,
                loading9: false,
                loading10: false,
                loading11: false,
                loading12: false,
                loading13: false,
                loading14: false,
                loading15: false,
                show_staffs: false,
                show_add_rm: false,
                show_list_staffs: true,
                show_list_clients: true,
                show_clients: false,
                show_guest_information: false,
                room_extension_status: false,
                panel_name: "List Amount",
                panel_header: "Members Wallet",
                room_type: '',
                room_number: '',
                bed_type: '',
                room_capacity: '',
                room_description: '',
                room_amount: '',
                rooms: [],
                staff_id: '',
                vstaff_status: '',
                edit_room_id: '',
                edit_room_type: '',
                edit_room_number: '',
                edit_room_capacity: '',
                edit_bed_type: '',
                edit_room_description: '',
                edit_room_amount: '',
                edit_room_data: false,
                gender: '',
                url: '',
                reservations: [],
                reservation_search: '',
                guest_status: '',
                update_guest_status: false,
                reservation_id: '',
                guest_status_input: '',
                guest_roomid: '',
                guest_phone_number: '',
                guest_info: [],
                edit_quest_id: '',
                user_id: '',
                edit_staffid:'',
                edit_firstname: '',
                edit_middlename: '',
                edit_lastname: '',
                edit_gender: '',
                edit_phone: '',
                edit_email: '',
                edit_address: '',
                edit_guest_data: false,
                checkout_date: '',
                reservation_summary: true,
                occupancy_report: false,
                revenue_report: false,
                roomtype_report: false,
                vguest_status: '',
                date_to: '',
                date_from: '',
                date_to2: '',
                date_from2: '',
                date_to3: '',
                date_from3: '',
                date_to4: '',
                date_from4: '',
                summaryReservation: [],
                response: '',
                occupancyReport: [],
                revenueReports:[],
                roomtypeReports:[],
                bookedStatus: '',
                extension_amount: '',
                extension_date: '',
                extension_reservation_id:'',
                ext_checkout_date: '',
                list_payments: false,
                guestPayments: [],
                payment_date_from: '',
                payment_date_to: '',
                guest_fullname: '',
                new_payment_status: false,
                guest_found: false,
                guest_name: '',
                guest_payments: [],
                payment_description: '',
                guestLists: [],

                staff_firstname: '',
                staff_middlename: '',
                staff_surname: '',
                staff_gender: '',
                staff_branch: '',
                staff_phone_number: '',
                staff_email_address: '',
                staff_address: '',
                password: '',
                cpassword:'',

                account_officer: '',
                client_account_officer: '',
                client_title: '',
                client_firstname: '',
                client_middlename: '',
                client_surname: '',
                client_gender: '',
                client_phone_number: '',
                client_email_address: '',
                client_password: '',
                client_cpassword:'',
                client_account_type: '',
                client_account_number: '',
                client_state: '',
                client_lga: '',
                client_spouse: '',
                client_mother_name: '',
                client_ms: '',
                client_dob: '',
                birth_country: '',
                client_nationality: '',
                client_religion: '',
                client_contact_address: '',
                client_whatsapp_number: '',
                client_nin: '',
                nok_title: '',
                nok_firstname: '',
                nok_middlename: '',
                nok_surname: '',
                nok_gender: '',
                nok_dob:'',
                nok_phone_number: '',
                nok_business:'',
                states: [],
                lgas: [],
                previewImage: '',
                branchid: '',
                branches: [],
                staffList: [],
                staffListing: [],
                edit_user: false,
                membersList: [],
                search_account_number: '',
                selectedFile: '',
                member_id: '',
    
            };
        },
    
        beforeMount() {
            this.member_id = this.$route.params.memberid
            this.fetchMemberWallet(this.member_id);
        },
    
        mounted() {
            this.app_role = localStorage.getItem("user_role");
            this.cfirstname = localStorage.getItem("firstname");
            this.clastname = localStorage.getItem("lastname");
            this.cusername = localStorage.getItem("username");
            this.userid = localStorage.getItem("userid");
            this.branchid = localStorage.getItem("branchid");
    
        },
    
        created() {
    
        },
        beforeDestroy() {
            // Clear the interval when the component is about to be destroyed
            clearInterval(this.timerInterval);
        },
        computed: {
            
    
        },
    
        methods: {
           async confirm_payment(walletid, member_id){
                try{
                    let message = confirm("         You're about to confirm this payment. Do you want to continue?")
                    if (message == true) {
                    this.response = await this.$http.post('/confirm-wallet', {
                        walletid: walletid,
                        member_id: member_id
                    });    

                    if (this.response.data.success) {
                        swal.fire({
                            icon: 'success',
                            width: 450,
                            height: 5,
                            title: "Payment",
                            text: this.response.data.message,
                        });
                        return;
                    } 
                    
                    this.fetchMemberWallet(member_id)
                }         
              }catch (err) {
                  console.log(err);
              }
            },
          
    
            tabClients() {
                this.staff_status = false
                this.clients_status = true
                this.show_list_clients = true
                this.show_guest_information = false
                this.housekeeping_status = false
                this.report_status = false
                this.process_payment = false
                this.panel_header = "Members"
                this.panel_name = "List Members"
    
                const tab1 = document.getElementsByClassName("tab-staff");
                tab1[0].style.color = "#000";
    
                const tab2 = document.getElementsByClassName("tab-clients");
                tab2[0].style.color = "#fff";
    
            }, 
    
            closeMenu() {
                this.edit_user = false
            },
    
                     
            list_clients() {
                this.show_list_clients = true
                this.show_clients = false
                this.panel_name = "List Members"
    
            }, 
                 
            async fetchMemberWallet(memberid) {
                try {
                    this.isLoading = true;
                    const response = await this.$http.post('/member-wallet', {
                        memberid: memberid
                    })
                    this.isLoading = false

                    this.membersList = response.data.members;
    
                } catch (err) {
                    console.log(err);
                }
            },
        
        
        }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
    
.password-check{
        margin-left:50px;
        font-size: 10px;
        font-family: Mulish;
        .five-char {
            color: red
        }
        .uppercase{
            color: red
        }
        .number{
            color: red
        }
        .special-char{
            color: red
        }
        .password-match{
            color: red
        }
}
.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #2b0c17;
        background: #FFF;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    .welcome {
        margin-top: 8px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 23px;
        justify-content: center;
        color: #FFFFFF;

    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

    .counter {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }

    .order-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        height: 250px;
        overflow-y: auto;
       
    }
    .edit-left{
        width:100%;
        .space-top{
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }  
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }

    }

    .edit-right{
        width:100%;
        margin-left: 4px;
        .space-top{
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }  
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 1px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }    

    }

    .edit-button {
        margin-bottom: 15px;
    }
   
}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }

    display: none;
}

    .modal-mask2 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask3 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-mask4 {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: 70%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;
    
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }
    
        .btn-counter {
            padding: 6px;
            font-weight: bold;
            font-size: 10px;
            border: 1px solid #fbd43a;
            margin: 5px;
            width: 20%;
        }
    
        .order-content {
            margin-top: 10px;
            height: 270px;
            overflow-y: auto;
        }
    
        .space-top {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin-left: 10px;
            margin-top: 10px;
        }
    
        .edit-input {
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            margin: 2px;
            border: 0px solid #000;
            height: 30px;
            width: 100%;
            margin-top: -10px;
        }
    
        .input-button {
            height: 30px;
            width: 100%;
            margin-top: 10px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            background: #000;
            color: #FFF;
        }
    
    }
    
    .modal-close2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile2 {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }
    
    a {
        text-decoration: none;
    }
    
    .background {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .bg-content{
        padding: 10px;
        background:#ccc;
        padding-right: 20px;
        width: 100%;
        border-radius: 10px;
        height: auto;
    }
    
    
    .menu-bar {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        box-sizing: border-box;
        height: 50px;
        background: #000;
        padding: 10px;
        border-radius: 4px;
    }

    .tab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 4px;
        margin-top: 15px;
    }

    .tab-staff {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        font-size: 10px;
        box-sizing: border-box;
        height: 20px;
        background: #FFA500;
        padding: 10px;
        border-right: solid 2px #fff;
        border-bottom: solid 1px #fff;
        color: #fff;
        cursor: pointer;
    }

    .tab-staff:hover {
        font-weight: bold;
        color: #000;
        border-bottom: solid 1px #fff;
    }

    .tab-clients {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: Mulish;
        font-size: 10px;
        box-sizing: border-box;
        height: 20px;
        background: #FFA500;
        padding: 10px;
        color: #000;
        cursor: pointer;
    }
    .tab-clients:hover {
        font-weight: bold;
        color: #000;
        border-bottom: solid 1px #fff;
    }
    
        .tab-reports {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-family: Mulish;
            font-size: 10px;
            box-sizing: border-box;
            height: 20px;
            background: #FFA500;
            padding: 10px;
            //border-right: solid 2px #fff;
            color: #000;
            cursor: pointer;
        }
    
        .tab-reports:hover {
            font-weight: bold;
            color: #000;
            border-bottom: solid 1px #fff;
        }
    
        .users-container {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .users-container2 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .users-container3 {
            margin: 15px;
    
            p {
                margin-top: -10px;
                font-size: 10px;
                font-weight: 650;
                font-family: Mulish;
            }
        }
    
        .search-input {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            font-size: 10px !important;
        }
    
        .search-name {
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            text-align: left;
            color: #000;
            margin-bottom: -2px;
        }
    
        .orderid {
            width: 10%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .searchdate {
            width: 13%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
        }
    
        .btn-search1 {
            width: 8%;
            height: 25px;
            margin-bottom: 2px;
            border: 1px solid #000;
            background: #000;
            border-radius: 2px;
            font-size: 10px;
            font-family: Mulish;
            margin: 4px;
            color: #fff;
        }
    
        .rm-menu {
            margin: 4px;
        }
    
        .rm-category:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .rm-category3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .add-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .users-content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
            font-size: 12px;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu2 {
            margin: 4px;
        }
    
        .add-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm2:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .users-content2 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .users-content3 {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.3);
            width: 100%;
            padding: 10px;
            background: #ccc;
    
            p {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: Mulish;
            }
        }
    
        .rm-menu3 {
            margin: 4px;
        }
    
        .add-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .list-rm3:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    
        .guest-information {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .guests {
                font-size: 10px;
                font-weight: 400 !important;
                cursor: pointer;
            }
    
            .guests:hover {
                text-decoration: underline;
            }
        }
    
        .add-user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 10px;
            font-weight: 700;
            font-family: Mulish;

            .staff-form {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
       
        .members-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
        }

        .btn-input{
            height: 30px;
            width: auto;
            font-family: Mulish;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            margin: 2px;
        }

        .btn-group{
            display:flex; 
            flex-direction: row; 
            justify-content: space-between;
        }

        .img-profile {
            width: 120px;
            height: 120px;
            background: url("../../assets/photo_add.png");
            background-size: cover;
            padding-bottom: 0rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            border-radius: 10px;
        }
        .form-input{
            font-weight: 500;
            font-size: 12px;
            height: 30px;  
        }
        
        .form-select{
            font-weight: 500;
            font-size: 12px;
            height: 30px;  
        }
        .form-button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
        }
    
        .add-user2 {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    
        .add-room {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-room2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .add-rm-center {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .add-rm-center2 {
            width: 50%;
    
            .form-control {
                border-radius: 4px;
                height: 30px;
                font-size: 12px;
            }
    
            .btn-primarys {
                background: #FFA500;
                font-size: 12px;
                height: 30px;
                border-radius: 4px;
                width: 100%;
    
            }
        }
    
        .list-category {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            //height: 800px;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .list-category2 {
            margin-top: 10px;
            overflow-x: auto;
            overflow-y: auto;
            .category-edit {
                cursor: pointer;
                color: green;
                font-size: 10px;
            }
    
            .category-delete {
                cursor: pointer;
                color: #f50404;
                font-size: 10px;
            }
        }
    
        .input {
            border: solid 0px #000;
            border-radius: 4px;
            width: 30%;
            height: 30px;
            font-size: 12px;
        }
    
        .btn-primarys {
            background: #FFA500;
            font-size: 12px;
            height: 30px;
            width: 10%;
    
        }
    
    img {
        width: 50px;
        border-radius: 40px;
    }
    
    h2 {
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        text-align: left;
        color: #fff;
    }
    
    
    
    @media screen and (max-width: 768px) {
    
        .modal-mask {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: 25%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    
        .modal-mask2 {
            position: fixed;
            //z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
            display: table;
            transition: opacity .4s ease;
    
            &>div {
                width: 80%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: 0.5px solid #2b0c17;
                background: #FFF;
                backdrop-filter: blur(13px);
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Mulish;
            }
    
            .welcome {
                margin-top: 8px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 23px;
                justify-content: center;
                color: #FFFFFF;
    
            }
    
            h3 {
                margin-top: 10px;
                font-size: 18px;
                color: #000;
                font-weight: bold;
                font-family: Mulish;
            }
    
            span {
                margin-bottom: 20px;
            }
    
            .counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
            }
    
            .btn-counter {
                padding: 6px;
                font-weight: bold;
                font-size: 10px;
                border: 1px solid #fbd43a;
                margin: 5px;
                width: 20%;
            }
    
            .order-content {
                margin-top: 10px;
                height: 270px;
                overflow-y: auto;
            }
    
            .space-top {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }
    
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 0px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }
    
            .input-button {
                height: 30px;
                width: 100%;
                margin-top: 10px;
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                background: #000;
                color: #FFF;
            }
    
        }
    
        .modal-close2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: none;
        }
    
        .modal-close-mobile2 {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
    
            &:hover {
                color: black;
            }
    
            display: block;
        }
    }
    
    @media screen and (max-width: 768px) {

        .bg-content{
            padding: 10px;
            background:#ccc;
            padding-right: 20px;
            width: 100%;
            border-radius: 10px;
            height: auto;
        }
    
        .background {
            background: #fbd43a;
            width: 100%;
            height: 100vh;
    
            .container {
                background: #fbd43a;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 30px;
    
                .card {
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
    
                    .menu-bar {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        height: 50px;
                        background: #000;
                        padding: 10px;
                        border-radius: 4px;
                    }
    
                    .tab {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        box-sizing: border-box;
                        padding: 4px;
                        border-radius: 4px;
                        margin-top: 15px;
                    }
    
                    .tab-staff {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 0px;
                        border-right: solid 2px #fff;
                        border-bottom: solid 1px #fff;
                        color: #fff;
                        cursor: pointer;
                    }
    
                    .tab-clients {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .tab-housekeepings {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-family: Mulish;
                        font-size: 8px;
                        box-sizing: border-box;
                        height: 20px;
                        background: #FFA500;
                        padding: 1px;
                        color: #000;
                        cursor: pointer;
                    }
    
                    .users-container {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .users-container2 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .users-container3 {
                        margin: 15px;
    
                        p {
                            margin-top: -10px;
                            font-size: 10px;
                            font-weight: 650;
                            font-family: Mulish;
                        }
                    }
    
                    .search-input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        font-size: 10px !important;
                    }
                    .searchdate {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                    }
        
                    .btn-search1 {
                        width: 100%;
                        height: 25px;
                        margin-bottom: 2px;
                        border: 1px solid #000;
                        background: #000;
                        border-radius: 2px;
                        font-size: 10px;
                        font-family: Mulish;
                        margin: 4px;
                        color: #fff;
                    }
    
                    .rm-menu {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .rm-category:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .add-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .users-content {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .rm-menu2 {
                        margin: 4px;
                        font-size: 9px;
                    }
    
                    .add-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .list-rm2:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
    
                    .users-content2 {
                        p {
                            margin-top: 5px;
                        }
                    }
    
                    .add-room {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-room2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                    }
    
                    .add-rm-center {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-rm-center2 {
                        width: 100%;
    
                        .form-control {
                            border-radius: 4px;
                            height: 30px;
                            font-size: 12px;
                        }
    
                        .btn-primarys {
                            background: #FFA500;
                            font-size: 12px;
                            height: 30px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
    
                    .add-user {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .add-user2 {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
    
                    .input {
                        width: 100%;
                        height: 30px;
                    }
    
                    .btn-primarys {
                        margin-top: -10px;
                        width: 100%;
                        height: 30px;
                    }
    
                    img {
                        width: 50px;
                    }
                }
    
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    text-align: left;
                    color: #fff;
                }
    
            }
        }
    }

    </style>
    