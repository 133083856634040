<template>
    <div style="background: #cccccc3d">
        <!-- top navbar -->
        <div class="top-bar">
            <div>
                <h6>Shop from our collections</h6>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg
        navbar-light navbar-bgcolor">
        <div>
            <div class="dropdown">
                <button class="dropbtn avater"><i class="fa fa-bars bars"></i>Menu</button>
                <div class="dropdown-content">
                    <div class="brand-category">Brands</div>
                    <router-link v-for="(data, index) in brands" :key="index" :to="'/brands/' + data.brand_name">{{ data.brand_name}}</router-link>
                    <div class="brand-category">Category</div>
                    <router-link v-for="(data, index) in categorys" :key="index" :to="'/category/' + data.category_name">{{ data.category_name}}</router-link>
                </div>
            </div>
            
        </div>
            <a class="navbar-brand" href="#"><img src="https://app.belmazmcs.com.ng/logo.png" alt="Logo"> &nbsp;</a>
            <div class="search">
                <input type="text" class="form-control inputs-field" placeholder="Search products, brands and category">
                <button class="search-button"> <i class="fa fa-search"></i></button>
            </div>
            <div class="menu-cart">
                <div class="dropdown">
                    <button class="dropbtn avater">{{ firstname }}<span>{{ name_initial }}</span></button>
                    <div class="dropdown-content">
                      <div v-if="user_role == 1">
                        <router-link to="/home">My Dashboard</router-link>
                        <router-link to="/shop">Shop</router-link>
                        <router-link to="/brand">Brand</router-link>
                        <router-link to="/category">Category</router-link>
                        <router-link to="/subcategory">SubCategory</router-link>
                        <router-link to="/product">Product</router-link>
                        
                      </div>
                      <div v-if="user_role == 2">
                        <router-link to="/myorders">My Orders</router-link>
                        <router-link to="/home">My Dashboard</router-link>
                        <router-link to="/myaccount">My Account</router-link>
                      </div>
                      <div v-if="user_role == 3">
                        <router-link to="/myorders">My Orders</router-link>
                        <router-link to="/home">My Dashboard</router-link>
                        <!-- <router-link to="/myaccount">My Account</router-link> -->
                      </div>
                      <!-- <router-link to="/sign-in">Logout <i class="fa fa-sign-out"></i></router-link> -->
                    </div>
                </div>
                <div class="cart">
                    <router-link to="/cart">
                        <div><i class="fa fa-shopping-cart"></i> Cart <span v-if="cartCount == ''"></span> <span v-else class="badge badge-primary">{{ cartCount}}</span></div>
                    </router-link>
                </div>
            </div>  
        </nav>
       
        <div class="container">
            <router-view />
        </div>

        <div class="footer">
            Privary Policy | Disclaimer
            <hr>
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br> 
            &copy;{{ new Date().getFullYear() }} Belmaz MCS. All rights reserved.
        </div>
    </div>
</template>
<script>
//import EventBus from '../util/event-bus.js'

export default {

    name: "ShoppingDashboard",
    components: {

    },

    data(){
        return {
            appTitle: 'Belmaz MCS',
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_role: '',
            brands: [],
            categorys: [],
            //cartCount: 0,
        };
    },
    
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        // EventBus.$on('update-cart-count', (count) => {
        //     this.cartCount = count;
        // });

        this.user_role = localStorage.getItem("user_role");
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");
      
        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }
        this.fetchcategorys()
        this.fetchbrands()
    },
    created(){
        
    },
    computed: {
     cartCount() {
         return this.$store.getters.getCartCount;
     },
    },
    methods:{
     
        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })

            this.categorys = response.data.categories;
                                   
            } catch (err) {
              console.log(err);
          }
        },

        async fetchbrands() {
            try {
               const response =  await this.$http.get('/get-brands', {
            })
            this.brands = response.data.brands              
            } catch (err) {
              console.log(err);
          }
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
/* Style The Dropdown Button */
.top-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1.9rem;
    font-family: Mulish !important;
    font-size: 18px;
    height: 40px;
    padding: 1.2rem;
    background: #00802f;
    opacity: 2px;
    color: #fff;
}
.search{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 45%;
}
.inputs-field{
    font-size: 16px;
    font-family: Mulish;
    height: 45px;
    border-radius: 0px;
    position: relative;
}
.search-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem;
    background: #00802f;
    color: #fff;
    border-radius: 0px;
    font-family: Mulish;
    font-size: 18px;
    text-transform: capitalize;
    height: 45px;
}

.menu-cart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 1.9rem;
    font-family: Mulish !important;
    font-size: 16px;
    color:#312F33;
}
.dropbtn {
   // background-color: #4CAF50;
    color: #4f4d4d;
    //padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 3px 16px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #474646}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    //background-color: #3e8e41;
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: Mulish;
}
.dropdown-menu {
    margin-left: 250px;
    background: #fff;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}

.cart{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px !important;
}
.bars{
    padding-left: 1.9rem;
    font-size: 20px;
    color: #071278;
}
.brand-category {
    padding: 0.4rem;
    font-size:14px; 
    text-align: left; 
    text-transform: uppercase
}
.navbar-bgcolor{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    color: #3c3a3a;
    font-family: Mulish;
    border-bottom: solid 1px #ccc;
   // box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);

    a{
        color: #ccc !important;
        text-decoration: none;
        font-size: 13px;

        img{
            width: 45px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}
.avater{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}
.footer{
   
    flex-shrink: 0;
    background: #f6f1f2;
    font-family: Mulish;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #0d0d0d;
    padding: 10px;
    text-align: center;
    //position: absolute;
    bottom: 0;
    height: 120px;
    width: 100%;
    font-size: 12px;
}

@media screen and (max-width: 768px) { 
    .bars{
        padding-left: 0.5rem;
    }
    .navbar-brand{
        display: none;
    }
    .search{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
    .inputs-field{
        font-size: 14px;
        height: 30px;
    }
    .search-button{
        font-size: 14px;
        height: 30px;
    }
    .menu-cart{
        margin-left: 50%;
        font-size: 12px;
    }
    .cart{
        font-size: 13px !important;
    }
    .dropdown-menu {
        margin-left: 0;
    }

    .dropdown {
        font-size: 12px;
      }
    
    .avater{
        font-size: 12px;
        display: flex;
    }
}

</style>