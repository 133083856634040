<template>
    <div class="background">
            <div class="header navbar navbar-default">
                <span class="logo">
                   <router-link to="/add-staff"> <img src="../../assets/arrow-back.png" alt="" width="30px"> </router-link>
                </span>
                <div class="contact">
                </div>
            </div>
            <div class="container">
               
                <div class="card">
                    <h2>
                        Users List
                    </h2>
                  
                   <div class="view-content">
                        <table id="myTable" class="table table-bordered table-striped">
                            <thead>
                                <tr style="font-size:9px">
                                    <th>SN</th>
                                    <th>Role</th>
                                    <th>Firstname</th>
                                    <th>Lastname</th>
                                    <th>Sex</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th>User Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in options" :key="index" style="font-size:9px">
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td v-if="data.user_role == 1">
                                        {{ 'Waiter' }}
                                    </td>
                                    <td v-if="data.user_role == 2">
                                        {{ 'Admin' }}
                                    </td>
                                    <td>
                                        {{ data.firstname }}
                                    </td>
                                    <td>
                                        {{ data.lastname }}
                                    </td>
                                    <td>
                                        {{ data.sex }}
                                    </td>
                                    <td>
                                        {{ data.phone }}
                                    </td>
                                    <td>
                                        {{ data.email }}
                                    </td>
                                    <td>
                                        {{ data.contact_address }}
                                    </td>
                                    <td>
                                       <span v-if="data.active_status == 1" style="color: green; font-weight: bold; font-size: 10px; font-family: Mulish;"> {{ 'Active' }}</span>
                                       <span v-if="data.active_status == 0" style="color: red; font-weight: bold; font-size: 10px; font-family: Mulish;"> {{ 'Not Active' }}</span>
                                    </td>
                                    <td class="btn-group">
                                        <button class="btn btn-primary btn-sm btn-input" @click="editUser(data.id, data.user_role, data.firstname, data.lastname, data.sex, data.contact_address, data.phone, data.email)"><i class="fa fa-edit"></i> Edit</button>
                                        <button class="btn btn-success btn-sm btn-input" v-if="data.active_status == 1" @click="disableUser(data.id)"><i class="fa fa-user-secret"></i> Disable</button> 
                                        <button class="btn btn-danger btn-sm btn-input" v-if="data.active_status == 0" @click="enableUser(data.id)"><i class="fa fa-user-secret"></i> Enable</button> 
                                        <button class="btn btn-info btn-sm btn-input" @click="deleteUser(data.id)"><i class="fa fa-trash"></i> Delete</button> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                   </div>
                </div>
            </div>
    
        <!-- Edit Modal -->
        <div v-if="edit_user">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile" @click="closeMenu"> <i
                                        class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <h3>
                                        Edit User
                                    </h3>
                                    <div class="order-content">
                                        <div class="edit-left">
                                            <input type="hidden" class="form-control " v-model="user_id">
                                            <p class="space-top">Role</p>
                                            <select class="form-control edit-input" id="edit_role" v-model="edit_role">
                                                <option value="">-Select-</option>
                                                <option value="1">Waiter</option>
                                                <option value="2">Admin</option>
                                            </select>
                                            <p class="space-top">Firstname</p>
                                            <input type="text" class="form-control edit-input" v-model="edit_firstname">
                                            <p class="space-top">Lastname</p>
                                            <input type="text" class="form-control edit-input" v-model="edit_lastname">
                                            <p class="space-top">Gender</p>
                                            <select class="form-control edit-input" id="edit_gender" v-model="edit_gender">
                                                <option value="">-Select-</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            
                                        </div>
                                        <div class="edit-right">
                                            <p class="space-top">Phone</p>
                                            <input type="text" class="form-control edit-input" v-model="edit_phone" readonly disabled>
                                            <p class="space-top">Email</p>
                                            <input type="text" class="form-control edit-input" v-model="edit_email">
                                            <p class="space-top">Address</p>
                                            <input type="text" class="form-control edit-input" v-model="edit_address">
                                        </div>
                                    </div>
                                    <div class="edit-button"> 
                                        <button class="btn btn-success btn-block input-button" @click="updateUser" v-if="!loading"><i class="fa fa-upload"></i> Update </button>
                                        <button class="btn btn-success btn-block input-button" type="button" disabled v-if="loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Updating...
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close" @click="closeMenu"> <i
                                            class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
    </template>
    <script>
    //import $ from 'jquery';
    //import Vue from 'vue'
   import swal from 'sweetalert2';
   import PageLoader from '../../components/PageLoader.vue'
   // import VueCountryDropdown from "vue-country-dropdown";
    
   // Vue.use(VueCountryDropdown);
    
    export default {
        components: {
            PageLoader,
        },
    data() {
      return {
        isLoading: false,
        options: [],
        app_role: '',
        cfirstname: '',
        clastname: '',
        cusername: '',
        userid:'',
        edit_user: false,

        user_id: '',
        edit_role:'',
        edit_firstname: '',
        edit_lastname: '',
        edit_gender: '',
        edit_address: '',
        edit_phone: '',
        edit_email: '',
        loading:  false,
    
      };
    },
    
    beforeMount(){

    },
    
    mounted(){

        this.app_role = localStorage.getItem("app_status");
        this.cfirstname = localStorage.getItem("firstname");
        this.clastname = localStorage.getItem("lastname");
        this.cusername = localStorage.getItem("username");
        this.userid = localStorage.getItem("userid");
        this.getStaff()
    },
    
    methods: {
            
        editUser(uid, role, firstname, lastname, gender, address, phone, email){
          try{
                this.user_id = uid
                this.edit_role = role,
                this.edit_firstname = firstname,
                this.edit_lastname = lastname,
                this.edit_gender = gender,
                this.edit_address = address,
                this.edit_phone = phone,
                this.edit_email = email,

                this.edit_user = true

            } catch (err) {
            console.log(err);
            }
        },

        async updateUser(){
            try{
       
                    this.loading = true;

                    this.response = await this.$http.post('/update-user', {
                        userid: this.user_id,
                        role: this.edit_role,
                        firstname: this.edit_firstname,
                        lastname: this.edit_lastname,
                        gender: this.edit_gender,
                        address: this.edit_address,
                        phone: this.edit_phone,
                        email: this.edit_email,
                    });

                        this.loading = false;

                        if (!this.response.data.success) {

                            swal.fire({
                                    icon: 'error',
                                    width:450,
                                    height:5,
                                    title: "Error",
                                    text: this.response.data.message,
                                });
                                return;

                        } else {

                        swal.fire({
                            icon: 'success',
                            width:450,
                            height:5,
                            title: "Success",
                            text: this.response.data.message,
                        });

                        this.edit_user = false
                        this.edit_role = '',
                        this.edit_firstname = '',
                        this.edit_lastname = '',
                        this.edit_gender = '',
                        this.edit_address = '',
                        this.edit_phone = '',
                        this.edit_email = '',

                        this.getStaff()
                                                    
                        return;

                        }

                } catch (err) {
                console.log(err);
                }
        },

        async deleteUser(uid) {

            let msg = confirm("               You are about to delete this user. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/delete-user', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
              }
              this.getStaff()


            } catch (err) {
                console.log(err);
            }
            }
        },

        async disableUser(uid) {

            let msg = confirm("You are about to disable this user. This user cannot have access to the system if disabled. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/disable-user', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
            this.getStaff()


            } catch (err) {
                console.log(err);
            }
            }
        },

        async enableUser(uid) {

            let msg = confirm("You are about to activate this user. This user can now have access to the system if activated. Are you sure?");

            if(msg == true) {
            try {
                this.loading = true;

                this.response = await this.$http.post('/activate-user', {
                    userid: uid,
                });

                this.loading = false;

                if (!this.response.data.success) {
                    swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: this.response.data.message,
                        });
                        return;

                } else {

                swal.fire({
                    icon: 'success',
                    width:450,
                    height:5,
                    title: "Success",
                    text: this.response.data.message,
                });
            }
            this.getStaff()


            } catch (err) {
                console.log(err);
            }
            }
        },

        async getStaff() {
         try{
            this.isLoading = true
            const response = await this.$http.get('/all-staff', )
            this.isLoading = false
            this.options = response.data.staff;    
                        
            }catch (err) {
                console.log(err);
            }
        },

        closeMenu() {
            this.edit_user = false
        },
       
      }
    }
    </script>
    
    <style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');

    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .4s ease;

        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #2b0c17;
            background: #FFF;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }

        .welcome {
            margin-top: 8px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 23px;
            justify-content: center;
            color: #FFFFFF;

        }

        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }

        span {
            margin-bottom: 20px;
        }

        .counter {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
        }

        .order-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 10px;
            height: 250px;
            overflow-y: auto;
           
        }
        .edit-left{
            width:100%;
            .space-top{
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }  
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 1px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }

        }

        .edit-right{
            width:100%;
            margin-left: 4px;
            .space-top{
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin-left: 10px;
                margin-top: 10px;
            }  
            .edit-input {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 10px;
                margin: 2px;
                border: 1px solid #000;
                height: 30px;
                width: 100%;
                margin-top: -10px;
            }    

        }

        .edit-button {
            margin-bottom: 15px;
        }
       
    }

    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }
    
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    
        display: none;
    }

    .order-content {
        margin-top: 10px;
        height: 270px;
        overflow-y: auto;
    }
    .space-top{
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        margin-left: 10px;
        margin-top: 10px;
    }  
    .edit-input {
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        margin: 2px;
        border: 1px solid #000;
        height: 30px;
        width: 100%;
        margin-top: -10px;
    }
    .input-button{
        height: 30px;
        width: 100%;
        margin-top: 10px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        background: #000;
        color: #FFF;
    }

    a {
        text-decoration: none;
    }
    body {
        background: #fbd43a !important;
    }
    
    .password-check{
            margin-top:10px;
            width: 100%!important;
            font-size: 10px;
            .five-char {
                color: red
            }
            .uppercase{
                color: red
            }
            .number{
                color: red
            }
            .special-char{
                color: red
            }
            .password-match{
                color: red
            }
        }
    .modal-window {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        &:target {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
        & > div {
          width: 680px;
          height: 400px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2em;
          position: absolute;
          width: 372px;
          height: 420px;
          background: #19181B;
          border-radius: 50px;
        }
        header {
          font-weight: bold;
          font-family: Mulish;
        }
        h1 {
          font-size: 150%;
          margin: 0 0 15px;
          font-family: Mulish;
        }
        h2 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }
        .welcome {
            margin-top: 15px;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #FFFFFF;
        }
        label {
            text-align: left;
            color: #fff;
            font-size: 12px;
            margin-left:15px;
            margin-bottom: -6px;
            font-family: Mulish;
        }
        span {
            margin-bottom: 20px;
        }
    
      }
      .modal-window {
        & > div {
            border-radius: 63px;
        }
      }
      .modal-logo {
        text-align: center;
      }
      .modal-logo > img {
        width: 100px;
        height: 100;
    }
    h2 {
       
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        line-height: 190%;
        margin-top: 20px;
    
    }
    .modal-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 19px;
        width: 380px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 50px;
        margin: 3%;
        cursor: pointer;
        font-family: Mulish;
    }
    .login-modal-box {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 52px;
        gap: 311px;
        width: 496px;
        height: 45px;
        background: #0A070F;
        border: 0.5px solid #FFFFFF;
        border-radius: 50px;
        margin: 10px;
        color: #767475;
        font-family: Mulish;
    }
    .btn-proceed {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 250px;
        height: 40px;
        background: #D33E74;
        border-radius: 82px;
        color: #FFFFFF;
        margin-left: -10px;
        font-family: Mulish;
    }
    .background{
        height: 100vh;
        background: #fbd43a;
        width: 100%;
       
        .container{
            background: transparent;
            display:flex;
            flex-direction: row;
            justify-content:space-evenly;
    
            .card{
                margin: 6px;
                width: 100%;
                height: auto;
                padding: 4px;
                background: transparent;
                font-size: 12px;
                border: none;
                color: #000;
                font-family: Mulish;

                img{
                    width: 60%;
                    margin-top: 5px;
                }
                .view-content {
                    padding: 10px;
                    overflow-x: auto;
                    overflow-y: auto;
                    height: 400px;
                    width: 96%;
                    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.8);
                }
                .btn-input{
                    height: 30px;
                    width: auto;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    margin: 2px;
                }

                .btn-group{
                    display:flex; 
                    flex-direction: row; 
                    justify-content: space-between;
                    border: none;
                    background: transparent;
                }
            }
            
    
            h2 {
                font-family: Mulish;
                font-style: normal;
                font-weight: 800;
                font-size: 30px;
                line-height: 42px;
                text-align: left;
                color: #000;
            }
            p {
                font-family: Mulish;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
                text-align: center;
                color: #000;
            }     

            span {
                .row{
                    label {
                        color: #000;
                        text-align: left;
                        font-family: Mulish;
                    }
                }

               
            }
    
            .form-group {
                margin: 4px;
            }
            .input-text {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                width: 100%;
                height: auto;
                background: #FFF;
                border: 0.5px solid #FFFFFF;
                border-radius: 4px;
                color: #000;
                font-size:11px;   
                font-family: Mulish; 
            }
    
            .birthday {
                margin-left: -20px;
            }
    
            input:focus {
                border: 0.5px solid #D33E74;
            }
            select:focus {
                border: 0.5px solid #D33E74;
            }
            textarea:focus {
                border: 0.5px solid #D33E74;
            }
            option:focus {
                border: 0.5px solid #D33E74;
            }
            .input-select {
                box-sizing: border-box;
                align-items: center;
                padding: 10px 40px;
                width: 110%;
                height: auto;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                font-family: Mulish;
                color: #767475;
                font-size:11px;
            }
            label {
                text-align: left;
                color: #FFFFFF;
                margin-left:4px;
                margin-top: 10px;
                font-family: Mulish;
            }
           
            .gender {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 40px;
                width: 100%;
                height: auto;
                background: #fbd43a;
                font-family: Mulish;
                border-radius: 10px;
                color: #000;
                font-size:11px;
            }
        
            .location {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 52px;
                gap: 202px;
                width: 100%;
                height: auto;
                background: #0A070F;
                border: 0.5px solid #FFFFFF;
                border-radius: 10px;
                color: #767475;
                font-size:11px;
                font-family: Mulish;
            }
            .btn-next {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 100%;
                height: 50px;
                background: #000;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color:#FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
            .btn-done {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                gap: 10px;
                width: 435px;
                height: 50px;
                background: #D33E74;
                border-radius: 82px;
                margin-top: 40px;
                margin-bottom: 40px;
                color:#FFFFFF;
                font-family: Mulish;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
            }
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            background: #000;
            width: 95%;
            border-radius: 4px;
            font-family: Mulish;
                    
            .logo > img{
                
            }
            .contact{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: auto;
                font-weight: normal;
                font-size: 13px;
                color: #000;
                line-height: 0px;
                font-family: Mulish;
            }
         }
         .btn-chips {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 40px;
            gap: 19px;
            width: 117.61px;
            height: 35px;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 50px;
            margin:6px;
            color:#767475;
            font-family: Mulish;
         }
         .btn-chips:hover {
            border: 0.5px solid #D33E74;
         }
         .rel-goal{
            color:#D33E74;
            font-family: Mulish;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
        }
    
    }
    @media screen and (max-width: 768px) {
          
          .modal-close {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
          }
          .modal-close-mobile {
            color: #000;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            text-decoration: none;
            &:hover {
              color: black;
            }
            display: none;
          }
          .modal-window {
            & > div {
                border-radius: 63px;
            }
          }
          .modal-logo > img {
            margin-top: -30px;
            width: 120px;
            height: 120;
        }
        h2 {
           
            font-family: Mulish;
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            line-height: 190%;
            margin-top: 20px;
        
        }
        .modal-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 19px;
            width: 380px;
            height: 50px;
            background: #FFFFFF;
            border-radius: 50px;
            margin: 3%;
            cursor: pointer;
            font-family: Mulish;
        }
        .login-modal-box {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 52px;
            gap: 311px;
            width: 496px;
            height: 45px;
            background: #0A070F;
            border: 0.5px solid #FFFFFF;
            border-radius: 50px;
            margin: 10px;
            color: #767475;
            font-family: Mulish;
        }
        .btn-proceed {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 235px;
            height: 35px;
            background: #D33E74;
            border-radius: 82px;
            color: #FFFFFF;
            margin-left: -10px;
            font-size: 13px;
            font-family: Mulish;
        }
    }
    
    @media screen and (max-width: 768px) {
        
        .modal-close {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            //text-decoration: none;
    
            &:hover {
                color: #fff;
            }
    
            display: none;
        }
    
        .modal-close-mobile {
            color: #fff;
            line-height: 50px;
            font-size: 140%;
            position: absolute;
            right: 0;
            text-align: center;
            top: 2;
            width: 70px;
            //text-decoration: none;
    
            &:hover {
                color: #fff;
            }
    
            display: block;
        }
        .background{
            background: #fbd43a;
            width: 100%;
            height: 100vh;
        
            .container{
                background: #fbd43a;
                width: 100%;
                display:flex;
                flex-direction: column;
                justify-content:space-evenly;
                padding: 30px;

                .card{
                    margin: 6px;
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    background: transparent;
                    font-size: 12px;
                    border: none;
                    color: #000;
                    font-family: Mulish;

                    img{
                        margin-top: -50px;
                        width: 50%;
                    }
                }
        
                h2 {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 42px;
                    text-align: left;
                    color: #000;
                }
                p {
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12.5px;
                    line-height: 23px;
                    text-align: center;
                    color: #000;
                }     
        
            
                .form-group {
                    margin: 4px;
                }
                .input-text {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 30px;
                    width: 100%;
                    height: 40px;
                    background: #FFF;
                    border: 0.5px solid #fff;
                    border-radius: 10px;
                    font-family: Mulish;
                }
                input:focus {
                    border: 0.5px solid #D33E74;
                }
                select:focus {
                    border: 0.5px solid #D33E74;
                }
                textarea:focus {
                    border: 0.5px solid #D33E74;
                }
                option:focus {
                    border: 0.5px solid #D33E74;
                }
                .birthday {
                    margin-left: -30px;
                }
                .input-select {
                    box-sizing: border-box;
                    align-items: center;
                    padding: 10px 40px;
                    width: 100%;
                    height: 40px;
                    background: #FFF;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 10px;
                    margin: 2px;
                    font-family: Mulish;
                }
                label {
                    text-align: left;
                    color: #000;
                    margin-left:4px;
                    margin-top: 10px;
                    font-size:12px;
                }
        
                .gender {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 40px;
                    width: 100%;
                    height: 40px;
                    font-family: Mulish;
                    border-radius: 10px;

                    img{
                        margin-top:10px;
                        width: 50px;
                    }
                }
                .location {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 52px;
                    gap: 202px;
                    width: 100%;
                    height: 40px;
                    background: #0A070F;
                    border: 0.5px solid #FFFFFF;
                    border-radius: 10px;
                    font-family: Mulish;
                }
                .btn-next {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 42px;
                    background: #000;
                    border-radius: 82px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    color:#FFFFFF;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;
                }
                .btn-done {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    width: 100%;
                    height: 42px;
                    background: #D33E74;
                    border-radius: 82px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    margin-left: -10px;
                    color:#FFFFFF;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 28px;
    
                }
        
            
            }
            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 50px;
                background: #000;
                width: 94%;
                padding: 10px;
                font-family: Mulish;
                margin-left: 10px;
            
                .logo > img{
                    width: 50%;
                }
                .contact {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: right;
                    padding: 10px;
                    font-weight: bold;
                    font-size: 15px;
                    color: #000;
                    line-height: 0px;
                    font-family: Mulish;
    
                }    
             }
        
        }
    }
    
    </style>