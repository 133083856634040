import axios from "axios";

// SECURE AXIOS
export const newAxios = axios.create({
  //baseURL: process.env.VUE_APP_API_URL,
  baseURL: 'https://backend.belmazmcs.com.ng/api/v1/',
  //baseURL: 'http://localhost:3000/api/v1/',

  headers: {
    "Content-Type": "application/json", 
  },
});

newAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("wertyuiojhdfghhdrtfyguh");
  const AuthStr = "Bearer ".concat(token);
  config.headers.Authorization = AuthStr;

  return config;

});
