<template>
    <div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
        <div class="product-container">
            <div class="column9">
                <div class="product-cart-number">
                   <span>&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
                    customer address</span>
                   <span class="address" @click="is_address = true"><span v-if="address == null">add address</span> <span v-else>change address</span> <i class="fa fa-arrow-right"></i></span>
                </div>
                <div class="cart-0">
                    <div class="product">
                        <div class="product-detail">
                            <div class="product-name">
                                <p class="cart-title">{{ firstname }} {{ lastname }}</p>
                                <p class="brand" v-if="address == null"> N/A </p>
                                <p class="brand" v-else>{{ address }}</p>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number">
                   <span>&nbsp;&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>
                    delivery details</span>
                   <!-- <span class="address">change <i class="fa fa-long-arrow-right"></i></span> -->
                </div>
                <div class="cart-0">
                    <div class="product">
                        <div class="product-detail">
                            <div class="product-name">
                                <p class="cart-title">Door Delivery</p>
                                <p class="brand">Delivery between 24 November and 27 November  </p>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="product-cart-number">
                  <span>&nbsp;&nbsp;<i class="fa fa-credit-card" aria-hidden="true"></i>
                   payment method</span>
                </div>
                <div class="cart-0">
                   <div class="product">
                       <div class="payment-method">
                           <div class="product-name payment-span">
                               <p class="cart-title">Pay with Your Wallet Balance</p>
                               <p class="brand">You will be redirected to our secure checkout page </p>
                               <div class="form-check">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="optradio" value="wallet" v-model="selectedPaymentMethod">Wallet
                                </label>
                              </div>
                           </div>
                           <!-- <div class="product-name payment-span">
                              <p class="cart-title"></p>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input type="radio" class="form-check-input" name="optradio" value="bankTransfer" v-model="selectedPaymentMethod">Bank Transfer
                                </label>
                              </div>
                            </div> -->
                       </div>
                   </div>  
                </div>
            </div>
            
            <div class="column3">
              <div class="delivery-status">
                <div class="delivery-return">
                 order summary
                </div>
                <br>
                <div  class="d-info">
                 <span>Item total({{ cart_length }}):</span>  <span>₦{{ total_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 }) }}</span> 
                </div>
                <hr>
                <div  class="d-info">
                    <span>Total:</span>  <span>₦{{total_amount.toLocaleString('en-NG', { minimumIntegerDigits: 2 })}}</span> 
                </div>
                <hr>
                <div>
                    <button id="submit4" class="btn btn-primary" :disabled="isPaymentMenthod" @click="btnConfirmOrder()" v-if="!loading">confirm order</button>
                    <button class="btn btn-primary" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                </div>
              </div>
            </div>
        </div>
        <router-link to="/shopping">
          <button class="btn btn-light continue-shopping"> <i class="fa fa-arrow-left"></i> Go back & continue shopping</button>
        </router-link>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <div v-if="is_address">
          <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper col-md-5" style="margin:auto">
                    <div class="row">
                        <div class="col-md-1">
                        </div>
                        <div class="col-md-10">
                            <div class="row account-content">
                                <div class="account-content-h">
                                  change address
                                </div>
                                <div class="account-content-d">
                                  <textarea name="" id="" style="width: 100%;" rows="2" v-model="address"></textarea>  
                                </div>
                                <button id="submit4" class="btn btn-secondary"  v-if="!is_change_address"  @click="submit_address()"> Done</button>
                                <button class="btn btn-secondary" type="button" disabled v-if="is_change_address">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button>
                                <button class="btn-bottom" @click="is_address = false"><i class="fa fa-arrow-left"></i> back</button>
                            </div>
                        </div>
                        <div class="col-md-1">                         
                        </div>
                    </div>
                </div>
            </div>
          </transition>
        </div> 
        <page-loader :is-loading="isLoading"></page-loader>
      </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  import PageLoader from '../../components/PageLoader.vue'
  export default {
    
    name: "CheckoutPage",
    components: {
        //quillEditor,
        PageLoader
    },
  
    data(){
      return {
          isLoading: false,
          user_id: '',
          firstname: '',
          lastname: '',
          address: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
         
          shops: [],
          brands: [],
          products: [],
          categorys: [],
          subcategorys:[],
          //img_url: 'http://localhost:3000/assets/uploads/products/',

          payment_bank_name: '',
          payment_account_name: '',
          payment_account_number: '',
          payment_amount_payment: '',
          ussd_code: '',
          transaction_reference: '',
          transaction_details: '',
          isSending: false,
          duration: 20 * 60 * 1000, // 20 minutes in milliseconds
          interval: 0 * 1000, // 30 seconds in milliseconds
          timer: null, 
          loading3: false,
          cart_detail: [],
          total_amount: '',
          selectedPaymentMethod: '',
          is_address: false,
          is_change_address: false,
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.address = localStorage.getItem("address");
        this.user_id = localStorage.getItem("userid");
        this.cart_id = localStorage.getItem('cart_id');
               
        this.bank_name = localStorage.getItem("bank_name");
        this.account_name = localStorage.getItem("account_name");
        this.account_number = localStorage.getItem("account_number");
        this.fetchCartInformation(this.cart_id) 
        this.fetch_address()
    
    },
  
    created(){
          
    },
  
    computed: {
      isPaymentMenthod() {
        return (
            this.selectedPaymentMethod === ''
        );
      },
    },
  
    methods: {
      async submit_address() {
          try {
              this.is_address = false
              this.is_change_address = true;
              const response = await this.$http.post('/update-address', {
                address: this.address,
                userid: this.user_id
              })
              this.is_change_address = false;
              if( response.data.success ){
              swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetch_address()
                return;
              }
          } catch (err) {
              console.log(err);
          }
      },
      async fetch_address() {
          try {
              const response = await this.$http.post('/fetch-user-byid', {
                userid: this.user_id
              })
              this.address = response.data.users[0].address

          } catch (err) {
              console.log(err);
          }
      },

      async fetchCartInformation(cart_id) {
          try {

              this.isLoading = true;
              const response = await this.$http.post('/fetch-cart-byunique_code', {
                cart_unique_code: cart_id
              })

              this.cart_items = response.data.cart;
              this.cart_length =  this.cart_items.length
              this.$store.dispatch('updateCartCount', this.cart_items.length);
              this.calculateOrder()
              this.isLoading = false;

          } catch (err) {
              console.log(err);
          }
      },

       calculateOrder(){
            this.total_amount = this.cart_items.reduce(
                (accumulator, item) => accumulator + item.unit_price * item.qty,
                0
            );
        },

        async btnConfirmOrder(){
        try {
                this.loading = true
                const response = await this.$http.post('/update-cart-payment', {
                    cart_items: this.cart_items,
                    payment_method: this.selectedPaymentMethod,
                })
                this.loading = false
                if (response.data.success) {
                  this.$router.push('/checkout/payment/review')
                }
                           
          } catch (err) {
              console.log(err);
          }
      },

    }
  
  }
  </script>
  <style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:#fff;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    text-transform: uppercase;
    text-align: center;
    background: #fff;
    border-bottom: solid 1px #00802f;
    color: #00802f;
  }
  .account-content-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    align-items: center;
    padding-top: 1.3rem;
    font-size: 14px;
    margin: 10px;
  }
  .btn-bottom{
    margin-bottom: 20px;
  }
  .btn-secondary{
    margin-bottom: 10px;
  }

  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
  .cart-0{
    margin-bottom: 4px;
  }
   /* Breadcrumbs container */
   nav.breadcrumbs {
    margin: 10px 0;
    font-size: 12px;
    padding-left: 2.5rem;
  }

  /* Breadcrumbs list */
  nav.breadcrumbs ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }

  /* Breadcrumbs list items */
  nav.breadcrumbs li {
    margin-right: 5px;
  }

  /* Breadcrumb links */
  nav.breadcrumbs a {
    text-decoration: none;
    color: #4b4848;
    transition: color 0.3s ease;
  }

  /* Breadcrumb links on hover */
  nav.breadcrumbs a:hover {
    color: #4b4848;
  }

  /* Separator between breadcrumb items */
  nav.breadcrumbs li::after {
    content: '>';
    margin: 0 5px;
    color: #ccc;
  }

  /* Styling for the last breadcrumb item (current page) */
  nav.breadcrumbs li:last-child::after {
    content: none; /* Remove separator for the last item */
    font-weight: 500;
    color: #333; /* You can adjust the color to make it stand out */
  }

.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
  }
  .product-container{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;

  }
  .column9{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 8px;
  }
  .column3{
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  .column12{
    width: 100%;
  }
  .product{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.9rem;
    font-family: Mulish;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background: #fff;
  }
  .price{
    display: flex;
    flex-direction: column;
  }
  .product-cart-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    font-family: Mulish;
    font-size: 13px;
    background: #fff;
    border-bottom: solid 1px #bdbaba;
    color: #626060;
    text-transform: uppercase;
    font-weight: normal;
  }
  .address{
    color: #00802f;
    text-transform: capitalize;
    font-weight: normal;
    cursor: pointer;
  }
  .counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}

.btn-counter{
    padding: 6px;
    font-weight: bold;
    font-family: Mulish;
    font-size: 15px;
    margin: 5px;
    width: 15%;
    background: #00802f;
    color: #fff;
    border-radius: 4px;
}
.counter-action{
    font-size: 13px;
}
.login-modal-box2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 20%;
    height: 30px;
    padding: 10px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 0px;
    color: #000;
    font-weight: 500;
    font-family: Mulish;
  }
  .product-counter-remove{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 0.3rem;
    font-family: Mulish;
    background: #fff;
    .btn-remove{
        text-transform: uppercase;
        color: #00802f;
        font-size: 12px;
      }
  }
  
  .order-number{
    background: #03BE03; 
    color: #fff; 
    border-radius: 2px; 
    padding: 0.2rem;
    a{
      text-decoration: none;
      color: #fff;
    }
  }
  .product:hover{
    //border: solid 1px #00802f;
    //box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2); 
  }
  .product-img{
    display: flex;
    flex-direction: column;
  }
  .img-slide{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
  }
  .img {
    cursor: pointer; 
    width: 100%; 
    height: 100px;
    padding: 0.5rem;
    border: solid 1px #ccc;
  }
  .img1 {
    cursor: pointer; 
    padding: 0.5rem;
    border: solid 1px #ccc;
    margin: 4px;
  }
  .product-detail{
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    width: 100%;
  }
  .payment-span{
    margin: 15px;
  }
  .payment-method{
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    width: 100%;
  }
  .continue-shopping{
    margin-left: 15px;
    font-size: 12px;
  }
  .product-name{
    display: flex;
    flex-direction: column;
    font-family: Mulish;
    margin-right: 10px;
    .cart-title{
        margin-top: -10px;
        font-size: 14px;
        font-weight: bold;
        color: #6e6c6c;
    }
    .brand{
        margin-top: -10px;
        font-size: 12px;
        span{
          font-weight: bold;
          font-size: 15px;
        }
    }
  }
  .payment-made{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.2rem;
    font-size: 13px;
    border-radius: 4px;
    border: solid 10px #a5a3a3;
    //background: #a5a3a3;
    .btn-primary{
      background: rgb(42, 136, 230);
      font-size: 14px !important;
    }
    p{
      color: #a5a3a3;
    }
  }
  .product-price{
    .price{
        padding: 16px 0;
        font-size: 20px;
        //font-weight: 600;
        color: #2b2929;
    }
   
  }
  .btn-primary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #00802f;
    text-transform: uppercase;
    font-size: 13px;
    width: 100%;
    height: 45px;
    border: solid 0px;
  }
  .fa-shopping-cart{
    font-size: 24px;
  }
  .fa-cart-plus{
    font-size: 24px;
  }

  .product-disclaimer{
    .disclaimer{
      font-size: 14px;
      color: #2b2929;
    }
    .disclaimer-info{
      font-size: 13px;
      color: rgba(255, 0, 0, 0.586);
    }
  }
  .product-d{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-family: Mulish;
    border: solid 0px #ccc;
    margin-bottom: 6px;
    border-radius: 4px;
    background: #fff;
  }
  .p-detail{
    display: flex;
    flex-direction: column;
  }
  .p-header{
    font-size: 18px;
    padding: 0.9rem;
    border-bottom: solid 1px #a5a3a3;
  }
  .p-content{
    padding: 0.9rem;
    font-size: 13px;
    text-align: justify;
  }
  .specification{
    display: flex;
    flex-direction: row;
    padding: 0.9rem;
   
  }
  .content-header{
    font-size: 13px;
    padding: 0.9rem;
    border: solid 1px #a5a3a3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-transform: uppercase;
  }
  .p-content-d{
    margin: 10px;
    font-size: 13px;
  }

  .inputs-field{
    font-size: 14px;
    font-family: Mulish;
    height: 45px;
}
.delivery-status{
  display: flex;
  flex-direction: column;
  justify-content: left;
  background: #fff;
  border-radius: 4px;
  padding: 0.9rem;
  width: 100%;
  height: 240px;

}
.delivery-return{
    font-size: 14px;
    font-family: Mulish;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-transform: uppercase;
    border-bottom: solid 1px #a5a3a37b;
    margin-bottom: 14px;
}
.d-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Mulish;
    padding: 0.3rem;
}
.location{
  font-size: 12px;
  font-family: Mulish;
  padding: 0.3rem;
  h5{
    font-size: 14px;
  }
}

  @media screen and (max-width: 768px) {

    .product-detail{
      display: flex;
      flex-direction: column;
      padding: 0.2rem;
      width: 100%;
    }
    .product-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
      .product{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.7rem;
        font-family: Mulish;
        width: 100%;
      }
     
      .column9{
        width: 100%;
      }
      .column3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .column12{
        width: 100%;
      }
      .delivery-status{
        padding: 0.7rem;
        width: 100%;
      }
    .inputs{
      font-size: 12px;
    }
    .product-img{
      display: flex;
      flex-direction: column;
      width: 100%; 
    }
    .img {
      cursor: pointer; 
      width: 100%; 
      padding: 0.5rem;
      border: solid 1px #ccc;
    }
    .img1 {
      cursor: pointer; 
      padding: 0.5rem;
      border: solid 1px #ccc;
      margin: 4px;
    }
    .product-d{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: Mulish;
      border: solid 0px #ccc;
      margin-bottom: 6px;
      border-radius: 4px;
      background: #fff;
    }
    .p-detail{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .specification{
      display: flex;
      flex-direction: column;
      padding: 0.9rem;
      text-align: justify;
    }

  
  }
  
  </style>